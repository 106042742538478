import { useMutation } from "@apollo/client";
import { CheckBox } from "components/base";
import CharlesButton from "components/charles/base";
import { Field } from "components/Form";
import {
  BallSizeInput,
  CubeSizeInput,
  CylindricalSizeInput,
  FlatSizeInput,
  MultiSizeInput,
} from "components/ItemSizeInput";
import { useState } from "react";
import { BATCH_UPDATE_PRODUCTS } from "./graphql";
import { Alert } from "components/Toast";

const BatchUpdateForm = ({ hide, products }) => {
  const productShape = products.length > 0 ? products[0].shape : "cube";

  const [innerBoxX, setInnerBoxX] = useState("");
  const [innerBoxY, setInnerBoxY] = useState("");
  const [innerBoxZ, setInnerBoxZ] = useState("");
  const [displayBoxX, setDisplayBoxX] = useState("");
  const [displayBoxY, setDisplayBoxY] = useState("");
  const [displayBoxZ, setDisplayBoxZ] = useState("");
  const [innerCartonX, setInnerCartonX] = useState("");
  const [innerCartonY, setInnerCartonY] = useState("");
  const [innerCartonZ, setInnerCartonZ] = useState("");
  const [outerCartonX, setOuterCartonX] = useState("");
  const [outerCartonY, setOuterCartonY] = useState("");
  const [outerCartonZ, setOuterCartonZ] = useState("");
  const [ctnNetWeight, setCtnNetWeight] = useState("");
  const [ctnGrossWeight, setCtnGrossWeight] = useState("");

  // * item
  const [itemWeight, setItemWeight] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [ballDiameter, setBallDiameter] = useState("");
  const [cubeX, setCubeX] = useState("");
  const [cubeY, setCubeY] = useState("");
  const [cubeZ, setCubeZ] = useState("");
  const [cylindricalDiameter, setCylindricalDiameter] = useState("");
  const [cylindricalHeight, setCylindricalHeight] = useState("");
  const [flatX, setFlatX] = useState("");
  const [flatY, setFlatY] = useState("");
  const [multiSizes, setMultiSizes] = useState("");

  const [hasSelectedInnerBoxSize, setHasSelectedInnerBoxSize] = useState(false);
  const [hasSelectedDisplayBoxSize, setHasSelectedDisplayBoxSize] =
    useState(false);
  const [hasSelectedInnerCartonSize, setHasSelectedInnerCartonSize] =
    useState(false);
  const [hasSelectedOuterCartonSize, setHasSelectedOuterCartonSize] =
    useState(false);
  const [hasSelectedCtnNetWeight, setHasSelectedCtnNetWeight] = useState(false);
  const [hasSelectedCtnGrossWeight, setHasSelectedCtnGrossWeight] =
    useState(false);
  // * item
  const [hasSelectedItemWeight, setHasSelectedItemWeight] = useState(false);
  const [hasSelectedGrossWeight, setHasSelectedGrossWeight] = useState(false);
  const [hasSelectedItemSize, setHasSelectedItemSize] = useState(false);

  const [batchUpdate, batchUpdateRes] = useMutation(BATCH_UPDATE_PRODUCTS, {
    onCompleted: () => {
      Alert("success", "Products batch updated successfully.");
    },
    onError: (err) => {
      Alert("error", parseError(err));
    },
  });

  const outerCartonCbm = () =>
    ((parseFloat(outerCartonX) + 0.5) *
      (parseFloat(outerCartonY) + 0.5) *
      (parseFloat(outerCartonZ) + 0.5)) /
    (100 * 100 * 100);

  const handleBatchUpdate = () => {
    if (
      hasSelectedInnerBoxSize &&
      (innerBoxX === "" || innerBoxY === "" || innerBoxZ === "")
    ) {
      Alert("error", "Inner Box Size is required.");
      return;
    }

    if (
      hasSelectedDisplayBoxSize &&
      (displayBoxX === "" || displayBoxY === "" || displayBoxZ === "")
    ) {
      Alert("error", "Display Box Size is required.");
      return;
    }

    if (
      hasSelectedInnerCartonSize &&
      (innerCartonX === "" || innerCartonY === "" || innerCartonZ === "")
    ) {
      Alert("error", "Inner Carton Size is required.");
      return;
    }

    if (
      hasSelectedOuterCartonSize &&
      (outerCartonX === "" || outerCartonY === "" || outerCartonZ === "")
    ) {
      Alert("error", "Outer Carton Size is required.");
      return;
    }

    if (hasSelectedCtnNetWeight && ctnNetWeight === "") {
      Alert("error", "CTN Net Weight is required.");
      return;
    }

    if (hasSelectedCtnGrossWeight && ctnGrossWeight === "") {
      Alert("error", "CTN Gross Weight is required.");
      return;
    }

    if (hasSelectedItemWeight && itemWeight === "") {
      Alert("error", "Item Weight is required.");
      return;
    }

    if (hasSelectedGrossWeight && grossWeight === "") {
      Alert("error", "Gross Weight is required.");
      return;
    }

    if (hasSelectedItemSize) {
      if (productShape === "ball" && ballDiameter === "") {
        Alert("error", "Ball Diameter is required.");
        return;
      }

      if (
        productShape === "cube" &&
        (cubeX === "" || cubeY === "" || cubeZ === "")
      ) {
        Alert("error", "Cube Size is required.");
        return;
      }

      if (
        productShape === "cylindrical" &&
        (cylindricalDiameter === "" || cylindricalHeight === "")
      ) {
        Alert("error", "Cylindrical Size is required.");
        return;
      }

      if (productShape === "flat" && (flatX === "" || flatY === "")) {
        Alert("error", "Flat Size is required.");
        return;
      }

      if (productShape === "multi" && multiSizes === "") {
        Alert("error", "Multi Sizes is required.");
        return;
      }
    }

    batchUpdate({
      variables: {
        ids: products.map((p) => p.id),

        innerBoxX: hasSelectedInnerBoxSize ? innerBoxX : null,
        innerBoxY: hasSelectedInnerBoxSize ? innerBoxY : null,
        innerBoxZ: hasSelectedInnerBoxSize ? innerBoxZ : null,
        displayBoxX: hasSelectedDisplayBoxSize ? displayBoxX : null,
        displayBoxY: hasSelectedDisplayBoxSize ? displayBoxY : null,
        displayBoxZ: hasSelectedDisplayBoxSize ? displayBoxZ : null,
        innerCartonX: hasSelectedInnerCartonSize ? innerCartonX : null,
        innerCartonY: hasSelectedInnerCartonSize ? innerCartonY : null,
        innerCartonZ: hasSelectedInnerCartonSize ? innerCartonZ : null,
        outerCartonX: hasSelectedOuterCartonSize ? outerCartonX : null,
        outerCartonY: hasSelectedOuterCartonSize ? outerCartonY : null,
        outerCartonZ: hasSelectedOuterCartonSize ? outerCartonZ : null,
        ctnNetWeight: hasSelectedCtnNetWeight ? ctnNetWeight : null,
        ctnGrossWeight: hasSelectedCtnGrossWeight ? ctnGrossWeight : null,

        itemWeight: hasSelectedItemWeight ? itemWeight : null,
        grossWeight: hasSelectedGrossWeight ? grossWeight : null,
        ballDiameter:
          hasSelectedItemSize && productShape === "ball" ? ballDiameter : null,
        cubeX: hasSelectedItemSize && productShape === "cube" ? cubeX : null,
        cubeY: hasSelectedItemSize && productShape === "cube" ? cubeY : null,
        cubeZ: hasSelectedItemSize && productShape === "cube" ? cubeZ : null,
        cylindricalDiameter:
          hasSelectedItemSize && productShape === "cylindrical"
            ? cylindricalDiameter
            : null,
        cylindricalHeight:
          hasSelectedItemSize && productShape === "cylindrical"
            ? cylindricalHeight
            : null,
        flatX: hasSelectedItemSize && productShape === "flat" ? flatX : null,
        flatY: hasSelectedItemSize && productShape === "flat" ? flatY : null,
        multiSizes:
          hasSelectedItemSize && productShape === "multi" ? multiSizes : null,
      },
    });

    hide();
  };

  return (
    <div className="space-y-4" data-testid="products-batch-update-form">
      <div className="space-y-2">
        <label className="block ">Selected Products: {products.length}</label>
        <div className="h-40 overflow-auto bg-white p-4 rounded-lg">
          {products.map((p) => (
            <div key={p.id} className="flex items-center space-x-4">
              <div className="w-40 whitespace-nowrap ">{p.number}</div>
              <div>{p.name}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="space-y-4">
        <label className="block ">Select options to update: </label>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedInnerBoxSize}
            onChange={() => {
              setHasSelectedInnerBoxSize(!hasSelectedInnerBoxSize);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedInnerBoxSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Inner Box Size:</div>
                <div className="text-xs text-gray-600">
                  Size 长(x) * 宽(z) * 高(y)
                </div>
              </label>

              <CubeSizeInput
                x={innerBoxX}
                y={innerBoxY}
                z={innerBoxZ}
                unit="mm"
                onChange={({ x, y, z }) => {
                  setInnerBoxX(x);
                  setInnerBoxY(y);
                  setInnerBoxZ(z);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedDisplayBoxSize}
            onChange={() => {
              setHasSelectedDisplayBoxSize(!hasSelectedDisplayBoxSize);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedDisplayBoxSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Display Box Size:</div>
                <div className="text-xs text-gray-600">
                  Size 长(x) * 宽(z) * 高(y)
                </div>
              </label>

              <CubeSizeInput
                x={displayBoxX}
                y={displayBoxY}
                z={displayBoxZ}
                unit="mm"
                onChange={({ x, y, z }) => {
                  setDisplayBoxX(x);
                  setDisplayBoxY(y);
                  setDisplayBoxZ(z);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedInnerCartonSize}
            onChange={() => {
              setHasSelectedInnerCartonSize(!hasSelectedInnerCartonSize);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedInnerCartonSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Inner Carton Size:</div>
                <div className="text-xs text-gray-600">
                  Size 长(x) * 宽(z) * 高(y)
                </div>
              </label>

              <CubeSizeInput
                x={innerCartonX}
                y={innerCartonY}
                z={innerCartonZ}
                unit="mm"
                onChange={({ x, y, z }) => {
                  setInnerCartonX(x);
                  setInnerCartonY(y);
                  setInnerCartonZ(z);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedOuterCartonSize}
            onChange={() => {
              setHasSelectedOuterCartonSize(!hasSelectedOuterCartonSize);
            }}
          />

          <div
            className={`text-sm ${!hasSelectedOuterCartonSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Outer Carton Size:</div>
                <div className="text-xs text-gray-600">
                  Size 长(x) * 宽(z) * 高(y)
                </div>
              </label>

              <CubeSizeInput
                x={outerCartonX}
                y={outerCartonY}
                z={outerCartonZ}
                unit="cm"
                onChange={({ x, y, z }) => {
                  console.log("x, y, z", x, y, z);
                  setOuterCartonX(x);
                  setOuterCartonY(y);
                  setOuterCartonZ(z);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`text-sm ${!hasSelectedOuterCartonSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
        >
          <div className="flex items-center space-x-4 ml-10 text-xs">
            <label className="w-40"></label>
            <div>
              CBM: {outerCartonCbm() ? outerCartonCbm().toFixed(4) : "0"} m³
            </div>
            <div className=" text-gray-600">
              ((x + 0.5) * (y + 0.5) * (z + 0.5)) / (100 * 100 * 100)
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedCtnNetWeight}
            onChange={() => {
              setHasSelectedCtnNetWeight(!hasSelectedCtnNetWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedCtnNetWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="CTN Net Weight"
              value={ctnNetWeight}
              min={0}
              onChange={setCtnNetWeight}
              suffix="kg"
              labelWidth="w-40"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedCtnGrossWeight}
            onChange={() => {
              setHasSelectedCtnGrossWeight(!hasSelectedCtnGrossWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedCtnGrossWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="CTN Gross Weight"
              value={ctnGrossWeight}
              min={0}
              onChange={setCtnGrossWeight}
              suffix="kg"
              labelWidth="w-40"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedItemWeight}
            onChange={() => {
              setHasSelectedItemWeight(!hasSelectedItemWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedItemWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="Item Weight"
              value={itemWeight}
              min={0}
              onChange={setItemWeight}
              suffix="g"
              labelWidth="w-40"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedGrossWeight}
            onChange={() => {
              setHasSelectedGrossWeight(!hasSelectedGrossWeight);
            }}
          />
          <div
            className={`text-sm ${!hasSelectedGrossWeight ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <Field
              type="number"
              label="Gross Weight"
              value={grossWeight}
              min={0}
              onChange={setGrossWeight}
              suffix="g"
              labelWidth="w-40"
            />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <CheckBox
            checked={hasSelectedItemSize}
            onChange={() => {
              setHasSelectedItemSize(!hasSelectedItemSize);
            }}
          />

          <div
            className={`text-sm ${!hasSelectedItemSize ? "opacity-30 pointer-events-none cursor-not-allowed" : ""}`}
          >
            <div className="flex items-center space-x-4">
              <label className="w-40">
                <div>Item Size:</div>
              </label>

              {productShape === "ball" ? (
                <BallSizeInput
                  diameter={ballDiameter}
                  unit="mm"
                  onChange={({ diameter }) => {
                    setBallDiameter(diameter);
                  }}
                />
              ) : productShape === "cube" ? (
                <CubeSizeInput
                  x={cubeX}
                  y={cubeY}
                  z={cubeZ}
                  unit="mm"
                  onChange={({ x, y, z }) => {
                    setCubeX(x);
                    setCubeY(y);
                    setCubeZ(z);
                  }}
                />
              ) : productShape === "cylindrical" ? (
                <CylindricalSizeInput
                  diameter={cylindricalDiameter}
                  height={cylindricalHeight}
                  onChange={({ diameter, height }) => {
                    setCylindricalDiameter(diameter);
                    setCylindricalHeight(height);
                  }}
                />
              ) : productShape === "flat" ? (
                <FlatSizeInput
                  x={flatX}
                  y={flatY}
                  onChange={({ x, y }) => {
                    setFlatX(x);
                    setFlatY(y);
                  }}
                />
              ) : productShape === "multi" ? (
                <MultiSizeInput
                  text={multiSizes}
                  onChange={({ text }) => {
                    setMultiSizes(text);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <CharlesButton
        onClick={handleBatchUpdate}
        disabled={
          hasSelectedInnerBoxSize ||
          hasSelectedDisplayBoxSize ||
          hasSelectedInnerCartonSize ||
          hasSelectedOuterCartonSize ||
          hasSelectedCtnGrossWeight ||
          hasSelectedCtnNetWeight ||
          hasSelectedItemWeight ||
          hasSelectedGrossWeight ||
          hasSelectedItemSize
            ? false
            : true
        }
      >
        Update
      </CharlesButton>
    </div>
  );
};

export default BatchUpdateForm;
