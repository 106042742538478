import React, { useState } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import SupplierDetail from "./Detail";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import { gql, useQuery } from "@apollo/client";
import odooIcon from "assets/odoo-icon.svg";
import { FETCH_ALL_FACTORIES } from "./graphql";
import SupplierPage from "./supplier";

const SuppliersRoutes = () => (
  <Routes>
    <Route>
      <Route index element={<SupplierList />} />
      <Route path=":id" element={<SupplierPage />} />
    </Route>
  </Routes>
);

const SupplierList = () => {
  const { loading, error, data } = useQuery(FETCH_ALL_FACTORIES);
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let suppliers = data.allFactories
    .filter((f) => f.status === 1)
    .filter((i) => searchByProp(i, ["name"], searchText));
  suppliers.sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <div className="p-6">
      <div>
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          placeholder="Search supplier by name"
        />
      </div>
      <div className="mt-4">
        {suppliers.map((supplier) => (
          <div
            key={supplier.id}
            className="py-2 border-b dark:border-gray-800 flex justify-between px-2"
          >
            <div className="flex items-center">
              {supplier.odooId ? (
                <img
                  className="mr-2"
                  style={{ height: 16 }}
                  src={odooIcon}
                  alt="odoo product"
                />
              ) : null}
              <Link to={supplier.id}>{supplier.name}</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SuppliersRoutes;
