import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { Select } from "components/Form";
import { FETCH_RETAILER_AGENTS } from "./graphql";

const AgentSelector = ({ value, onChange, disabled }) => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_AGENTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <Errors error={error} />;

  const sortedAgents = [...data.retailerAgents].sort((a, b) =>
    a.email.localeCompare(b.email),
  );

  return (
    <Select
      value={value === null ? "0" : value}
      onChange={(e) => {
        onChange(e.target.value === "0" ? null : e.target.value);
      }}
      disabled={disabled}
    >
      <option value="0">No Agent</option>
      {sortedAgents.map((agent) => (
        <option key={agent.id} value={agent.id}>
          {agent.email}
        </option>
      ))}
    </Select>
  );
};

export default AgentSelector;
