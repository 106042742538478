import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { FETCH_RETAILER_STORE_ORDER_SHIPMENTS } from "./graphql";
import ShipmentView from "./ShipmentView";

const ShipmentsView = ({ order }) => {
  const { loading, error, data } = useQuery(
    FETCH_RETAILER_STORE_ORDER_SHIPMENTS,
    {
      variables: { orderId: order.id },
    },
  );

  if (loading)
    return (
      <div className="relative p-8">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  return (
    <div className="card px-6 py-4">
      <h4>Shipment</h4>
      <div className="mt-4 space-y-6">
        {data.shipments.map((i) => (
          <ShipmentView key={i.id} shipment={i} />
        ))}
      </div>
    </div>
  );
};

export default ShipmentsView;
