import CharlesButton from "components/charles/base";
import { UPDATE_ASSESSMENT_STATUS } from "./graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const StartImprovementView = ({ id, categories, dismiss }) => {
  const [updateAssessmentStatus, updateAssessmentStatusRes] = useMutation(
    UPDATE_ASSESSMENT_STATUS,
    {
      variables: { id, status: "improving" },
      onCompleted() {
        Alert(
          "success",
          "Improvement feedback sent. Assessment status is now `Improving`",
        );
        dismiss();
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  function cleanCategories(categories) {
    return categories
      .map((category) => ({
        ...category,
        questions: category.questions.filter(
          (q) => q.requiredImprovement && q.improvementCompleted === false,
        ),
        children: cleanCategories(category.children),
      }))
      .filter((category) => category.questions.length > 0);
  }

  const cleanedCategories = cleanCategories(
    categories.filter((i) => i.requiredSupplier),
  );

  return (
    <div className="space-y-6">
      <div className="text-sm">
        Below are the questions that require improvement. Please review and
        confirm that you have clarified our thoughts. Once you start
        improvement, the system will notify the supplier and the assessment will
        change to `Improving` status. Suppliers are supposed to provide
        improvement at this stage.
      </div>

      <div className="card px-6 py-4">
        {cleanedCategories.map((category) => (
          <CategoryView category={category} key={category.id} />
        ))}
      </div>

      <div>
        <CharlesButton
          primary
          onClick={updateAssessmentStatus}
          loading={updateAssessmentStatusRes.loading}
        >
          Start Improvement
        </CharlesButton>
      </div>
    </div>
  );
};

const CategoryView = ({ category }) => {
  return category.children.length > 0
    ? category.children.map((child) => (
        <CategoryView category={child} key={child.id} />
      ))
    : category.questions.map((question) => (
        <QuestionView question={question} key={question.id} />
      ));
};

const QuestionView = ({ question }) => {
  return (
    <div className="border-t pt-4 mt-4 first:border-0 first:pt-0 first:mt-0">
      <div>{question.question.question}</div>
      <div className="mt-2">
        <h6 className="opacity-50">Improvement Notes</h6>
        <div className="mt-1">{question.improvementNote ?? "-"}</div>
      </div>
    </div>
  );
};

export default StartImprovementView;
