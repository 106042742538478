import { gql } from "@apollo/client";

export const FETCH_PRICE_LIST = gql`
  query FETCH_PRICE_LIST($id: ID!) {
    priceList(id: $id) {
      id
      name
      currency
      odooId
      createdBy {
        id
      }
      latestPrices(prefetchProductFields: ["images"]) {
        id
        product {
          id
          odooId
          number
          name
          isActive
          images: computedImages(size: "300x300") {
            id
            url
          }
          productCost {
            cost
            date
          }
        }
        useFixedMargin
        fixedMargin
        discountPercentage
        price
        finalPrice
        state
        updatedAt
      }
    }
  }
`;
