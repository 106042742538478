import { gql } from "@apollo/client";

export const FETCH_FORECAST_DOCS = gql`
  query FETCH_FORECAST_DOCS {
    incForecastDocs {
      id
      name
      user {
        id
        email
      }
      createdAt
    }
  }
`;

export const FETCH_FORECAST_DOC = gql`
  query FETCH_FORECAST_DOC($id: ID!) {
    incForecastDoc(id: $id) {
      id
      name
      warehouse
      year
      data
      syncConflictData
    }
  }
`;

export const INC_SAVE_FORECAST_DOC = gql`
  mutation INC_SAVE_FORECAST_DOC($id: ID!, $data: String!) {
    incSaveForecastDoc(id: $id, data: $data) {
      forecastDoc {
        id
        data
      }
    }
  }
`;

export const INC_CREATE_FORECAST_DOC = gql`
  mutation INC_CREATE_FORECAST_DOC(
    $name: String!
    $warehouse: String!
    $year: Int!
    $data: String
  ) {
    incCreateForecastDoc(
      name: $name
      warehouse: $warehouse
      year: $year
      data: $data
    ) {
      forecastDoc {
        id
        name
        warehouse
        year
        data
      }
    }
  }
`;

export const INC_FC_IMPORT_WAREHOUSE_PRODUCTS = gql`
  mutation INC_FC_IMPORT_WAREHOUSE_PRODUCTS(
    $forecastDocId: ID!
    $warehouse: String!
  ) {
    incFcImportWarehouseProducts(
      forecastDocId: $forecastDocId
      warehouse: $warehouse
    ) {
      forecastDoc {
        id
        data
      }
    }
  }
`;

export const FETCH_PRODUCT_FORECASTED_SALES = gql`
  query FETCH_PRODUCT_FORECASTED_SALES(
    $productIds: [ID]!
    $year: Int!
    $month: Int!
  ) {
    incForecastSales(productIds: $productIds, year: $year, month: $month)
  }
`;

export const FETCH_INC_SHIPMENT_LINES = gql`
  query FETCH_INC_SHIPMENT_LINES($productIds: [ID]!, $year: Int!) {
    incShipmentLines(productIds: $productIds, year: $year) {
      id
      product {
        id
      }
      shipment {
        id
        name
        state
        date
        shipmentType
      }
      qty
    }
  }
`;
