import { gql } from "@apollo/client";

export const FETCH_RETAILER_STORE_PRODUCTS = gql`
  query FETCH_RETAILER_STORE_PRODUCTS {
    retailerProducts {
      id
      name
      isActive
      price
      image
      ordering
      bom {
        id
        wisProduct {
          id
          name
          number
          warehouseInventory(warehouseName: "NL")
        }
        qty
      }

      inStockQty
      nextAvailable(warehouseName: "NL") {
        qty
        eta
      }
    }
  }
`;

export const FETCH_PRODUCT = gql`
  query FETCH_PRODUCT($id: ID!) {
    retailerProduct(id: $id) {
      id
      name
      description
      price
      uom
      qtyPerCarton
      retailValue
      recommendedRetailPrice
      isActive
      backToStockAt
      images

      bom {
        id
        wisProduct {
          id
          name
          number
          warehouseInventory(warehouseName: "NL")
          warehouseInboundLines(warehouseName: "NL") {
            id
            qty
            inbound {
              id
              eta
            }
          }
        }
        qty
        totalPrice
      }
    }
  }
`;

export const FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE = gql`
  query FETCH_WIS_PRODUCTS_FOR_RETAILER_STORE {
    products(hasOdooId: true, isSalable: true, isActive: true) {
      total
      results {
        id
        name
        number
        odooId
      }
    }
  }
`;

export const RETAILER_CREATE_PRODUCT = gql`
  mutation RETAILER_CREATE_PRODUCT($name: String!) {
    retailerCreateProduct(name: $name) {
      product {
        id
      }
    }
  }
`;

export const RETAILER_SAVE_PRODUCT = gql`
  mutation RETAILER_SAVE_PRODUCT(
    $id: ID!
    $productInput: RetailerProductInput!
    $bom: [RetailerProductChildInput!]
  ) {
    retailerSaveProduct(id: $id, productInput: $productInput, bom: $bom) {
      product {
        id
        name
        description
        price
        qtyPerCarton
        retailValue
        recommendedRetailPrice
        isActive
        backToStockAt
        images
        bom {
          id
          wisProduct {
            id
            name
            number
          }
          qty
          totalPrice
        }
      }
    }
  }
`;

export const SORT_RETAILER_PRODUCTS = gql`
  mutation ($productIds: [ID]!) {
    retailerSortProducts(productIds: $productIds) {
      products {
        id
        ordering
      }
    }
  }
`;
