import { AppContext } from "App";
import { NavigationLink } from "components/base";
import Page from "components/Page";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PRICE_EDITOR, PRICE_VIEWER } from "utils/permissions";
import Combines from "./analysis/Combines";
import Trends from "./analysis/Trends";
import ExchangeRates from "./exchangeRates";
import Material from "./material";
import PriceList from "./priceList";
import Products from "./products";
import SelectExport from "./SelectExport";
import Suppliers from "./suppliers";
import UpdateHistory from "./updateHistory";

function PricePages() {
  const { hasPermission } = useContext(AppContext);
  const hasEditorPermission = hasPermission(PRICE_EDITOR);
  const hasViewerPermission = hasPermission(PRICE_VIEWER);
  const hasAnyPermission = hasEditorPermission || hasViewerPermission;

  if (!hasAnyPermission)
    return (
      <div className="flex flex-1 items-center justify-center font-bold text-2xl h-screen text-gray-600">
        Not Allowed.
      </div>
    );

  const LeftButtonsView = () => {
    if (hasEditorPermission) {
      return (
        <div className="flex space-x-4">
          <NavigationLink to="/price/products" title="Products" />
          <NavigationLink to="/price/suppliers" title="Suppliers" />
          <NavigationLink to="/price/price-list" title="Price Lists" />
          <NavigationLink to="/price/update-history" title="Update History" />
          <NavigationLink to="/price/select-export" title="Export" />
        </div>
      );
    } else if (hasViewerPermission) {
      return <NavigationLink to="/price/price-list" title="Price Lists" />;
    }
  };

  const routes = () => {
    if (hasEditorPermission) {
      return (
        <Routes>
          <Route path="products/*" element={<Products />} />
          <Route path="materials/*" element={<Material />} />
          <Route path="exchange-rates/*" element={<ExchangeRates />} />
          <Route path="suppliers/*" element={<Suppliers />} />
          <Route path="price-list/*" element={<PriceList />} />
          <Route path="update-history" element={<UpdateHistory />} />
          <Route path="analysis">
            <Route path="trends" element={<Trends />} />
            <Route path="combines" element={<Combines />} />
          </Route>
          <Route path="select-export" element={<SelectExport />} />
          <Route path="*" element={<Navigate to="products" />} />
        </Routes>
      );
    } else if (hasViewerPermission) {
      return (
        <Routes>
          <Route path="price-list/*" element={<PriceList />} />
          <Route path="*" element={<Navigate to="price-list" />} />
        </Routes>
      );
    }
  };

  return (
    <Page
      leftButtons={<LeftButtonsView />}
      rightButtons={
        hasEditorPermission ? (
          <div className="flex space-x-4">
            <NavigationLink to="/price/materials" title="Raw Materials" />
            <NavigationLink to="/price/exchange-rates" title="Exchange Rates" />
            <NavigationLink to="/price/analysis/trends" title="Trends" />
            <NavigationLink to="/price/analysis/combines" title="Combines" />
          </div>
        ) : null
      }
      className="h-screen"
    >
      {routes()}
    </Page>
  );
}

export default PricePages;
