import ImageLoader from "./Image";

const ProductImages = ({ product, showImages }) => {
  return (
    <div
      className={`flex items-center justify-center space-x-2
         ${showImages ? "cursor-pointer hover:opacity-60" : ""}`}
      onClick={showImages ? () => showImages(product.id) : null}
    >
      {product.images.map((i, index) => (
        <ImageLoader key={index} size="w-20 h-20 z-0" src={i.url} />
      ))}
    </div>
  );
};

export const ProductImagesV2 = ({ images, size = "w-12 h-12" }) => {
  return (
    <div className="flex flex-wrap">
      {images.map((i) => (
        <img
          className={`mr-2 mb-2 rounded-lg ${size}`}
          alt={i.url}
          src={i.url}
          key={i.id}
        />
      ))}
    </div>
  );
};

export default ProductImages;
