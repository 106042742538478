import { useMutation } from "@apollo/client";
import { parseError } from "apollo";
import signatureStella from "assets/signatureStella.png";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import CharlesButton from "components/charles/base";
import { GENERATE_DOCUMENT } from "graphql/mutations";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import http from "utils/http";
import { CONFIRM_PRODUCT_LIST_DOCUMENT } from "./graphql";

const DOCForm = ({ productListId, originalValues }) => {
  const [productList, setProductList] = useState(originalValues);

  const [useEuSignature, setUseEuSignature] = useState(false);
  const status = originalValues.status ?? "PENDING";
  const [confirmDoc, confirmDocRes] = useMutation(
    CONFIRM_PRODUCT_LIST_DOCUMENT,
    {
      onCompleted() {
        Alert("success", "Document is updated.");
      },
      onError(error) {
        Alert(error, parseError(error));
      },
    },
  );
  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function addReportHandler() {
    setProductList({
      ...productList,
      reports: [
        ...productList.reports,
        { refNo: "", notifyBody: "", documentNo: "", title: "", date: "" },
      ],
    });
  }

  function removeReport(index) {
    const reports = [...productList.reports];
    reports.splice(index, 1);
    setProductList({ ...productList, reports });
  }

  function onChangeReport(index, key, value) {
    const reports = [...productList.reports];
    reports[index][key] = value;
    setProductList({ ...productList, reports });
  }

  const [exportingPDF, setExportingPDF] = useState(false);

  function confirmHandler() {
    if (window.confirm("Are you sure to confirm this document?")) {
      const docData = JSON.stringify({ ...productList, status: "CONFIRMED" });
      confirmDoc({ variables: { id: productListId, docData } });
    }
  }

  function cancelHandler() {
    if (window.confirm("Are you sure to reset to pending state?")) {
      const docData = JSON.stringify({ ...productList, status: "PENDING" });
      confirmDoc({ variables: { id: productListId, docData } });
    }
  }

  function generatePdfHandler() {
    const name = `${productList.name} - DOC`;
    const variables = {
      docType: "doc",
      name,
      data: JSON.stringify({ ...productList, useEuSignature }, "", 4),
    };

    setExportingPDF(true);
    generateDocument({ variables })
      .then((res) =>
        http.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}generated-document/${res.data.generateDocument.document.id}/?pdf=1`,
          {
            responseType: "blob",
            withCredentials: true,
          },
        ),
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name + ".pdf");
        document.body.appendChild(link);
        link.click();
        setExportingPDF(false);
      })
      .catch((e) => {
        Alert("error", e.message);
        setExportingPDF(false);
      });
  }

  return (
    <div className="grid grid-cols-12 gap-6 p-6 text-sm">
      <div className="col-span-9">
        <div className="space-y-6 card p-16">
          <div className="flex justify-between">
            <div>
              <h2>EC Declaration of Conformity</h2>
              <h5>In accordance with EN ISO 17050-1:2004</h5>
            </div>
            <div>
              <img
                className="h-10"
                src="https://cdn.waboba.com/public/waboba-logo-w-waboba.png"
                alt="Waboba Logo"
              />
            </div>
          </div>

          <div>
            <label htmlFor="">
              This declaration of conformity is issued under the sole
              responsibility of the manufacturer.
            </label>
          </div>

          <div className="pt-4">
            <div>
              <label className="font-semibold">We </label>
              <span>WABOBA AB</span>
            </div>

            <div>
              <label className="font-semibold">of </label>
              <span>Hornsgatan 110 117 26 Stockholm Sweden</span>
            </div>
          </div>

          <div>
            <label className="font-semibold">
              in accordance with the following Directive(s):
            </label>
            <div>2009/48/EC Toys safety</div>
          </div>

          <div>
            <label className="font-semibold">hereby declare that:</label>
            <div className="-mx-2 whitespace-nowrap mt-3">
              <table>
                <tbody>
                  <tr>
                    <td className="w-20">
                      <label htmlFor="">Name:</label>
                    </td>
                    <td>
                      <Input
                        disabled={status === "CONFIRMED"}
                        className="w-full"
                        value={productList.name}
                        onChange={(e) =>
                          setProductList({
                            ...productList,
                            name: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label htmlFor="">Model Number:</label>
                    </td>
                    <td>
                      <Input
                        disabled={status === "CONFIRMED"}
                        className="w-full"
                        value={productList.number}
                        onChange={(e) =>
                          setProductList({
                            ...productList,
                            number: e.target.value,
                          })
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex flex-wrap">
              {productList.sampleImages.map((i) => (
                <div key={i.id}>
                  <img className="w-44" src={i.url} alt={i.id} />
                </div>
              ))}
            </div>
          </div>

          <div>
            <label htmlFor="">
              is in conformity with the applicable requirements of the following
              documents:
            </label>

            <div className="py-6">
              <table className="border">
                <thead className="whitespace-nowrap">
                  <tr>
                    <th className="">Ref.No.</th>
                    <th className="">Notified Body</th>
                    <th className="">Document No.</th>
                    <th className="">Title</th>
                    <th className="text-right">Edition/ Date</th>
                  </tr>
                </thead>
                <tbody>
                  {productList.reports.map((i, index) => (
                    <tr key={index} className="border-y">
                      <td>
                        <div className="flex items-center space-x-2">
                          {status !== "CONFIRMED" && (
                            <CharlesButton
                              danger
                              onClick={() => removeReport(index)}
                            >
                              <BsTrash />
                            </CharlesButton>
                          )}
                          <Input
                            className="w-full"
                            value={i.refNo}
                            onChange={(e) =>
                              onChangeReport(index, "refNo", e.target.value)
                            }
                            disabled={status === "CONFIRMED"}
                          />
                        </div>
                      </td>
                      <td className="whitespace-nowrap">
                        <Input
                          className="w-full"
                          value={i.notifyBody}
                          onChange={(e) =>
                            onChangeReport(index, "notifyBody", e.target.value)
                          }
                          disabled={status === "CONFIRMED"}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={i.documentNo}
                          onChange={(e) =>
                            onChangeReport(index, "documentNo", e.target.value)
                          }
                          disabled={status === "CONFIRMED"}
                        />
                      </td>
                      <td>
                        <Input
                          className="w-full"
                          value={i.title}
                          onChange={(e) =>
                            onChangeReport(index, "title", e.target.value)
                          }
                          disabled={status === "CONFIRMED"}
                        />
                      </td>
                      <td className="text-right whitespace-nowrap">
                        <input
                          className="border rounded-lg px-2 py-1"
                          type="date"
                          value={i.date}
                          onChange={(e) =>
                            onChangeReport(index, "date", e.target.value)
                          }
                          disabled={status === "CONFIRMED"}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <CharlesButton
                        onClick={addReportHandler}
                        disabled={status === "CONFIRMED"}
                      >
                        + Add
                      </CharlesButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            I hereby declare that the article named above has been designed to
            comply with the relevant sections of the above referenced
            specifications. The unit complies with all applicable Essential
            Requirements of the Directives.
          </div>

          <div className="-mx-2">
            <table>
              <tbody>
                <tr>
                  <td className=" w-20">
                    <label htmlFor="">Signed for and on behalf of: </label>
                  </td>
                  <td className="">
                    <img
                      className="h-20"
                      src={signatureStella}
                      alt="Signature"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Name: </label>
                  </td>
                  <td>Stella Huang</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Position: </label>
                  </td>
                  <td>Head of Global Operations</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Place: </label>
                  </td>
                  <td>Guangzhou, China</td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="">Date: </label>
                  </td>
                  <td>
                    <input
                      className="border px-4 py-1 rounded-xl"
                      type="date"
                      value={productList.signDate}
                      onChange={(e) =>
                        setProductList({
                          ...productList,
                          signDate: e.target.value,
                        })
                      }
                      disabled={status === "CONFIRMED"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className=" col-span-3 flex flex-col space-y-6">
        <div className="card">
          <h5>Document Status</h5>
          <div className="mt-2">
            <div
              className={`font-black text-2xl
            ${status === "PENDING" ? "text-orange-600" : status === "CONFIRMED" ? "text-green-600" : "text-red-500"}
            `}
            >
              {status}
            </div>
          </div>
          {status === "PENDING" && (
            <div className="mt-2">
              This document is pending confirmation, be careful for the
              information in this document.
            </div>
          )}
          {status === "PENDING" && (
            <CharlesButton
              className="mt-4"
              onClick={confirmHandler}
              loading={confirmDocRes.loading}
            >
              Confirm Document
            </CharlesButton>
          )}

          {status === "CONFIRMED" && (
            <CharlesButton className="mt-4" danger onClick={cancelHandler}>
              Reset to Pending
            </CharlesButton>
          )}
        </div>

        <div className="card">
          <div className="hidden">
            <label
              htmlFor="useEuSignature"
              className="flex items-center space-x-2"
            >
              <input
                type="checkbox"
                id="useEuSignature"
                checked={useEuSignature}
                onChange={(e) => setUseEuSignature(e.target.checked)}
              />
              <span>Use EU Signature:</span>
            </label>
          </div>
          <CharlesButton onClick={generatePdfHandler} loading={exportingPDF}>
            Generate PDF
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default DOCForm;
