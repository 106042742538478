import { useMutation, useQuery } from "@apollo/client";
import Page from "components/Page";
import { useParams } from "react-router-dom";
import { FETCH_PRODUCT, RETAILER_SAVE_PRODUCT } from "./graphlq";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { useEffect, useState } from "react";
import CharlesButton from "components/charles/base";
import { Input, Text } from "components/Form";
import ProductMappingView from "./ProductMappingView";
import ProductImagesView from "./ProductImagesView";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import moment from "moment";
import InventoryView from "./InventoryView";
import BaseInfo from "./BaseInfo";

const ProductDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_PRODUCT, {
    variables: { id },
  });

  const [product, setProduct] = useState(null);
  const [save, saveRes] = useMutation(RETAILER_SAVE_PRODUCT, {
    onCompleted() {
      Alert("success", "Product saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  function saveHandler() {
    save({
      variables: {
        id,
        productInput: {
          name: product.name,
          description: product.description,
          price: product.price,
          retailValue: product.retailValue || 0,
          recommendedRetailPrice: product.recommendedRetailPrice || 0,
          isActive: product.isActive,
          outOfStock: product.outOfStock,
          backToStockAt: product.backToStockAt,
          imageUrls: product.images.map((i) => i).join("\n"),
        },
        bom: product.bom.map((b) => ({
          wisProductId: b.wisProduct.id,
          qty: b.qty,
          totalPrice: b.totalPrice,
        })),
      },
    });
  }

  useEffect(() => {
    if (data) {
      setProduct({
        ...data.retailerProduct,
        backToStockAt: data.retailerProduct.backToStockAt
          ? moment(data.retailerProduct.backToStockAt).format("YYYY-MM-DD")
          : null,
      });
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;
  if (!product) return null;

  return (
    <Page
      title={product.name}
      backTo="./.."
      rightButtons={
        <div>
          <CharlesButton onClick={saveHandler} loading={saveRes.loading}>
            Save
          </CharlesButton>
        </div>
      }
    >
      <div className="p-6 grid grid-cols-12 gap-6 text-sm">
        <div className="col-span-full space-y-6">
          <BaseInfo product={product} setProduct={setProduct} />

          <ProductMappingView product={product} setProduct={setProduct} />

          <InventoryView data={data} />

          <ProductImagesView product={product} setProduct={setProduct} />
        </div>
      </div>
    </Page>
  );
};

export default ProductDetail;
