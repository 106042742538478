import React from "react";
import { Routes, Route } from "react-router-dom";
import QCAnalytics from "./qc";
import ProductionAnalytics from "./production";
import GradingAnalytics from "./grading";
import OdooAnalyticsPages from "./odoo";
import AnalyticsSales from "./sales";

function AnalyticsIndex() {
  return (
    <Routes>
      <Route path="sales/*" element={<AnalyticsSales />} />
      <Route path="qc" element={<QCAnalytics />} />
      <Route path="production/*" element={<ProductionAnalytics />} />
      <Route path="grading/*" element={<GradingAnalytics />} />
      <Route path="odoo/*" element={<OdooAnalyticsPages />} />
    </Routes>
  );
}

export default AnalyticsIndex;
