import { gql } from "@apollo/client";

export const FETCH_ACK_ORDERS_REQUIRED_DATA = gql`
  query FETCH_ACK_ORDERS_REQUIRED_DATA($barcodes: [String]) {
    stockCategories: listAmazonStockCategories {
      id
      name
    }
  }
`;

export const FETCH_WAREHOUSE_PRODUCTS = gql`
  query FETCH_WAREHOUSE_PRODUCTS($id: ID!) {
    amazonStockCategory(id: $id) {
      id
      name
      regions
      stocks {
        id
        name
        odooId
        barcode
        number
        asin
        qtyPerMaster
        unitCost
        initialQty
        latestQty
        wisProduct {
          id
          images: computedImages(size: "300x300") {
            id
            url
          }
        }
      }
    }
  }
`;
