import { useMutation } from "@apollo/client";
import { UPDATE_ASSESSMENT_QUESTION } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { Text } from "components/Form";
import { useState } from "react";
import CharlesButton from "components/charles/base";

const InternalNoteForm = ({ assessmentQuestion, hide }) => {
  const [update, updateRes] = useMutation(UPDATE_ASSESSMENT_QUESTION, {
    onCompleted() {
      Alert("success", "Internal note updated.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [internalNote, setInternalNote] = useState(
    assessmentQuestion.internalNote,
  );

  return (
    <div>
      <Text
        value={internalNote}
        onChange={(e) => setInternalNote(e.target.value)}
      />

      <hr />

      <div>
        <CharlesButton
          primary
          loading={updateRes.loading}
          onClick={() =>
            update({
              variables: {
                id: assessmentQuestion.id,
                internalNote,
              },
            })
          }
        >
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default InternalNoteForm;
