import React from "react";
import { Route, Routes } from "react-router-dom";
import ShortLinks from "./shortlinks";
import QRCodePage from "./QRCodePage";
import ShopifyPage from "./shopify";
import Google from "./google";
import PatentTask from "./patent";
import PatentDetail from "./patent/PatentDetail";
import PackageTracking from "./package";
import PackageInfo from "./package/PackageInfo";
import PackageDestination from "./package/PackageDestination";
import ModelView from "./3d";

function ToolsIndex() {
  return (
    <Routes>
      <Route>
        <Route path="shortlinks" element={<ShortLinks />} />
        <Route path="qrcode" element={<QRCodePage />} />
        <Route path="shopify" element={<ShopifyPage />} />
        <Route path="google" element={<Google />} />
        <Route path="patentai" element={<PatentTask />} />
        <Route path="patentai/:cpc" element={<PatentDetail />} />
        <Route path="package" element={<PackageTracking />} />
        <Route path="package/:packageId" element={<PackageInfo />} />
        <Route path="package/destination" element={<PackageDestination />} />
        <Route path="3d" element={<ModelView />} />
      </Route>
    </Routes>
  );
}

export default ToolsIndex;
