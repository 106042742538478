import { gql } from "@apollo/client";

export const FETCH_ANALYTICS_SALES_REPORT = gql`
  query FETCH_ANALYTICS_SALES_REPORT(
    $startYear: Int!
    $endYear: Int!
    $partnerId: String
  ) {
    analyticsSalesReportByMonth(
      startYear: $startYear
      endYear: $endYear
      partnerId: $partnerId
    )
    analyticsSalesReportByCategory(
      startYear: $startYear
      endYear: $endYear
      partnerId: $partnerId
    )
  }
`;
