import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { useEffect, useState } from "react";
import { UPDATE_PACKAGE_CC } from "./graphql";
import { Alert } from "components/Toast";
import InputSelect from "components/InputSelect";
import { ImCross } from "react-icons/im";

const CcList = ({ internalUsers, samples, parcel, isEditable }) => {
  let allCcs = [...parcel.cc];
  // Note: compatible with the legacy cc (from each sample)
  samples.forEach((sample) => {
    sample.cc.forEach((cc) => {
      if (!allCcs.some((c) => c.id === cc.id)) {
        allCcs.push(cc);
      }
    });
  });

  const [ccs, setCcs] = useState(allCcs);
  const [inputText, setInputText] = useState("");

  const [updatePackageCc] = useMutation(UPDATE_PACKAGE_CC, {
    onCompleted: () => {
      Alert("success", "Cc updated successfully");
      hide();
    },
  });

  useEffect(() => {
    if (ccs !== allCcs) {
      updatePackageCc({
        variables: {
          id: parcel.id,
          ccs: ccs.map((cc) => cc.id),
        },
      });
    }
  }, [ccs]);

  return (
    <div className="flex items-center space-x-2">
      {ccs?.length > 0 && (
        <div className="flex items-center group space-x-2">
          {ccs.map((cc, index) => (
            <div className="flex items-center space-x-2" key={index}>
              <span className="font-bold">{cc.email}</span>
              <CharlesButton
                icon={
                  <ImCross className="text-xxs opacity-0 group-hover:opacity-100" />
                }
                danger={true}
                onClick={() => setCcs(ccs.filter((_, i) => i !== index))}
              />
            </div>
          ))}
        </div>
      )}

      {isEditable && (
        <InputSelect
          options={internalUsers.map((user) => ({
            name: user.email,
            cc: user,
          }))}
          onSelect={(selectedOption) => {
            if (!ccs.some((cc) => cc.id === selectedOption.cc.id)) {
              setCcs([...ccs, selectedOption.cc]);
            }
          }}
          onChange={setInputText}
          value={inputText}
          placeholder="Search a user"
          className="w-40"
        />
      )}
    </div>
  );
};

export default CcList;
