import { Select } from "components/Form";

const QuestionFilters = ({ assessment, filter, setFilter }) => {
  return (
    <div className="flex justify-end">
      <div className="space-x-3">
        <label>Filter: </label>
        <Select
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        >
          <option value="all">All</option>
          <optgroup label="Score">
            <option value="score_a">Score A</option>
            <option value="score_b">Score B</option>
            <option value="score_c">Score C</option>
            <option value="score_d">Score D</option>
            <option value="score_no">No Score</option>
          </optgroup>

          <optgroup label="Improvement">
            <option value="required_improvement">Required Improvement</option>
            <option value="received_improvement">Received Improvement</option>
          </optgroup>

          <optgroup label="Check Required">
            <option value="confirm_required">📋 On-Site</option>
            <option value="check_docs_required">📄 Docs</option>
            <option value="check_environment_required">🏭 Environment</option>
            <option value="check_with_workers_required">👷 Workers</option>
            <option value="check_with_owner_required">👨🏻‍💼 Owner</option>
          </optgroup>

          <optgroup label="Follow Up">
            <option value="follow_up_this_time">Follow Up This Time</option>
            <option value="follow_up_next_time">Follow Up Next Time</option>
          </optgroup>
        </Select>
      </div>
    </div>
  );
};

export default QuestionFilters;
