import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Button, CheckBox, NavigationBar } from "components/base";
import SearchBar from "components/SearchBar";
import { searchByProp } from "utils/search";
import NoOdooIdReminder from "./NoOdooIdReminder";
import odooIcon from "assets/odoo-icon.svg";
import BatchUpdateInput from "./BatchUpdateInput";
import http from "utils/http";
import QuotationFiles from "./QuotationFiles";
import { useModals } from "ModalProvider";
import { IoStatsChart } from "react-icons/io5";
import ProductCostHistory from "./ProductCostHistory";
import { FiEdit } from "react-icons/fi";
import AppConfig from "AppSettings";
import CharlesButton from "components/charles/base";
import BatchSyncPriceToOdooView from "./BatchSyncPriceToOdooView";
import { currencySymbol } from "pages/price/PriceWithCurrency";
import moment from "moment";
import { Select } from "components/Form";
import ProductPricesView from "../ProductPricesView";
import EffectedProductsView from "../EffectedProductsView";

export const FETCH_FACTORY_DETAIL = gql`
  query FETCH_FACTORY_DETAIL($id: ID!) {
    factory(id: $id) {
      id
      name
      odooId
      # quotationFiles {
      #   id
      #   name
      #   src
      #   createdAt
      # }
      products {
        id
        odooId
        odooTemplateId
        number
        name
        isActive
        moq
        defaultQty
        itemsPerSet
        cost
        children {
          id
        }
        prices {
          id
          factory {
            id
            name
            nickName
            odooId
          }
          minQty
          price
          currency
          supplierUpdatedAt
          archivedAt
          archivedNotes
          archivedCode
          updatedAt
          avaliableFromMonth
          avaliableToMonth
        }
        archivedAt
      }
      # odooProductPrices
    }
  }
`;

const SupplierDetail = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_FACTORY_DETAIL, {
    variables: { id },
  });
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("0");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { present, hide } = useModals();
  const chartModal = useModals();
  const batchSyncModal = useModals();
  const effectedModal = useModals();

  const [isExporting, setIsExporting] = useState(false);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const factory = data.factory;

  const odooProductPrices = data.factory.odooProductPrices
    ? JSON.parse(data.factory.odooProductPrices)
    : [];

  const validPrice = (i) =>
    i.factory && i.factory.id === factory.id && i.archivedAt === null;

  const products = factory.products
    .filter((i) => i.archivedAt === null && i.isActive)
    .sort((a, b) => (a.number > b.number ? 1 : -1))
    .filter((i) => searchByProp(i, ["number"], searchText))
    .map((i) => {
      if (i.children.length > 0) {
        // if product has bom, use the computed cost.
        const odooPrice = i.odooId
          ? odooProductPrices.find(
              (x) =>
                x.product_id[0] === i.odooId &&
                x.partner_id[0] === factory.odooId,
            )
          : null;
        const wis = `${currencySymbol("USD")} ${i.cost}`;
        const odoo = odooPrice
          ? `${currencySymbol(odooPrice.currency_id[1])} ${odooPrice.price}`
          : null;

        const computedPrices = [{ qty: 0, wis, odoo, synced: wis === odoo }];

        const synced = i.odooId ? computedPrices.every((i) => i.synced) : true;

        return { ...i, computedPrices, synced };
      } else {
        const odooPrices = i.odooId
          ? odooProductPrices.filter(
              (x) =>
                x.product_id[0] === i.odooId &&
                x.partner_id[0] === factory.odooId,
            )
          : null;
        const computedPrices = i.prices.filter(validPrice).reduce((res, x) => {
          const odooPrice = odooPrices
            ? odooPrices.find((op) => op.min_qty === x.minQty)
            : null;
          const wis = `${currencySymbol(x.currency)} ${x.price}`;
          const odoo = odooPrice
            ? `${currencySymbol(odooPrice.currency_id[1])} ${odooPrice.price}`
            : null;
          return [...res, { qty: x.minQty, wis, odoo, synced: wis === odoo }];
        }, []);
        const synced = i.odooId ? computedPrices.every((i) => i.synced) : true;
        const supplierUpdatedAt = i.prices
          .map((x) => new Date(x.supplierUpdatedAt))
          .reduce((a, b) => (a > b ? a : b), null);
        return { ...i, computedPrices, synced, supplierUpdatedAt };
      }
    })
    .filter((i) => (filter === "UNSYNCED" ? !i.synced : true));

  const hasSelectedProduct = (product) =>
    selectedProducts.find((i) => i.id === product.id) !== undefined;

  function onSelectProduct(product) {
    let updatedSelectedProducts = [];
    if (hasSelectedProduct(product)) {
      updatedSelectedProducts = selectedProducts.filter(
        (i) => i.id !== product.id,
      );
    } else {
      updatedSelectedProducts = [...selectedProducts, product];
    }
    setSelectedProducts(updatedSelectedProducts);
  }

  function batchUpdate() {
    present({
      title: "Batch Update",
      children: (
        <BatchUpdateInput
          factoryId={id}
          hide={hide}
          products={selectedProducts}
          handleEffectedProducts={handleEffectedProducts}
        />
      ),
    });
  }

  function handleEffectedProducts(effectedProducts) {
    effectedModal.present({
      title: "Effected Products",
      maxWidth: "max-w-6xl",
      isBeingPresented: true,
      children: <EffectedProductsView products={effectedProducts} />,
    });
  }

  function exportPdf() {
    setIsExporting(true);
    http
      .post(
        "/export/supplier-cost/pdf/",
        {
          id: factory.id,
          product_ids: selectedProducts.map((i) => parseInt(i.id)),
        },
        {
          baseURL: process.env.REACT_APP_SERVER_BASE_URL,
          timeout: 60000,
          responseType: "blob",
          withCredentials: true,
        },
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${factory.name}_PRICE_LIST.pdf`);
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
      })
      .catch((error) => {
        alert(`Oops! ${error}`);
        setIsExporting(false);
      });
  }

  function tryBatchSyncPriceToOdoo() {
    batchSyncModal.present({
      title: "Batch Sync Price to Odoo",
      center: true,
      children: (
        <BatchSyncPriceToOdooView
          products={selectedProducts}
          hide={batchSyncModal.hide}
        />
      ),
    });
  }

  return (
    <>
      <NavigationBar
        title={factory.name}
        backTo="/price/suppliers"
        rightButtons={
          <CharlesButton
            onClick={() =>
              present({
                title: "Quotation Files",
                // center: factory.quotationFiles.length < 6,
                children: <QuotationFiles factory={factory} />,
              })
            }
          >
            Quotation Files
          </CharlesButton>
        }
      />

      {!factory.odooId ? (
        <div className="p-6">
          <NoOdooIdReminder factoryId={factory.id} />
        </div>
      ) : (
        <>
          <div className="flex items-center space-x-8 pt-6 px-6">
            <div className="flex-1">
              <SearchBar
                placeholder="Search by item number"
                value={searchText}
                onChange={setSearchText}
                autoFocus
              />
            </div>
            <div>
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="0">All</option>
                <option value="UNSYNCED">Unsynced</option>
              </Select>
            </div>
            {selectedProducts.length > 0 ? (
              <>
                <CharlesButton
                  onClick={() => {
                    setSelectedProducts([]);
                  }}
                >
                  Clear Selection
                </CharlesButton>
                <CharlesButton onClick={batchUpdate}>
                  Batch Update
                </CharlesButton>
                <CharlesButton
                  className="text-purple-600"
                  onClick={tryBatchSyncPriceToOdoo}
                >
                  Batch Sync to Odoo
                </CharlesButton>
                <CharlesButton onClick={exportPdf} loading={isExporting}>
                  Export
                </CharlesButton>
              </>
            ) : null}
          </div>

          {products.length > 0 ? (
            <>
              <div className="card rounded-2xl p-0 overflow-auto shadow-md mx-6 mt-6 flex-1">
                <div className="h-full relative dark:text-gray-400">
                  <table>
                    <thead>
                      <tr className="sticky top-0 bg-white backdrop-blur bg-opacity-80 shadow-sm dark:bg-gray-800">
                        <th className="py-3 px-4">
                          <div className="flex items-center w-3/5">
                            <CheckBox
                              checked={
                                selectedProducts.length === products.length
                              }
                              onChange={() =>
                                selectedProducts.length === products.length
                                  ? setSelectedProducts([])
                                  : setSelectedProducts(products)
                              }
                            />
                            <div className="text-left px-4">
                              Products({products.length})
                            </div>
                          </div>
                        </th>
                        <th className="text-right">QTY</th>
                        <th className="text-right">Cost</th>
                        <th className="text-right text-purple-600">Odoo</th>
                        <th className="text-right pr-4">Supplier Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, index) => (
                        <ProductRow
                          key={index}
                          selected={hasSelectedProduct(product)}
                          onSelectProduct={onSelectProduct}
                          onClick={() =>
                            present({
                              title: "Edit Price",
                              subtitle: `[${product.number}] ${product.name}`,
                              maxWidth: "max-w-6xl",
                              children: (
                                <ProductPricesView
                                  id={product.id}
                                  currentFactory={factory}
                                />
                              ),
                            })
                          }
                          product={product}
                          validPrice={validPrice}
                          showChart={() => {
                            chartModal.present({
                              title: product.name,
                              maxWidth: "max-w-6xl",
                              children: (
                                <ProductCostHistory product={product} />
                              ),
                            });
                          }}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="text-right opacity-70 px-6 py-4">
                {AppConfig.exchangeRateNote}
              </div>
            </>
          ) : (
            <div className="p-6">
              <label>
                {searchText
                  ? "No Products found."
                  : "No Products for this supplier."}
              </label>
            </div>
          )}
        </>
      )}
    </>
  );
};

const ProductRow = ({
  product,
  selected,
  onSelectProduct,
  onClick,
  showChart,
}) => {
  return (
    <tr
      className={`border-t dark:border-gray-700 py-3
               ${selected ? " bg-green-50 dark:bg-green-900 dark:bg-opacity-50" : ""}
               ${product.synced ? "" : " text-red-600"}
         `}
    >
      <td className="flex items-center px-4 space-x-4">
        <CheckBox
          checked={selected ? " bg-green-200" : ""}
          onChange={() => onSelectProduct(product)}
        />
        <div className="flex items-center space-x-2">
          {product.odooId ? (
            <img
              className="mr-2"
              style={{ height: 16 }}
              src={odooIcon}
              alt="odoo product"
            />
          ) : null}
          <div>
            [{product.number}] {product.name}
          </div>

          <div className="flex items-center ml-4">
            <Button
              className="whitespace-nowrap"
              onClick={onClick}
              size={18}
              leftIcon={<FiEdit />}
            />
            <IoStatsChart
              className="inline-block cursor-pointer text-blue-600 hover:text-blue-600 pl-2 w-6"
              size={12}
              onClick={showChart}
            />
          </div>
        </div>
      </td>
      <td className="text-right">
        {product.computedPrices.map((i, index) => (
          <div key={index}>{i.qty}</div>
        ))}
      </td>
      <td className="text-right">
        {product.computedPrices.map((i, index) => (
          <div key={index}>{i.wis}</div>
        ))}
      </td>
      <td className="text-right">
        {product.computedPrices.map((i, index) => (
          <div key={index}>{i.odoo}</div>
        ))}
      </td>
      <td className="text-right px-4">
        {product.supplierUpdatedAt
          ? moment(product.supplierUpdatedAt).format("YYYY-MM-DD")
          : "-"}
      </td>
    </tr>
  );
};

export default SupplierDetail;
