import { formatDate } from "react-day-picker/moment";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import AcknowledgedOrder from "./AcknowledgedOrder";
import {
  useAmazonPurchaseOrders,
  useImportedAmazonPurchaseOrders,
} from "hooks/useAmazon";
import { Tag } from "components/base";
import NewOrderView from "./NewOrderView";
import { useModals } from "ModalProvider";
import { AmazonContext } from ".";
import RegionRows from "./orders/RegionRows";
import { createContext } from "react";
import AckOrdersView from "./orders/AckOrdersView";

const AWS_REGIONS = ["GB", "DE", "IT", "ES", "FR"];
const STATE_OPTIONS = ["New", "Acknowledged", "Closed"];

export const AmzContext = createContext({});

const OrdersPage = () => {
  const { stockCategories } = useContext(AmazonContext);
  const [regions, setRegions] = useState(
    localStorage.getItem("amazon:regions")
      ? JSON.parse(localStorage.getItem("amazon:regions"))
      : AWS_REGIONS,
  );
  const [orderState, setOrderState] = useState(
    localStorage.getItem("amazon:orderState") || STATE_OPTIONS[0],
  );

  useEffect(() => {
    if (regions)
      localStorage.setItem("amazon:regions", JSON.stringify(regions));
    if (orderState) localStorage.setItem("amazon:orderState", orderState);
  }, [regions, orderState]);

  const acknowledgementModal = useModals();
  const ackOrdersModal = useModals();

  function ackOrders({ region, orders }) {
    ackOrdersModal.present({
      title: "Ack Orders",
      subtitle: `Acknowledge ${region} orders(${orders.length})`,
      fullscreen: true,
      isBeingPresented: true,
      children: <AckOrdersView region={region} orders={orders} />,
    });
  }

  function selectOrder({ order, region }) {
    acknowledgementModal.present({
      title: `[${region}] ${order.purchaseOrderNumber}`,
      maxWidth: "max-w-full",
      children:
        order.purchaseOrderState === "New" ? (
          <NewOrderView
            regionCode={region}
            order={order}
            hide={acknowledgementModal.hide}
            stockCategories={stockCategories}
          />
        ) : (
          <AcknowledgedOrder regionCode={region} order={order} />
        ),
    });
  }

  return (
    <AmzContext.Provider value={{ ackOrders, orderState }}>
      <div className="p-6">
        <div className="flex space-x-16">
          <div className="flex mb-6 space-x-4">
            {AWS_REGIONS.map((i, index) => (
              <Tag
                title={i}
                key={index}
                selected={regions.includes(i)}
                onClick={() =>
                  setRegions((prev) =>
                    prev.includes(i)
                      ? prev.filter((j) => j !== i)
                      : [...prev, i],
                  )
                }
              />
            ))}
          </div>

          <div className="flex mb-6 space-x-4">
            {STATE_OPTIONS.map((i, index) => (
              <Tag
                title={i}
                key={index}
                selected={orderState === i}
                onClick={() => setOrderState(i)}
              />
            ))}
          </div>
        </div>

        <label className="block mb-4 text-orange-600 text-xs">
          Contact Charlie if there is any issue. Please double check the order
          status in Amazon Vendor Center.
        </label>

        <table className="bg-white dark:bg-gray-800 rounded overflow-auto">
          <thead>
            <tr className="text-left border-b dark:border-gray-700">
              <th className="py-3 px-6">Region</th>
              <th className="py-3 ">Purchase Order Number</th>
              <th className="py-3 text-center">Date</th>
              <th className="py-3 text-center">Window Start</th>
              <th className="py-3 text-center">Window End</th>
              <th className="py-3 text-center">Biling to Party</th>
              <th className="py-3 text-center">Ship to Party</th>
              <th className="py-3 text-center">Status</th>
              <th className="py-3 text-center">Odoo</th>
            </tr>
          </thead>
          <tbody>
            {regions.map((region, index) => (
              <RegionRows
                selectOrder={(order) => selectOrder({ region, order })}
                region={region}
                orderState={orderState}
                key={index}
              />
            ))}
          </tbody>
        </table>
      </div>
    </AmzContext.Provider>
  );
};

const RegionOrders = ({ region, orderState, selectOrder }) => {
  const { loading, error, purchaseOrders } = useAmazonPurchaseOrders(
    region,
    orderState,
  );

  if (loading)
    return (
      <tr>
        <td colSpan={9}>
          <div className="p-8 relative">
            <InlineSpinner text={`loading ${region} orders..`} />
          </div>
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={9}>
          <Errors error={error} />
        </td>
      </tr>
    );

  return (
    <OrdersList
      region={region}
      purchaseOrders={purchaseOrders}
      orderState={orderState}
      selectOrder={selectOrder}
    />
  );
};

const OrdersList = ({ region, purchaseOrders, orderState, selectOrder }) => {
  const numbers = purchaseOrders.map((i) => i.purchaseOrderNumber);
  const { loading, error, data } = useImportedAmazonPurchaseOrders(numbers);

  if (loading)
    return (
      <tr>
        <td colSpan={9}>
          <div className="p-8 relative">
            <InlineSpinner />
          </div>
        </td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td colSpan={9}>
          <Errors error={error} />
        </td>
      </tr>
    );

  const computedOrders = purchaseOrders
    .map((i) => {
      const dates = i.orderDetails.deliveryWindow.split("--");
      const windowStart = formatDate(dates[0], "YYYY-MM-DD");
      const windowEnd = formatDate(dates[1], "YYYY-MM-DD");
      const purchaseOrderDate = new Date(i.orderDetails.purchaseOrderDate);
      const odoo = data.amzVcPurchaseOrders.find(
        (j) => j.number === i.purchaseOrderNumber,
      );
      const wisId = odoo ? odoo.id : null;
      const odooSalesOrderId = odoo ? odoo.odooSalesOrderId : null;
      const odooSalesOrderName = odoo ? odoo.odooSalesOrderName : null;
      const expectedDate = odoo ? odoo.expectedDate : null;
      const isNew = odoo
        ? moment.duration(moment().diff(moment(odoo.createdAt))).asDays() < 1
        : false;
      return {
        ...i,
        purchaseOrderDate,
        windowStart,
        windowEnd,
        wisId,
        odooSalesOrderId,
        odooSalesOrderName,
        expectedDate,
        isNew,
      };
    })
    .sort((a, b) => (a.purchaseOrderDate < b.purchaseOrderDate ? 1 : -1));

  return (
    <>
      <tr>
        <td
          colSpan={9}
          className="px-6 border-t dark:border-gray-700 bg-gray-100 dark:bg-gray-900 bg-opacity-50"
        >
          <h4>{region}</h4>
        </td>
      </tr>

      {computedOrders.length === 0 ? (
        <tr>
          <td colSpan={9} className="border-t dark:border-gray-700 px-6">
            <label htmlFor="">No {orderState} orders</label>
          </td>
        </tr>
      ) : (
        computedOrders.map((order, index) => (
          <tr
            key={index}
            className="px-4 md:px-6 border-t dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50 cursor-pointer whitespace-nowrap"
            onClick={() => selectOrder(order)}
          >
            <td className="px-6">{region}</td>
            <td>
              <div className="flex items-center space-x-3">
                <div>{order.purchaseOrderNumber}</div>
                {order.isNew ? (
                  <div className="rounded-full text-white bg-green-500 dark:bg-green-600 text-xs px-2 py-1">
                    new
                  </div>
                ) : null}
              </div>
            </td>
            <td className="text-center">
              {formatDate(order.orderDetails.purchaseOrderDate, "YYYY-MM-DD")}
            </td>
            <td className="text-center">
              {formatDate(order.windowStart, "YYYY-MM-DD")}
            </td>
            <td className="text-center">
              {formatDate(order.windowEnd, "YYYY-MM-DD")}
            </td>
            <td className="text-center">
              {order.orderDetails.billToParty.partyId}
            </td>
            <td className="text-center">
              {order.orderDetails.shipToParty.partyId}
            </td>
            <td className="text-center">{order.purchaseOrderState}</td>
            <td className="text-center px-6">
              {order.odooSalesOrderId ? order.odooSalesOrderName : " - "}
            </td>
          </tr>
        ))
      )}
    </>
  );
};

export default OrdersPage;
