import React from "react";
import { NavigationLink } from "components/base";

export const LeftButtons = () => (
  <div className="flex space-x-4">
    <NavigationLink to="/manage/products" title="Products" />
    <NavigationLink to="/manage/product-lines" title="Product Lines" />
    <NavigationLink to="/manage/biz" title="BIZ Products" />
    {/* <NavigationLink to="/manage/current-products" title="Latest" /> */}
  </div>
);
