import { Input, Text } from "components/Form";

const BaseInfo = ({ product, setProduct }) => {
  return (
    <div className="card px-6 py-4">
      <h4>Basic Info</h4>
      <div className="-mx-2">
        <table>
          <tbody>
            <tr>
              <td>
                <label htmlFor="">Name: </label>
              </td>
              <td className="w-full">
                <Input
                  value={product.name}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                />
              </td>
            </tr>
            <tr>
              <td className=" align-top">
                <label htmlFor="">Description: </label>
              </td>
              <td>
                <Text
                  value={product.description}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      description: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="">Unit Price:</label>
              </td>
              <td>
                <Input
                  value={product.price}
                  onChange={(e) =>
                    setProduct({ ...product, price: e.target.value })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="">Retail Value:</label>
              </td>
              <td>
                <Input
                  value={product.retailValue}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      retailValue: e.target.value,
                    })
                  }
                />
              </td>
            </tr>

            <tr>
              <td>
                <label htmlFor="">Recommended Retail Price:</label>
              </td>
              <td>
                <Input
                  value={product.recommendedRetailPrice}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      recommendedRetailPrice: e.target.value,
                    })
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr />

      <div className="mt-4">
        <div className="flex items-center space-x-3">
          <label htmlFor="id-available">Available: </label>
          <input
            id="id-available"
            type="checkbox"
            checked={product.isActive}
            onChange={(e) =>
              setProduct({ ...product, isActive: e.target.checked })
            }
          />
        </div>
        <div className=" opacity-70 text-xs">
          Inactive product will not displayed in Retailer Store.
        </div>
      </div>
    </div>
  );
};

export default BaseInfo;
