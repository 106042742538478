import moment from "moment";
import useAssessment from "./useAssessment";

const ImprovementsView = ({ improvements }) => {
  const { assessmentStatus } = useAssessment();

  if (assessmentStatus === "REVIEWING") return null;

  return (
    <div className="mt-4">
      {improvements.length > 0 ? (
        <>
          <h5>Supplier Improvements</h5>
          <div className="mt-2 space-y-4">
            {improvements.map(({ node }, index) => (
              <div key={index} className="card bg-gray-100 px-5 py-3">
                <div className="opacity-70 text-xs">
                  {moment(node.updatedAt).format("YYYY-MM-DD")}
                </div>
                <div className="mt-1">{node.content}</div>
                <div className="text-xs">
                  {node.files.map((url, urlIndex) => (
                    <a
                      key={urlIndex}
                      className="block py-1"
                      href={url}
                      target="_blank"
                      rel="noreferer"
                    >
                      {url}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="opacity-70 text-red-600 font-semibold">
          Supplier has not submit any improvement yet.
        </div>
      )}
    </div>
  );
};

export default ImprovementsView;
