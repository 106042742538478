import AssessmentQuestion from "./AssessmentQuestion";

const CategoryQuestions = ({ category }) => {
  const categorySelfQuestions = category.questions.filter(
    (question) => question.question.category.id === category.id,
  );

  return (
    <div>
      {category.children?.map((child) => {
        return (
          <div key={child.id} className="pt-4">
            <h5 className="font-bold">
              {child.indexNumber}. {child.name}
            </h5>
            <CategoryQuestions
              category={child}
              requiredSupplier={category.requiredSupplier}
            />
          </div>
        );
      })}

      {categorySelfQuestions.length > 0 ? (
        <div>
          {categorySelfQuestions.map((question, questionIndex) => (
            <div
              key={question.id}
              className={`${questionIndex > 0 ? "border-t" : ""} border-gray-100 dark:border-gray-700 py-6`}
              id={question.id}
            >
              <AssessmentQuestion
                assessmentQuestion={question}
                number={question.question.indexNumber}
                requiredSupplier={category.requiredSupplier}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryQuestions;
