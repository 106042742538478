import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";

export const BATCH_SYNC_PRODUCT_PRICES_TO_ODOO = gql`
  mutation BATCH_SYNC_PRODUCT_PRICES_TO_ODOO($productIds: [ID!]!) {
    batchSyncProductPricesToOdoo(productIds: $productIds) {
      result
    }
  }
`;

const BatchSyncPriceToOdooView = ({ products, hide }) => {
  const validProducts = products.filter((i) => i.odooId);
  const invalidProducts = products.filter((i) => !i.odooId);
  const [sync, syncRes] = useMutation(BATCH_SYNC_PRODUCT_PRICES_TO_ODOO, {
    variables: {
      productIds: validProducts.map((i) => i.id),
    },
    onCompleted() {
      Alert("success", "Sync Success");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
    refetchQueries: ["FETCH_ODOO_PRODUCT_PRICES"],
    awaitRefetchQueries: true,
  });

  return (
    <div>
      <div>
        <div>
          Are you sure to sync {validProducts.length} product prices to Odoo.
        </div>

        {invalidProducts.length > 0 ? (
          <div>
            Note: {invalidProducts.length} products{" "}
            {invalidProducts.length > 1 ? "are" : "is"} not connected with Odoo,
            they will be ignore.
          </div>
        ) : null}
        <div className="mt-4 opacity-70 text-xs">
          This might take long if you sync too many products at once.
        </div>
      </div>

      <hr />

      <div>
        <CharlesButton primary onClick={sync} loading={syncRes.loading}>
          Confirm and Sync Now
        </CharlesButton>
      </div>
    </div>
  );
};

export default BatchSyncPriceToOdooView;
