import { Fragment } from "react";
import { qtyTypes } from "./consts";
import CellInput from "./CellInput";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ProductShipmentsView from "./shipments/ProductShipmentsView";
import EditShipmentView from "./shipments/EditShipmentView";
import { IoIosTime } from "react-icons/io";
import { computedForecastData } from "./utils";

const ForecastView = ({
  warehouse,
  products,
  setProducts,
  shipmentLines,
  syncConflictData,
}) => {
  const isInput = (qtyType) =>
    ["forecast", "begin_inv", "sales"].includes(qtyType);

  const hasProducts = Object.keys(products).length > 0;
  const shipmentsModal = useModals();
  const editShipmentModal = useModals();

  const computedData = computedForecastData(products, shipmentLines);

  // console.log("products", products);
  // console.log("computedData", computedData);
  // console.log("shipmentLines", shipmentLines);

  if (!hasProducts)
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-xl  text-center leading-relaxed opacity-70">
          <p className="font-bold">No products for this document.</p>
          <p>Please import from warehouse or add manually.</p>
        </div>
      </div>
    );

  function onChangeCell(id, month, qtyType, value) {
    setProducts((prevProducts) => {
      let newProducts = { ...prevProducts };
      console.log("newProducts", month, qtyType, value, newProducts[id]);
      newProducts[id][month][qtyType] = {
        ...newProducts[id][month][qtyType],
        ...value,
      };
      return newProducts;
    });
  }

  function viewShipments(product, shipments) {
    shipmentsModal.present({
      title: "Shipments",
      subtitle: product.sku,
      children: (
        <ProductShipmentsView
          shipments={shipments}
          editShipment={editShipment}
        />
      ),
    });
  }

  function editShipment(shipment) {
    editShipmentModal.present({
      title: "Edit Shipment",
      children: (
        <EditShipmentView
          id={shipment.id}
          warehouse={warehouse}
          hide={editShipmentModal.hide}
        />
      ),
    });
  }

  return (
    <div className="flex-1 overflow-auto">
      <table className="border-collapse whitespace-nowrap font-code">
        <thead>
          <tr className="sticky top-0 left-0 z-20">
            <th
              colSpan={2}
              className="sticky top-0 left-0 z-20 border bg-blue-100 dark:border-blue-800 dark:bg-blue-900 px-3 py-3"
            >
              Month / SKU
            </th>
            {Object.entries(products).map(([id, product]) => (
              <th
                key={id}
                className="border bg-blue-100 dark:border-blue-800 dark:bg-blue-900 px-3 font-sans text-right"
              >
                {product.sku}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
          {Array(12)
            .fill()
            .map((_, monthIndex) => (
              <Fragment key={monthIndex}>
                <tr>
                  <td
                    className="text-center text-xl bg-teal-100 dark:bg-teal-900 sticky left-0 z-10 border dark:border-teal-800"
                    rowSpan={qtyTypes.length + 1}
                  >
                    {monthIndex + 1}
                  </td>
                </tr>
                {qtyTypes.map((qtyType, index) => (
                  <tr key={index}>
                    <td className="bg-teal-50 dark:bg-teal-900 border dark:border-teal-800 capitalize left-[52.36px] z-10 sticky font-sans">
                      {qtyType.replace("_", " ")}
                    </td>
                    {Object.entries(products).map(([id, product]) => (
                      <td
                        className={`text-right border dark:border-gray-700 bg-white dark:bg-gray-800
                          ${isInput(qtyType) ? "p-0" : "px-3"}
                          `}
                        key={id}
                      >
                        {isInput(qtyType) ? (
                          <CellInput
                            syncedData={
                              syncConflictData && syncConflictData[id]
                                ? syncConflictData[id][monthIndex + 1]
                                  ? syncConflictData[id][monthIndex + 1][
                                      qtyType
                                    ]
                                  : null
                                : null
                            }
                            productId={id}
                            item={product[monthIndex + 1][qtyType]}
                            month={monthIndex + 1}
                            qtyType={qtyType}
                            onChange={(value) =>
                              onChangeCell(id, monthIndex + 1, qtyType, value)
                            }
                            placeholder={
                              computedData[id][monthIndex + 1].prevEndInv
                            }
                          />
                        ) : qtyType === "inbound" || qtyType === "outbound" ? (
                          computedData[id][monthIndex + 1][qtyType].shipments
                            .length > 0 ? (
                            <div className="flex justify-end items-center space-x-1">
                              <CharlesButton
                                className="z-0 hover:z-50"
                                icon={
                                  computedData[id][monthIndex + 1][qtyType]
                                    .state !== "COMPLETED" && (
                                    <IoIosTime className=" text-blue-600" />
                                  )
                                }
                                data-tips={
                                  computedData[id][monthIndex + 1][qtyType]
                                    .description
                                }
                                onClick={() =>
                                  viewShipments(
                                    product,
                                    computedData[id][monthIndex + 1][qtyType]
                                      .shipments,
                                  )
                                }
                              >
                                {`${computedData[id][monthIndex + 1][qtyType].qty}`}
                              </CharlesButton>
                            </div>
                          ) : null
                        ) : qtyType === "end_inv" ? (
                          computedData[id][monthIndex + 1].endInv
                        ) : (
                          product[monthIndex + 1][qtyType].qty
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                <tr>
                  <td></td>
                </tr>
              </Fragment>
            ))}

          <tr className="sticky bottom-0 z-30 font-bold text-base">
            <td
              className="bg-teal-50 dark:bg-teal-900 border dark:border-teal-800 capitalize left-0 py-3 sticky font-sans"
              colSpan={2}
            >
              End of the Year
            </td>
            {Object.keys(products).map((id) => (
              <td
                key={id}
                className={`text-right border dark:border-gray-700 dark:bg-gray-800 px-3
                  ${computedData[id][12].endInv > 0 ? "bg-white" : "text-red-500 bg-red-50"}
                  `}
              >
                {computedData[id][12].endInv}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ForecastView;
