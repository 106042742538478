import React from "react";
import { NavigationLink } from "components/base.js";

export const LeftButtons = () => (
  <div className="flex space-x-4">
    <NavigationLink to="/products/index" title="Products" />
    <NavigationLink to="/products/warnings" title="Warnings" />
    <NavigationLink to="/products/export" title="Export All" />
    <NavigationLink to="/products/collection-view" title="Collection View" />
    <NavigationLink to="/products/groups" title="Groups View" />
  </div>
);

export const RightButtons = () => <div className="flex space-x-4"></div>;
