import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "./Form.js";
import Icon from "./Icon.js";

function SearchBar({
  containerClassName = "",
  className = "",
  onChange,
  initialQuery = "",
  hide = false,
  ...rest
}) {
  const [text, setText] = useState(initialQuery);

  return (
    <div className={`relative ${containerClassName}`}>
      <Input
        value={text}
        onChange={(e) => {
          let text = e.target.value;
          setText(text);
          if (onChange) {
            onChange(text);
          }
        }}
        className={`
          ${className} cursor-pointer w-full px-4 block
               `}
        autoCorrect="none"
        autoCapitalize="none"
        spellCheck="none"
        {...rest}
      />
      <div
        className={`absolute right-0 px-2 top-0 h-full items-center cursor-pointer animate-ease-2 text-gray-600 hover:text-gray-900 active:text-gray-800
               ${text?.trim() === "" ? "opacity-0 invisible" : "opacity-100 visible"}
               ${hide ? "hidden" : "flex"}
               `}
        onClick={() => {
          setText("");
          onChange("");
        }}
      >
        <Icon icon="close" size={12} />
      </div>
    </div>
  );
}

export default SearchBar;
