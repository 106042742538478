import { useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { VALIDATE_PRODUCTION_PLAN } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import RoutesView from "./validatePlan/RoutesView";
import BomsView from "./validatePlan/BomsView";

const ConfirmPlanView = ({ plan, confirmTimeHandler }) => {
  const { loading, error, data } = useQuery(VALIDATE_PRODUCTION_PLAN, {
    variables: { planId: plan.id },
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner text="Validating Production Plan..." />;
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.validateProductionPlan);

  return (
    <div className=" space-y-8">
      <RoutesView routes={res.routes} />
      <BomsView boms={res.boms} />

      <div>
        <div>
          You are going to confirm the time for plan: <b>{plan.name}</b>, the
          estimate ready date is :{" "}
          <b>{moment(plan.readyDate).format("YYYY-MM-DD")}</b>.
        </div>

        <div>Are you sure to confirm the time for this plan?</div>

        <hr />

        <div>
          <CharlesButton primary onClick={confirmTimeHandler}>
            Confirm Time
          </CharlesButton>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPlanView;
