import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SORT_RETAILER_PRODUCTS } from "./graphlq";

const SortView = ({ originProducts, hide }) => {
  const [products, setProducts] = useState(originProducts);
  const [sortProducts, sortProductsRes] = useMutation(SORT_RETAILER_PRODUCTS, {
    variables: { productIds: products.map((i) => i.id) },
    onCompleted() {
      Alert("success", "Products are updated.");
      hide();
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const newProducts = reorder(
      products,
      result.source.index,
      result.destination.index,
    );
    setProducts(newProducts);
  }

  return (
    <div className="text-sm">
      <div className="text-xs opacity-70 mt-2">
        Drag and Drop to sort the products ordering.
      </div>
      <div className="mt-4">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-plans">
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {products.map((i, index) => (
                    <Draggable key={i.id} draggableId={i.id} index={index}>
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          provided.draggableProps.style = {
                            ...provided.draggableProps.style,
                            left: provided.draggableProps.style.offsetLeft,
                            top: provided.draggableProps.style.offsetTop,
                          };
                        }
                        return (
                          <div
                            className="py-2"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="card-in-modal py-3 px-4">
                              <div className="flex space-x-4 items-center">
                                {i.image ? (
                                  <img
                                    src={i.image + "?imageView2/2/w/120"}
                                    alt={i.name}
                                    className="w-8 h-8 rounded-xl"
                                  />
                                ) : (
                                  <div className="w-8 h-8 rounded-xl bg-gray-200"></div>
                                )}
                                <div>
                                  #{i.id} {i.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="mt-6">
        <CharlesButton
          primary
          onClick={sortProducts}
          loading={sortProductsRes.loading}
        >
          Save
        </CharlesButton>
      </div>
    </div>
  );
};

export default SortView;
