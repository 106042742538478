import { useMutation } from "@apollo/client";
import FactorySelector from "components/FactorySelector";
import CharlesButton from "components/charles/base";
import { useState } from "react";
import { CREATE_ASSESSMENT } from "./graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const CreateAssessmentView = ({ complete }) => {
  const [supplierId, setSupplierId] = useState(null);
  const [portalSupplierId, setPortalSupplierId] = useState(null);
  const [createAssessment, createAssessmentRes] = useMutation(
    CREATE_ASSESSMENT,
    {
      variables: { supplierId, portalSupplierId },
      onCompleted: (data) => {
        complete(data.createAssessment.assessment);
        Alert("success", "Assessment created");
      },
      onError: (error) => {
        Alert("error", parseError(error));
      },
      refetchQueries: ["FETCH_ASSESSMENTS"],
    },
  );

  return (
    <div>
      <div>
        <div className="flex items-center space-x-3">
          <label htmlFor="">Supplier: </label>
          <FactorySelector
            value={supplierId}
            onChange={(id) => {
              setSupplierId(id);
              setPortalSupplierId(id);
            }}
          />
        </div>
        <div className="text-sm text-gray-700">
          The supplier for the assessment. Use in most cases in our system,
          including purchase, inventory, production capacity, analytics, etc.
        </div>
      </div>

      <div>
        <div className="flex items-center space-x-3 mt-4">
          <label htmlFor="">Portal Supplier: </label>
          <FactorySelector
            value={portalSupplierId}
            onChange={setPortalSupplierId}
          />
        </div>
        <div className="text-sm text-gray-700">
          The supplier who will login to Supplier Portal and do the assessment.
        </div>
      </div>

      <hr />

      <div className="opacity-70 mt-4 text-sm">
        You create an assessment for a supplier. You start the assessment after
        creation. You can set a due date for the assessment. By default we use
        the latest question category we created at 2023 by ESG team for the
        assessment. If you need to update the questions, you should contact
        Charlie.
      </div>

      <div className="mt-10">
        <CharlesButton
          className="text-sm"
          primary
          next
          onClick={createAssessment}
          loading={createAssessmentRes.loading}
        >
          Create Now
        </CharlesButton>
      </div>
    </div>
  );
};

export default CreateAssessmentView;
