import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import UserSelector from "components/UserSelector";
import { Text } from "components/Form";
import Customer from "components/Customer";
import Card from "components/Card";
import CustomerDocuments from "./Documents";
import CustomerProducts from "./CustomerProducts";
import {
  FETCH_CUSTOMER,
  UPDATE_CUSTOMER,
  CUSTOMER_ADD_SUB_ACCOUNT,
  CUSTOMER_REMOVE_SUB_ACCOUNT,
} from "./graphql";
import Page from "components/Page";
import { useModals } from "ModalProvider";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { BsTrash } from "react-icons/bs";
import WisPriceListSelector from "components/WisPriceListSelector";

function Detail() {
  const { id } = useParams();
  const [editingAccount, setEditingAccount] = useState(false);
  const { loading, error, data } = useQuery(FETCH_CUSTOMER, {
    variables: { id },
  });
  const [customerAddSubAccount, customerAddSubAccountRes] = useMutation(
    CUSTOMER_ADD_SUB_ACCOUNT,
  );
  const [updateCustomer, updateCustomerRes] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      Alert("success", "Customer Updated.");
    },
  });

  const userModal = useModals();

  function tryAddSubAccount() {
    userModal.present({
      title: "Select a User",
      children: (
        <UserSelector
          excludeUsers={customer.subAccounts}
          excludeEmails={[customer.email]}
          onSelectUser={(user) => {
            customerAddSubAccount({
              variables: { customerId: customer.id, userId: user.id },
            });
            userModal.hide();
          }}
        />
      ),
    });
  }

  function changeMainAccount() {
    userModal.present({
      title: "Select a User",
      children: (
        <UserSelector
          excludeUsers={[customer.user]}
          excludeEmails={customer.subAccounts.map((a) => a.email)}
          onSelectUser={(user) => {
            console.log("user", user);
            userModal.hide();
          }}
        />
      ),
    });
  }

  if (loading) return <Spinner text="Loading Customer.." />;
  if (error) return <Errors error={error} />;
  const customer = data.customer;

  return (
    <Page
      backTo="../"
      title={customer.name}
      rightButtons={
        <div>
          <a
            href={`${process.env.REACT_APP_SERVER_ADMIN_URL}customer/customer/${customer.id}`}
            target="_blank"
            rel="noreferrer"
          >
            admin
          </a>
        </div>
      }
    >
      <div className="p-6">
        <div className="grid grid-cols-12 gap-6">
          <div className=" col-span-8 space-y-6">
            <Card
              title={customer.user ? customer.user.email : "No User"}
              rightButtons={
                <CharlesButton
                  className="absolute py-4 px-6 right-0 top-0"
                  loading={customerAddSubAccountRes.loading}
                  onClick={(_) => setEditingAccount(!editingAccount)}
                >
                  {editingAccount ? "done" : "edit"}
                </CharlesButton>
              }
            >
              <label>Sub Accounts:</label>
              <div className="text-gray-500 text-xs">
                Sub Accounts could see the orders, shipments and price list in
                this company in biz.waboba.com.
              </div>
              {customer.subAccounts.length > 0 ? (
                customer.subAccounts.map((a, index) => (
                  <CustomerSubAccount
                    key={index}
                    customerId={customer.id}
                    subAccount={a}
                    showRemoveButton={editingAccount}
                  />
                ))
              ) : (
                <div className="text-gray-500 text-xs mt-2">
                  No sub accounts.
                </div>
              )}
              {editingAccount ? (
                <div className="flex space-x-4 mt-4">
                  <CharlesButton onClick={tryAddSubAccount}>
                    + Add Sub Account
                  </CharlesButton>

                  <CharlesButton onClick={changeMainAccount}>
                    Change Main Account
                  </CharlesButton>
                </div>
              ) : null}
            </Card>

            <CustomerNotes customer={customer} />
            <CustomerProducts customer={customer} />
          </div>

          <div className="col-span-4 space-y-6">
            <div className="card px-6 py-4">
              <h5>Pricelist</h5>

              <WisPriceListSelector
                className="mt-2 w-full"
                loading={updateCustomerRes.loading}
                value={customer.priceList ? customer.priceList.id : null}
                onChange={(priceList) => {
                  if (priceList)
                    updateCustomer({
                      variables: { id: customer.id, priceListId: priceList.id },
                      optimisticResponse: {
                        updateCustomer: {
                          customer: {
                            ...customer,
                            priceList: { id: priceList.id },
                          },
                        },
                      },
                    });
                }}
              />
            </div>
            <CustomerAddresses customer={customer} />
            <CustomerDocuments customer={customer} />
          </div>
        </div>
      </div>
    </Page>
  );
}

const CustomerAddresses = ({ customer }) => {
  return (
    <Card title="Addresses">
      {customer.addresses.length > 0 ? (
        <div className="space-y-6">
          {customer.addresses.map((address, index) => (
            <div key={index}>
              <label className="uppercase">{address.category} Address:</label>
              <div className="mt-1 whitespace-pre-line">{address.address}</div>
            </div>
          ))}
        </div>
      ) : (
        <label className="mt-2">No Addresses.</label>
      )}
    </Card>
  );
};

function CustomerSubAccount({ customerId, subAccount, showRemoveButton }) {
  const [customerRemoveSubAccount, { loading }] = useMutation(
    CUSTOMER_REMOVE_SUB_ACCOUNT,
    {
      variables: { customerId: customerId, accountId: subAccount.id },
    },
  );
  return (
    <div className="flex items-center space-x-2 border-b border-gray-100 dark:border-gray-700 py-2">
      {showRemoveButton ? (
        <CharlesButton
          danger
          loading={loading}
          onClick={(_) => {
            if (
              window.confirm(
                `Are you sure to remove this user account for this customer?`,
              )
            ) {
              customerRemoveSubAccount();
            }
          }}
        >
          <BsTrash />
        </CharlesButton>
      ) : null}
      <Customer email={subAccount.user.email} />
    </div>
  );
}

function CustomerNotes({ customer }) {
  const [notes, setNotes] = useState(customer.notes);
  const [editingNotes, setEditingNotes] = useState(false);
  const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: () => {
      setEditingNotes(false);
      Alert("success", "Notes updated.");
    },
  });
  return (
    <Card
      className="mt-4"
      title="Notes"
      subtitle="Notes is a reference about this customer, will show in shipment page."
      rightButtons={
        <div className="flex justify-end">
          {editingNotes ? (
            <div className="flex space-x-6">
              <CharlesButton
                loading={loading}
                title="save"
                onClick={(_) => {
                  updateCustomer({ variables: { id: customer.id, notes } });
                }}
              >
                Save
              </CharlesButton>
              <CharlesButton onClick={() => setEditingNotes(false)}>
                Cancel
              </CharlesButton>
            </div>
          ) : (
            <CharlesButton onClick={() => setEditingNotes(true)}>
              Edit
            </CharlesButton>
          )}
        </div>
      }
    >
      {editingNotes ? (
        <Text
          autoFocus
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
        />
      ) : (
        <div className="whitespace-pre-wrap">
          {customer.notes ? (
            customer.notes
          ) : (
            <div className="text-gray-500">No notes.</div>
          )}
        </div>
      )}
    </Card>
  );
}

export default Detail;
