import ProductionCategory from "./ProductionCategory";
import ProductionSelector from "components/ProductionSelector";
import Production from "components/Production";
import { useContext } from "react";
import { ProductContext } from "./ProductForm";
import { CharlesButtonWithArrow } from "components/charles/base";
import { Switcher } from "components/Form";

const ProductionView = () => {
  const {
    showProduction,
    setShowProduction,
    simpleFields,
    onChangeSimpleFields,
    productionCategoryId,
    setProductionCategoryId,
    productionId,
    setProductionId,
    id,
  } = useContext(ProductContext);

  return (
    <section>
      <CharlesButtonWithArrow
        className="text-xl"
        show={showProduction}
        onClick={() => setShowProduction((prev) => !prev)}
      >
        Production
      </CharlesButtonWithArrow>

      {showProduction ? (
        <div className="mt-4">
          <div className="flex items-center space-x-4">
            <label>Consider BOM in Production: </label>
            <Switcher
              isOn={simpleFields.considerBomInProduction}
              onChange={() =>
                onChangeSimpleFields(
                  "considerBomInProduction",
                  !simpleFields.considerBomInProduction,
                )
              }
            />
          </div>

          <div className="flex mt-6">
            <ProductionCategory
              value={productionCategoryId}
              onChange={setProductionCategoryId}
            />
          </div>

          <div className="flex mt-6">
            <ProductionSelector
              productId={id}
              productionId={productionId}
              onSelect={setProductionId}
            />
          </div>
          <div className="relative mt-4 bg-white dark:bg-gray-900 p-12 rounded-2xl flex items-center justify-center">
            {productionId ? (
              <Production id={productionId} />
            ) : (
              <div className="text-gray-500">
                This Product does not have Production data.
              </div>
            )}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default ProductionView;
