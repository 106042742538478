const CustomerView = ({ customer }) => {
  return (
    <div className="card">
      <h4>{customer.name}</h4>

      <div className="mt-2 space-y-1 text-sm">
        <div>{customer.email}</div>
        <div>{customer.phone}</div>
      </div>
    </div>
  );
};
export default CustomerView;
