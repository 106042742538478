import { gql } from "@apollo/client";

export const PRODUCT_BASE_INFO_FRAGMENT = gql`
  fragment productBaseInfo on ProductType {
    id
    name
    number
    shape
    hsCode
    ballDiameter
    cubeX
    cubeY
    cubeZ
    cylindricalDiameter
    cylindricalHeight
    flatX
    flatY
    multiSizes
    itemWeight
    grossWeight
    moq
    packing
    innerBoxX
    innerBoxY
    innerBoxZ
    barCode
    quantityPerDisplayBox
    displayBoxX
    displayBoxY
    displayBoxZ
    innerCartonX
    innerCartonY
    innerCartonZ
    outerCartonX
    outerCartonY
    outerCartonZ
    ctnNetWeight
    ctnGrossWeight
    outerCartonCbm
    quantityPerCarton
  }
`;

export const FETCH_PRODUCTS_FOR_PRODUCTS_PAGE = gql`
  query FETCH_PRODUCTS_FOR_PRODUCTS_PAGE(
    $productAreas: [ID!]
    $productLines: [ID!]
  ) {
    products(
      productLines: $productLines
      productAreas: $productAreas
      isActive: true
      prefetchForProductsPage: true
      prefetchImages: true
    ) {
      results {
        id
        name
        images: computedImages(size: "300x300") {
          id
          url
        }
        productLine {
          id
          name
          age
          hsCode
          hsCodeForEu
          hsCodeForUs
          materials {
            id
            material {
              id
              name
            }
          }
          children {
            id
            name
            age
            materials {
              id
              material {
                id
                name
              }
            }
          }
        }
        materials {
          id
          material {
            id
            name
          }
        }
        children {
          id
          product {
            id
            name
            materials {
              id
              material {
                id
                name
              }
            }
          }
        }
        number
        shape
        hsCode
        ballDiameter
        cubeX
        cubeY
        cubeZ
        cylindricalDiameter
        cylindricalHeight
        flatX
        flatY
        multiSizes
        itemWeight
        grossWeight
        moq
        packing
        innerBoxX
        innerBoxY
        innerBoxZ
        barCode
        innerBoxBarcodeEan
        innerBoxBarcodeUpc
        innerCartonBarcodeEan
        innerCartonBarcodeUpc
        outerCartonBarcodeEan
        outerCartonBarcodeUpc
        quantityPerDisplayBox
        displayBoxX
        displayBoxY
        displayBoxZ
        innerCartonX
        innerCartonY
        innerCartonZ
        outerCartonX
        outerCartonY
        outerCartonZ
        ctnNetWeight
        ctnGrossWeight
        outerCartonCbm
        quantityPerCarton
        pantone: finalPantone
        warnings: finalWarnings {
          id
          name
        }
        labTestStandards: finalLabTestStandards {
          id
          name
        }
      }
    }
  }
`;
