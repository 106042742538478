import Errors from "components/Errors";
import { useAmazonPurchaseOrders } from "hooks/useAmazon";
import RegionOrderRows from "./RegionOrderRows";
import { InlineSpinner } from "components/Spinner";

const RegionRows = ({ region, orderState, selectOrder }) => {
  const { loading, error, purchaseOrders } = useAmazonPurchaseOrders(
    region,
    orderState,
  );

  if (loading)
    return (
      <tr>
        <td colSpan={9}>
          <div className="p-8 relative">
            <InlineSpinner text={`loading ${region} orders..`} />
          </div>
        </td>
      </tr>
    );

  if (error)
    return (
      <tr>
        <td colSpan={9}>
          <Errors error={error} />
        </td>
      </tr>
    );

  return (
    <RegionOrderRows
      region={region}
      purchaseOrders={purchaseOrders}
      orderState={orderState}
      selectOrder={selectOrder}
    />
  );
};

export default RegionRows;
