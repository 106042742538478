const BomsView = ({ boms }) => {
  if (Object.keys(boms).length === 0) return null;

  return (
    <div>
      <h5 className="text-red-600">⚠️ BOM Conflict Detected</h5>

      <div className="text-xs space-y-6">
        {Object.entries(boms).map(([key, { name, rows }]) => (
          <div key={key}>
            <div className="card px-4 mt-4">
              <h5 className="mx-2">{name}</h5>
              <table className="mt-2">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-right">WIS QTY</th>
                    <th className="text-right">Odoo QTY</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
                  {rows.map((row, index) => (
                    <tr
                      key={index}
                      className={
                        row.wis_qty === row.odoo_qty ? "" : "text-red-600"
                      }
                    >
                      <td>{row.name}</td>
                      <td className="text-right">{row.wis_qty}</td>
                      <td className="text-right">{row.odoo_qty}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BomsView;
