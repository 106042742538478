import { gql } from "@apollo/client";

export const FETCH_ASSESSMENTS = gql`
  query FETCH_ASSESSMENTS {
    assessments {
      edges {
        node {
          id
          category {
            id
            name
          }
          supplier {
            id
            name
            user {
              email
            }
          }
          portalSupplier {
            id
            name
            user {
              email
            }
          }
          createdAt
          createdBy {
            id
            email
          }
          dueDate
          status
          baseInfo
          totalScore {
            score
            scoreText
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;

export const FETCH_ASSESSMENT = gql`
  query FETCH_ASSESSMENT($id: ID!) {
    assessment(id: $id) {
      id
      dueDate
      supplier {
        id
        name
      }
      baseInfo
      lowestWagesPerMonth
      lowestWagesPerHour

      totalScore {
        score
        scoreText
      }

      category {
        id
        name
        indexNumber
        children {
          edges {
            node {
              id
              name
              indexNumber
              requiredSupplier
              children {
                edges {
                  node {
                    id
                    name
                    indexNumber
                  }
                }
              }
            }
          }
        }
      }

      questions {
        edges {
          node {
            id
            prevCategoryAssessmentQuestion {
              id
            }
            question {
              id
              indexNumber
              category {
                id
                requiredSupplier
              }
              applicableRequired
              applicableDescription
              confirmRequired
              checkDocsRequired
              checkEnvironmentRequired
              checkWithWorkersRequired
              checkWithOwnerRequired
              standardDescription: standardRuleDocstring
              question
              answerType
              numberListCount
              choices {
                id
                choice
                content
              }
            }
            applicable
            answerText
            answerNumbers
            answerChoices
            uploadedFiles
            score
            requiredImprovement
            improvementNote
            improvementCompleted
            improvements {
              edges {
                node {
                  id
                  content
                  files
                }
              }
            }
            followUpNextTime
            internalNote
          }
        }
      }
      status
    }
  }
`;

export const CREATE_ASSESSMENT = gql`
  mutation CREATE_ASSESSMENT($supplierId: ID!, $portalSupplierId: ID!) {
    createAssessment(
      supplierId: $supplierId
      portalSupplierId: $portalSupplierId
    ) {
      assessment {
        id
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_STATUS = gql`
  mutation UPDATE_ASSESSMENT_STATUS($id: ID!, $status: String!) {
    updateAssessmentStatus(id: $id, status: $status) {
      assessment {
        id
        status
      }
    }
  }
`;

export const SAVE_ASSESSMENT = gql`
  mutation SAVE_ASSESSMENT(
    $id: ID!
    $dueDate: Date
    $extraInfo: AssessmentExtraInfoInput
  ) {
    saveAssessment(id: $id, dueDate: $dueDate, extraInfo: $extraInfo) {
      assessment {
        id
        dueDate
        lowestWagesPerHour
        lowestWagesPerMonth
        questions {
          edges {
            node {
              id
              score
            }
          }
        }
        totalScore {
          score
          scoreText
        }
      }
    }
  }
`;

export const SAVE_ASSESSMENT_QUESTION = gql`
  mutation SAVE_ASSESSMENT_QUESTION(
    $id: ID!
    $answerText: String
    $answerNumbers: [Float]
    $answerChoices: [String]
    $uploadedFiles: [String]
    $applicable: Boolean
  ) {
    saveAssessmentQuestion(
      id: $id
      answerText: $answerText
      answerNumbers: $answerNumbers
      answerChoices: $answerChoices
      uploadedFiles: $uploadedFiles
      applicable: $applicable
    ) {
      assessmentQuestion {
        id
        applicable
        answerText
        answerNumbers
        answerChoices
        uploadedFiles
        score
        requiredImprovement
        assessment {
          id
          totalScore {
            score
            scoreText
          }
        }
      }
    }
  }
`;

export const UPDATE_ASSESSMENT_QUESTION = gql`
  mutation UPDATE_ASSESSMENT_QUESTION(
    $id: ID!
    $requiredImprovement: Boolean
    $improvementCompleted: Boolean
    $improvementNote: String
    $followUpNextTime: Boolean
    $internalNote: String
  ) {
    updateAssessmentQuestion(
      id: $id
      requiredImprovement: $requiredImprovement
      improvementNote: $improvementNote
      improvementCompleted: $improvementCompleted
      followUpNextTime: $followUpNextTime
      internalNote: $internalNote
    ) {
      assessmentQuestion {
        id
        requiredImprovement
        improvementNote
        improvementCompleted
        followUpNextTime
        internalNote
      }
    }
  }
`;

export const CONFIRM_ASSESSMENT_QUESTION = gql`
  mutation ConfirmAssessmentQuestion($id: ID!, $score: String!) {
    confirmAssessmentQuestion(id: $id, score: $score) {
      assessmentQuestion {
        id
        score
        requiredImprovement
        assessment {
          id
          totalScore {
            score
            scoreText
          }
        }
      }
    }
  }
`;
