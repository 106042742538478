import { useQuery } from "@apollo/client";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Page from "components/Page";
import SearchBar from "components/SearchBar.js";
import Spinner from "components/Spinner";
import gql from "graphql-tag";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const FETCH_CUSTOMERS = gql`
  query FETCH_CUSTOMERS {
    customers {
      id
      name
      user {
        id
        email
      }
      areas {
        id
        name
      }
      shipments {
        id
      }
      priceList {
        id
        name
        currency
      }
    }
  }
`;

function CustomerList() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [sortByShipment, setSortByShipment] = useState(1);

  const { loading, error, data } = useQuery(FETCH_CUSTOMERS);
  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let customers = [...data.customers];
  customers.sort((a, b) => {
    if (sortByShipment === 1) {
      return b.shipments.length - a.shipments.length;
    } else if (sortByShipment === -1) {
      return a.shipments.length - b.shipments.length;
    } else {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  });
  customers = customers.filter(
    (i) =>
      i.name.toLowerCase().trim().indexOf(searchText.toLowerCase().trim()) >
        -1 ||
      (i.user &&
        i.user.email
          .toLowerCase()
          .trim()
          .indexOf(searchText.toLowerCase().trim()) > -1),
  );

  return (
    <Page
      title="Customers"
      rightButtons={<Link to="/customers/add">+ New Customer</Link>}
    >
      <div className="flex-1 flex flex-col h-full p-6">
        <div className="">
          <SearchBar
            className="p-2 w-full"
            placeholder="Search customer by name or email"
            autoFocus
            type="text"
            value={searchText}
            onChange={(text) => setSearchText(text)}
          />
        </div>
        <div className="card p-2 mt-4">
          <table className="bg-white dark:bg-gray-800">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Product Groups</th>
                <th>
                  <CharlesButton
                    onClick={() => {
                      setSortByShipment((prev) => {
                        if (prev === 1) return -1;
                        if (prev === -1) return 0;
                        if (prev === 0) return 1;
                      });
                    }}
                  >
                    Shipments
                  </CharlesButton>
                </th>
                <th>Price List</th>
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <tr
                    className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer border-y border-gray-100 dark:border-gray-700"
                    onClick={(_) => navigate(`/customers/${customer.id}`)}
                    key={customer.id}
                  >
                    <td>
                      <div>
                        <div className="break-all">{customer.name}</div>
                        <div className="break-all text-gray-500">
                          {customer.user
                            ? `${customer.user.email}`
                            : "This customer does not have setup an account in our system yet."}
                        </div>
                      </div>
                    </td>
                    <td>{customer.areas.map((i) => i.name).join(", ")}</td>
                    <td className="text-right">
                      {customer.shipments.length || "-"}
                    </td>
                    <td className="whitespace-nowrap">
                      {customer.priceList
                        ? `${customer.priceList.name}(${customer.priceList.currency})`
                        : "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="py-8 px-4 lg:px-6 font-bold text-gray-500"
                    colSpan={3}
                  >
                    No Customers Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
}

export default CustomerList;
