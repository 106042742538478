const AssessmentQuestionTags = ({ assessmentQuesetion }) => {
  return (
    <div className="flex gap-4 py-1 font-bold">
      {assessmentQuesetion.prevCategoryAssessmentQuestion && (
        <div className="text-orange-600">
          <span className="text-xl">⚠️</span> FOLLOW UP
        </div>
      )}

      {assessmentQuesetion.question.confirmRequired && (
        <div className="text-fuchsia-500">
          <span className="text-xl">📋</span> ON-SITE
        </div>
      )}

      {assessmentQuesetion.question.checkDocsRequired && (
        <div className="text-blue-600">
          <span className="text-xl">📄</span> DOCS
        </div>
      )}
      {assessmentQuesetion.question.checkEnvironmentRequired && (
        <div className="text-green-600">
          <span className="text-xl">🏭</span> ENV
        </div>
      )}
      {assessmentQuesetion.question.checkWithWorkersRequired && (
        <div className="text-yellow-600">
          <span className="text-xl">👷</span> WORKERS
        </div>
      )}
      {assessmentQuesetion.question.checkWithOwnerRequired && (
        <div className="text-red-600">
          <span className="text-xl">👨🏻‍💼</span> OWNER
        </div>
      )}
    </div>
  );
};

export default AssessmentQuestionTags;
