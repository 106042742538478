import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import PowerbiSales from "./PowerbiSales";
import Page from "components/Page";
import { NavigationLink } from "components/base";
import ABPage from "./ABPage";

const AnalyticsSales = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="powerbi" element={<PowerbiSales />} />
        <Route path="ab" element={<ABPage />} />
        <Route path="*" element={<Navigate to="powerbi" />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  return (
    <Page
      className="h-screen"
      leftButtons={
        <div className="space-x-6">
          <NavigationLink to="powerbi" title="Power BI" />
          <NavigationLink to="ab" title="Sales Report" />
        </div>
      }
    >
      <Outlet />
    </Page>
  );
};

export default AnalyticsSales;
