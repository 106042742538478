import { AppContext } from "App";
import { Text } from "components/Form";
import { useContext, useEffect, useState } from "react";
import CharlesButton from "components/charles/base";
import InputSelect from "components/InputSelect";
import { ImCross } from "react-icons/im";
import { FaAsterisk } from "react-icons/fa";

const HighlighLoginedUser = ({ email }) => {
  const { user } = useContext(AppContext);
  const loginedUserEmail = user.email;
  return loginedUserEmail === email ? (
    <div>
      <span className="bg-yellow-200 bg-opacity-50 rounded px-1">{email}</span>
    </div>
  ) : (
    <div>{email}</div>
  );
};

const MessageFromDisplay = ({ message }) => {
  return (
    <div className="rounded-lg bg-gray-100 p-4 space-y-2 group relative dark:bg-gray-800">
      <div>
        <div className="flex items-center">
          <label className="w-24">Sender:</label>
          {message.senders.map((sender, index) => (
            <div key={index} className="mr-2">
              <HighlighLoginedUser key={index} email={sender.email} />
            </div>
          ))}
        </div>

        <div className="flex items-center">
          <label className="w-24">Receiver:</label>
          {message.receivers.map((receiver, index) => (
            <div key={index} className="mr-2">
              <HighlighLoginedUser key={index} email={receiver.email} />
            </div>
          ))}
        </div>
      </div>

      <Text
        rows={1}
        value={message.content}
        className="border-none pointer-events-none cursor-not-allowed dark:bg-gray-700 dark:bg-opacity-20"
      />
    </div>
  );
};

export const MessageFormCreate = ({ internalUsers, setMessages }) => {
  const [text, setText] = useState("");
  const [senders, setSenders] = useState([]);
  const [receivers, setReceivers] = useState([]);

  const [InputSender, setInputSender] = useState("");
  const [InputReceiver, setInputReceiver] = useState("");

  useEffect(() => {
    if (senders.length > 0) {
      setMessages((prevMessages) => {
        // Check if there is already a message with id null, it means a new message!
        const nullIdMessageIndex = prevMessages.findIndex(
          (message) => message.id === null,
        );

        // If there is a message with id null, update it
        if (nullIdMessageIndex !== -1) {
          return prevMessages.map((message, index) => {
            if (index === nullIdMessageIndex) {
              return {
                id: null,
                senders: senders,
                receivers: receivers,
                content: text,
              };
            }
            return message;
          });
        }

        // If there is no message with id null, add a new one
        return [
          ...prevMessages,
          {
            id: null,
            senders: senders,
            receivers: receivers,
            content: text,
          },
        ];
      });
    }
  }, [senders, receivers, text]);

  return (
    <div className=" rounded-lg bg-gray-100 p-4 space-y-2 dark:bg-gray-800">
      <div className="flex items-center">
        <label className="w-24">Sender:</label>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <InputSelect
              options={internalUsers.map((user) => ({
                name: user.email,
                sender: user,
              }))}
              onSelect={(selectedOption) => {
                if (
                  !senders.some(
                    (sender) => sender.id === selectedOption.sender.id,
                  )
                ) {
                  setSenders([...senders, selectedOption.sender]);
                }
                setInputSender("");
              }}
              onChange={setInputSender}
              value={InputSender}
              placeholder="Search a user"
              className="w-40"
              inputClasses={"dark:bg-gray-700 dark:bg-opacity-20"}
            />
            <FaAsterisk className="text-red-500 text-xxs" />
          </div>

          {senders.length > 0 && (
            <div className="flex items-center space-x-4 flex-wrap">
              {senders.map((sender, index) => (
                <div key={index} className="flex items-center space-x-2 group">
                  <div>{sender.email}</div>
                  <CharlesButton
                    icon={<ImCross className="text-xxs" />}
                    danger={true}
                    onClick={() =>
                      setSenders(senders.filter((_, i) => i !== index))
                    }
                    className="invisible group-hover:visible"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center">
        <label className="w-24">Receiver:</label>

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <InputSelect
              options={internalUsers.map((user) => ({
                name: user.email,
                receiver: user,
              }))}
              onSelect={(selectedOption) => {
                if (
                  !receivers.some(
                    (receiver) => receiver.id === selectedOption.receiver.id,
                  )
                ) {
                  setReceivers([...receivers, selectedOption.receiver]);
                }
                setInputReceiver("");
              }}
              onChange={setInputReceiver}
              value={InputReceiver}
              placeholder="Search a user"
              className="w-40"
              inputClasses={"dark:bg-gray-700 dark:bg-opacity-20"}
            />
            {/* <FaAsterisk className="text-red-500 text-xxs" /> */}
          </div>

          {receivers.length > 0 && (
            <div className="flex items-center space-x-4 flex-wrap">
              {receivers.map((receiver, index) => (
                <div key={index} className="flex items-center space-x-2 group">
                  <div>{receiver.email}</div>
                  <CharlesButton
                    icon={<ImCross className="text-xxs" />}
                    danger={true}
                    onClick={() =>
                      setReceivers(receivers.filter((_, i) => i !== index))
                    }
                    className="invisible group-hover:visible"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Text
        rows={1}
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="dark:border-none dark:bg-gray-700 dark:bg-opacity-20"
      />
    </div>
  );
};

const MessageFormUpdate = ({ internalUsers, setMessages, message }) => {
  const { user } = useContext(AppContext);

  const [text, setText] = useState(message ? message.content : "");
  const [senders, setSenders] = useState(
    message ? message.senders : [{ id: user.id, email: user.email }],
  );
  const [receivers, setReceivers] = useState(message ? message.receivers : []);

  const [InputSender, setInputSender] = useState("");
  const [InputReceiver, setInputReceiver] = useState("");

  useEffect(() => {
    if (senders.length > 0 && receivers.length > 0) {
      setMessages((prevMessages) =>
        prevMessages.map((currentMsg) => {
          if (currentMsg.id === message.id) {
            return {
              id: message.id,
              senders: senders,
              receivers: receivers,
              content: text,
            };
          }
          return currentMsg;
        }),
      );
    }
  }, [senders, receivers, text]);

  return (
    <div className="rounded-lg bg-gray-100 p-4 space-y-2 group relative dark:bg-gray-800">
      <div className="flex items-center">
        <label className="w-24">Sender:</label>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <InputSelect
              options={internalUsers.map((user) => ({
                name: user.email,
                sender: user,
              }))}
              onSelect={(selectedOption) => {
                if (
                  !senders.some(
                    (sender) => sender.id === selectedOption.sender.id,
                  )
                ) {
                  setSenders([...senders, selectedOption.sender]);
                }
                setInputSender("");
              }}
              onChange={setInputSender}
              value={InputSender}
              placeholder="Search a user"
              className="w-40"
              inputClasses={"dark:bg-gray-700 dark:bg-opacity-20"}
            />
            <FaAsterisk className="text-red-500 text-xxs" />
          </div>

          {senders.length > 0 && (
            <div className="flex items-center space-x-4 flex-wrap">
              {senders.map((sender, index) => (
                <div key={index} className="flex items-center space-x-2 group">
                  <div>{sender.email}</div>
                  <CharlesButton
                    icon={<ImCross className="text-xxs" />}
                    danger={true}
                    onClick={() =>
                      setSenders(senders.filter((_, i) => i !== index))
                    }
                    className="invisible group-hover:visible"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center">
        <label className="w-24">Receiver:</label>

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <InputSelect
              options={internalUsers.map((user) => ({
                name: user.email,
                receiver: user,
              }))}
              onSelect={(selectedOption) => {
                if (
                  !receivers.some(
                    (receiver) => receiver.id === selectedOption.receiver.id,
                  )
                ) {
                  setReceivers([...receivers, selectedOption.receiver]);
                }
                setInputReceiver("");
              }}
              onChange={setInputReceiver}
              value={InputReceiver}
              placeholder="Search a user"
              className="w-40"
              inputClasses={"dark:bg-gray-700 dark:bg-opacity-20"}
            />
            <FaAsterisk className="text-red-500 text-xxs" />
          </div>

          {receivers.length > 0 && (
            <div className="flex items-center space-x-4 flex-wrap">
              {receivers.map((receiver, index) => (
                <div key={index} className="flex items-center space-x-2 group">
                  <div>{receiver.email}</div>
                  <CharlesButton
                    icon={<ImCross className="text-xxs" />}
                    danger={true}
                    onClick={() =>
                      setReceivers(receivers.filter((_, i) => i !== index))
                    }
                    className="invisible group-hover:visible"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Text
        rows={1}
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="dark:bg-gray-700 dark:bg-opacity-20 dark:border-none"
      />

      <div className="absolute top-4 right-4">
        <CharlesButton
          onClick={() =>
            setMessages((prevMessages) =>
              prevMessages.filter((msg) => msg.id !== message.id),
            )
          }
          icon={<ImCross className="text-xs" />}
          danger={true}
          className="invisible group-hover:visible "
        />
      </div>
    </div>
  );
};

const MessageList = ({ isEditing, messages, internalUsers, setMessages }) => {
  return (
    <div className="space-y-2">
      {isEditing
        ? messages.map(
            (msg) =>
              msg.id !== null && (
                <div key={msg.id}>
                  <MessageFormUpdate
                    internalUsers={internalUsers}
                    setMessages={setMessages}
                    message={msg}
                  />
                </div>
              ),
          )
        : messages.map(
            (msg) =>
              msg.id !== null && (
                <div key={msg.id}>
                  <MessageFromDisplay message={msg} />
                </div>
              ),
          )}
    </div>
  );
};

export default MessageList;
