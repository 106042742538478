import { useQuery } from "@apollo/client";
import { NavigationBar } from "components/base";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import { useModals } from "ModalProvider";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import AddProductToStockView from "./AddProductToStockView";
import ChinaStocksProductTableView from "./ChinaStocksProductTableView";
import { FETCH_CHINA_STOCK_PRODUCTS } from "./graphql";

const ChinaStockProducts = () => {
  const { stockId } = useParams();
  const { loading, error, data } = useQuery(FETCH_CHINA_STOCK_PRODUCTS, {
    variables: { stockId },
  });
  const [searchParams] = useSearchParams();
  const ref = useRef();

  const modal = useModals();
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  useEffect(() => {
    if (data) {
      setSelectedProductIds(data.odooStock.products.map((p) => p.id));
    }
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  function tryAddProduct(stock) {
    modal.present({
      title: "Add Product",
      center: true,
      children: <AddProductToStockView hide={modal.hide} stock={stock} />,
    });
  }

  const adminLink = `${process.env.REACT_APP_SERVER_ADMIN_URL}stock/stock/${stockId}/change/`;

  const params = Object.fromEntries(searchParams.entries());
  const products = data.odooStock.products.filter((i) => {
    if (params.productOdooId) {
      return i.odooId === parseInt(params.productOdooId);
    }
    return true;
  });

  const q = params.q;

  return (
    <div className="flex flex-col flex-1 overflow-auto">
      <NavigationBar
        backTo={`./..${q ? `?q=${q}` : ""}`}
        title={data.odooStock.name}
        rightButtons={
          <div className="flex items-center space-x-4">
            <CharlesButton onClick={() => tryAddProduct(data.odooStock)}>
              + Add Product
            </CharlesButton>

            <a href={adminLink} target="_blank">
              Admin
            </a>

            <CharlesButton
              onClick={() => {
                ref.current.download();
              }}
            >
              Download
            </CharlesButton>
          </div>
        }
      />
      {data.odooStock.products.length === 0 ? (
        <div className="p-6">No products found in this stock.</div>
      ) : (
        <ChinaStocksProductTableView ref={ref} products={products} />
      )}
    </div>
  );
};

export default ChinaStockProducts;
