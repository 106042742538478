import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import { InlineSpinner } from "components/Spinner";
import { useImportedAmazonPurchaseOrders } from "hooks/useAmazon";
import moment from "moment";
import { useContext } from "react";
import { AmzContext } from "../Orders";

const RegionOrderRows = ({
  region,
  purchaseOrders,
  orderState,
  selectOrder,
}) => {
  const numbers = purchaseOrders.map((i) => i.purchaseOrderNumber);
  const { loading, error, data } = useImportedAmazonPurchaseOrders(numbers);
  const { ackOrders } = useContext(AmzContext);

  if (loading)
    return (
      <tr>
        <td colSpan={9}>
          <div className="p-8 relative">
            <InlineSpinner />
          </div>
        </td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td colSpan={9}>
          <Errors error={error} />
        </td>
      </tr>
    );

  const computedOrders = purchaseOrders
    .map((i) => {
      const dates = i.orderDetails.deliveryWindow.split("--");
      const windowStart = moment(dates[0]).format("YYYY-MM-DD");
      const windowEnd = moment(dates[1]).format("YYYY-MM-DD");
      const purchaseOrderDate = new Date(i.orderDetails.purchaseOrderDate);
      const odoo = data.amzVcPurchaseOrders.find(
        (j) => j.number === i.purchaseOrderNumber,
      );
      const wisId = odoo ? odoo.id : null;
      const odooSalesOrderId = odoo ? odoo.odooSalesOrderId : null;
      const odooSalesOrderName = odoo ? odoo.odooSalesOrderName : null;
      const expectedDate = odoo ? odoo.expectedDate : null;
      const isNew = odoo
        ? moment.duration(moment().diff(moment(odoo.createdAt))).asDays() < 1
        : false;
      return {
        ...i,
        purchaseOrderDate,
        windowStart,
        windowEnd,
        wisId,
        odooSalesOrderId,
        odooSalesOrderName,
        expectedDate,
        isNew,
      };
    })
    .sort((a, b) => (a.purchaseOrderDate < b.purchaseOrderDate ? 1 : -1));

  console.log("computedOrders", computedOrders);

  return (
    <>
      <tr className="border-t dark:border-gray-700 bg-gray-100 dark:bg-gray-900 bg-opacity-50">
        <td className="px-6" colSpan={8}>
          <h4>{region}</h4>
        </td>
        <td>
          {orderState === "New" && computedOrders.length > 0 && (
            <div className="flex justify-center">
              <CharlesButton
                onClick={() => ackOrders({ region, orders: computedOrders })}
              >
                Batch Ack Orders
              </CharlesButton>
            </div>
          )}
        </td>
      </tr>

      {computedOrders.length === 0 ? (
        <tr>
          <td colSpan={9} className="border-t dark:border-gray-700 px-6">
            <label htmlFor="">No {orderState} orders</label>
          </td>
        </tr>
      ) : (
        computedOrders.map((order, index) => (
          <tr
            key={index}
            className="px-4 md:px-6 border-t dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 dark:hover:bg-opacity-50 cursor-pointer whitespace-nowrap"
            onClick={() => selectOrder(order)}
          >
            <td className="px-6">{region}</td>
            <td>
              <div className="flex items-center space-x-3">
                <div>{order.purchaseOrderNumber}</div>
                {order.isNew ? (
                  <div className="rounded-full text-white bg-green-500 dark:bg-green-600 text-xs px-2 py-1">
                    new
                  </div>
                ) : null}
              </div>
            </td>
            <td className="text-center">
              {moment(order.orderDetails.purchaseOrderDate).format(
                "YYYY-MM-DD",
              )}
            </td>
            <td className="text-center">
              {moment(order.windowStart).format("YYYY-MM-DD")}
            </td>
            <td className="text-center">
              {moment(order.windowEnd).format("YYYY-MM-DD")}
            </td>
            <td className="text-center">
              {order.orderDetails.billToParty.partyId}
            </td>
            <td className="text-center">
              {order.orderDetails.shipToParty.partyId}
            </td>
            <td className="text-center">{order.purchaseOrderState}</td>
            <td className="text-center px-6">
              {order.odooSalesOrderId ? order.odooSalesOrderName : " - "}
            </td>
          </tr>
        ))
      )}
    </>
  );
};

export default RegionOrderRows;
