import { gql } from "@apollo/client";

export const FETCH_BIZ_PRODUCT_COLLECTION = gql`
  query FETCH_BIZ_PRODUCT_COLLECTION($name: String) {
    anyCollection(name: $name) {
      id
      name
      descendants {
        id
        name
        description
        parent {
          id
        }
      }
      bizProductLines {
        id
        anyCollections {
          id
        }
        name
        activeForWis
        activeForBiz
        productList {
          version
        }

        products {
          id
          number
          name
          isActive
          isActiveForBiz
          productList {
            version
          }
          images: computedImages(size: "300x300") {
            id
            url
          }

          areas {
            id
            name
          }
        }
      }
    }
  }
`;

export const SAVE_BIZ_PRODUCT_LINE = gql`
  mutation SAVE_BIZ_PRODUCT_LINE(
    $id: ID
    $simpleFields: ProductLineSimpleFields
  ) {
    saveProductLine(id: $id, simpleFields: $simpleFields) {
      productLine {
        id
        activeForWis
        activeForBiz
      }
    }
  }
`;

export const SAVE_BIZ_PRODUCT = gql`
  mutation SAVE_BIZ_PRODUCT($id: ID!, $simpleFields: ProductSimpleFields) {
    saveProduct(id: $id, simpleFields: $simpleFields) {
      product {
        id
        isActive
        isActiveForBiz
      }
    }
  }
`;
