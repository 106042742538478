import { Text } from "components/Form";
import { useEffect, useRef, useState } from "react";
import { FaComment } from "react-icons/fa6";
import ProductForecastView from "./ProductForecastView";

const CellInput = ({
  productId,
  month,
  item,
  onChange,
  qtyType,
  placeholder,
  syncedData,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const inputRef = useRef(null);
  const [showPosition, setShowPosition] = useState("topRight");
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  // Check if the element is visible in the screen, if yes then set visible to true. This helps reduce the number of elements that are rendered on the screen to improve performance
  useEffect(() => {
    let observer;
    if (ref.current) {
      observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      });
      observer.observe(ref.current);
    }
    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  function setPosition() {
    // check inputRef element position if it is close to the right of the container then show the info on the left
    const inputRect = inputRef.current.getBoundingClientRect();
    let position = "topRight";
    if (window.innerWidth - inputRect.right < 500) {
      position = "topLeft";
      if (window.innerHeight - inputRect.bottom < 400) {
        position = "bottomLeft";
      }
    } else {
      position = "topRight";
      if (window.innerHeight - inputRect.bottom < 400) {
        position = "bottomRight";
      }
    }
    setShowPosition(position);
  }

  return (
    <div className="relative" ref={ref}>
      {visible && (
        <>
          <div className="flex items-center bg-sky-50 dark:bg-sky-900">
            {item?.remark && (
              <div className="absolute w-8 pl-2 flex justify-center items-center text-orange-600 z-0">
                <FaComment />
              </div>
            )}

            {syncedData && syncedData !== item.qty && (
              <div className="absolute z-0 px-2 text-red-500">{syncedData}</div>
            )}

            <input
              ref={inputRef}
              className={`appearance-none bg-sky-50 dark:bg-sky-900 py-0 h-8 px-3 text-right w-full border border-opacity-0 focus:border-opacity-100 focus:border-sky-500 focus:bg-sky-200 transition-all duration-200`}
              style={{ minWidth: "90px" }}
              value={item.qty}
              placeholder={qtyType === "begin_inv" ? placeholder : null}
              onChange={(e) => onChange({ qty: e.target.value })}
              onFocus={() => {
                setShowInfo(true);
                setPosition();
                inputRef.current.select();
              }}
            />
          </div>
          {showInfo && (
            <>
              <div
                className={`absolute w-96 overflow-auto px-4 py-4 rounded-2xl bg-blue-50 dark:bg-blue-900 border border-blue-300 dark:border-blue-500 z-20 mx-4
                ${
                  showPosition === "topRight"
                    ? "left-full top-0"
                    : showPosition === "topLeft"
                      ? "right-full top-0"
                      : showPosition === "bottomRight"
                        ? "left-full bottom-0"
                        : "right-full bottom-0"
                }
                `}
              >
                <div className="space-y-6">
                  {qtyType === "forecast" && (
                    <ProductForecastView
                      productId={productId}
                      year={2024}
                      month={month}
                      onSelectNumber={(qty) => {
                        onChange({ qty });
                        setShowInfo(false);
                      }}
                    />
                  )}

                  <div className="px-2 flex flex-col items-start">
                    <div>
                      <label htmlFor="">Note</label>
                    </div>
                    <div className="-mx-1 mt-2 w-full">
                      <Text
                        value={item.remark || ""}
                        onChange={(e) => onChange({ remark: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fixed inset-0 z-10"
                onWheel={() => {
                  setShowInfo(false);
                }}
                onClick={() => setShowInfo(false)}
              ></div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CellInput;
