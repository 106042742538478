import CharlesButton from "components/charles/base";
import useAssessmentOperations from "./useAssessmentOperations";

const InternalNoteView = ({ assessmentQuestion }) => {
  const { showInternalNoteForm } = useAssessmentOperations();

  if (!assessmentQuestion.internalNote) return null;

  return (
    <div className="mt-4 whitespace-pre-wrap bg-blue-50 rounded-2xl px-6 py-4">
      <div className="flex justify-between items-center">
        <label htmlFor="">Internal Note</label>
        <CharlesButton onClick={() => showInternalNoteForm(assessmentQuestion)}>
          Edit
        </CharlesButton>
      </div>
      c<div className="mt-2">{assessmentQuestion.internalNote}</div>
    </div>
  );
};

export default InternalNoteView;
