import { useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import { FETCH_RETAILER_AGENTS } from "../graphql";

const AgentList = () => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_AGENTS, {
    fetchPolicy: "network-only",
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title="Retailer Agents">
      <div className="card m-6 p-6">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex-1">
            <SearchBar placeholder="Search by email or username." />
          </div>
        </div>

        <div className="mt-4">
          <table>
            <thead>
              <tr>
                <th>Agent Email</th>
                <th className="text-right">Customers Count</th>
              </tr>
            </thead>
            <tbody>
              {data.retailerAgents.map((agent) => (
                <tr key={agent.id} className="border-y dark:border-gray-700">
                  <td>
                    <Link to={agent.id}>{agent.email}</Link>
                  </td>
                  <td className="text-right">{agent.agentCustomers.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default AgentList;
