const RoutesView = ({ routes }) => {
  if (!routes.length) return null;

  return (
    <div>
      <h5 className="text-red-600">⚠️ Production Route Errors Found!</h5>
      <div className="text-sm mt-1">
        Wrong Production Routes will cause incorrect stock usage. Please make
        sure they are correct before you confirm the time. WIS automatically
        checks the products in China Stock if they are in the correct production
        route.
      </div>

      <div className="mt-4 space-y-3 text-sm">
        {routes.map((i, index) => (
          <div key={index} className="card px-6 py-4">
            <h4>{i.product.number}</h4>
            <div>{i.product.name}</div>

            <div className="mt-4">
              <div className="flex items-center space-x-2">
                <label htmlFor="">Production Route: </label>
                <div>{i.production.name}</div>
              </div>
              <div>
                <div>
                  {i.production.categories.map((c, cIndex) => (
                    <div key={cIndex}> - {c}</div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-4">
              <h6>Missing Categories</h6>
              <div className="mt-1 space-y-1">
                {i.missing.map((j, jIndex) => (
                  <div key={jIndex}>
                    <div>
                      [{j.product.number}] {j.product.name} -{" "}
                      <span className=" text-red-600">
                        {j.production_category?.name ??
                          "No Production Category"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-4 text-sm">
        To fix these issues, you can either remove the item from China Stock, or
        update the bom, production routes, categories.
      </div>
    </div>
  );
};

export default RoutesView;
