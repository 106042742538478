import { computeMaxPackage } from "./inventory";

const InventoryView = ({ data }) => {
  const res = data.retailerProduct.bom.map((child) => ({
    ...child,
    maxPackages: computeMaxPackage(child),
  }));

  return (
    <div className="card px-6 py-4">
      <h5>Inventory</h5>

      <div className="-mx-2 mt-2 whitespace-nowrap">
        <table>
          <thead>
            <tr>
              <th className="w-1/2">Product</th>
              <th className="text-right">Latest QTY</th>
              <th className="text-right">QTY / CTN</th>
              <th className="text-right">CTN In Stock</th>
              <th className="text-right">Coming Inbounds</th>
            </tr>
          </thead>
          <tbody>
            {res.map((child, index) => (
              <tr key={index} className="border-y">
                <td>
                  #{child.wisProduct.id}: {child.wisProduct.number}
                </td>
                <td className="text-right">
                  {child.wisProduct.warehouseInventory}
                </td>
                <td className="text-right">{child.qty}</td>
                <td className="text-right">{child.maxPackages}</td>
                <td className="text-right">
                  <div>
                    {child.wisProduct.warehouseInboundLines.map((line) => (
                      <div key={line.id}>
                        <div>
                          {line.inbound.eta}({line.qty}) :{" "}
                          {Math.floor(line.qty / child.qty)}
                        </div>
                      </div>
                    ))}
                  </div>

                  {child.firstInboundLine &&
                    `${child.firstInboundLine.inbound.eta}(${child.firstInboundLine.qty}) : ${Math.floor(child.firstInboundLine.qty / child.qty)}`}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}></td>
              <td className="text-right"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default InventoryView;
