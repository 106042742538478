import { useEffect, useState } from "react";
import AckOrderView from "./AckOrderView";
import { useMutation, useQuery } from "@apollo/client";
import {
  FETCH_ACK_ORDERS_REQUIRED_DATA,
  FETCH_WAREHOUSE_PRODUCTS,
} from "./graphql";
import Spinner, { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import { Select } from "components/Form";
import CharlesButton from "components/charles/base";
import { AMAZON_SUBMIT_ACKNOWLEDGEMENT } from "../graphql";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { useRef } from "react";
import moment from "moment";

const AckOrdersView = ({ region, orders }) => {
  const barcodes = orders.reduce((acc, order) => {
    let res = [
      ...acc,
      ...order.orderDetails.items.map((i) => i.vendorProductIdentifier),
    ];
    return res.filter((item, pos) => res.indexOf(item) === pos);
  }, []);

  console.log("orders", orders);

  const { loading, error, data } = useQuery(FETCH_ACK_ORDERS_REQUIRED_DATA, {
    variables: { barcodes },
  });
  const [stockCategoryId, setStockCategoryId] = useState("0");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <div className="text-sm m-10 mt-4 pb-10">
      <div className="flex space-x-3 items-center">
        <label htmlFor="">Stock Category:</label>
        <Select
          value={stockCategoryId}
          onChange={(e) => setStockCategoryId(e.target.value)}
        >
          <option value="0" disabled>
            Choose a Stock Category
          </option>
          {data.stockCategories.map((i) => (
            <option key={i.id} value={i.id}>
              {i.name}
            </option>
          ))}
        </Select>
      </div>

      {stockCategoryId !== "0" ? (
        <AckOrdersViewWithStock
          region={region}
          orders={orders}
          stockCategoryId={stockCategoryId}
        />
      ) : (
        <div className="space-y-6 mt-6">
          First choose a stock category for these orders.
        </div>
      )}
    </div>
  );
};

const AckOrdersViewWithStock = ({ region, orders, stockCategoryId }) => {
  const { loading, error, data } = useQuery(FETCH_WAREHOUSE_PRODUCTS, {
    variables: { id: stockCategoryId },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div className="p-10">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  // History of wrong barcodes

  const stockCategory = data.amazonStockCategory;

  console.log("stockCategory", stockCategory);

  return (
    <AckOrdersViewWithSuggestedAction
      region={region}
      initialOrders={orders}
      stockCategory={stockCategory}
    />
  );
};

const AckOrdersViewWithSuggestedAction = ({
  region,
  initialOrders,
  stockCategory,
}) => {
  const wrongBarcodes = ["0840001930872", "0840001910003", "0840001930858"];

  function computeOrders(orders) {
    const stocks = stockCategory.stocks.reduce((acc, stock) => {
      acc[stock.odooId] = stock.latestQty;
      return acc;
    }, {});
    let res = [];
    orders.forEach((order) => {
      let orderDetails = order.orderDetails;
      let items = orderDetails.items;
      let newItems = items
        .map((i) => {
          let stock = stockCategory.stocks.find((s) => {
            if (s.asin) {
              if (s.asin === i.amazonProductIdentifier) return true;
              const combinedAsins = s.asin.split(",");
              return combinedAsins.includes(i.amazonProductIdentifier);
            }
            return false;
          });
          return { ...i, stock };
        })
        .reduce((acc, i) => {
          let actionType = "reject";
          let qty = 0;
          let rejectReason = "TemporarilyUnavailable";
          let qtyOnHand = 0;
          let stock = i.stock ? stocks[i.stock.odooId] : 0;
          if (!wrongBarcodes.includes(i.barcode)) {
            if (stock > 0) {
              qtyOnHand = stock;
              if (qtyOnHand >= i.orderedQuantity.amount) {
                let acceptableQty = Math.min(
                  i.orderedQuantity.amount,
                  qtyOnHand,
                );
                let sets = Math.floor(acceptableQty / i.stock.qtyPerMaster);
                if (sets > 0) {
                  qty = sets * i.stock.qtyPerMaster;
                  actionType = "accept";
                  stocks[i.stock.odooId] -= qty;
                }
              }
            }
          }
          return [
            ...acc,
            { ...i, qtyOnHand, action: { actionType, qty, rejectReason } },
          ];
        }, []);
      res.push({
        ...order,
        orderDetails: { ...orderDetails, items: newItems },
        status:
          order.purchaseOrderState !== "Acknowledged"
            ? "AWAITING"
            : "ACKNOWLEDGED",
        selected: true,
      });
    });
    return res;
  }

  const [orders, setOrders] = useState(computeOrders(initialOrders));
  const selectedOrders = orders.filter((order) => order.selected);

  const completedOrders = useRef([]);
  const [submitAcknowledgement, submitAcknowledgementRes] = useMutation(
    AMAZON_SUBMIT_ACKNOWLEDGEMENT,
    {
      onCompleted() {
        Alert("success", "Acknowledged.");
        hide();
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  useEffect(() => {
    setOrders(computeOrders(initialOrders));
  }, [initialOrders, stockCategory]);

  function onChangeOrder(orderIndex, orderValues) {
    setOrders((prev) => {
      let newOrders = [...prev];
      let newOrder = { ...newOrders[orderIndex], ...orderValues };
      newOrders[orderIndex] = newOrder;
      return newOrders;
    });
  }

  function onChangeItem(orderIndex, itemIndex, itemValues) {
    setOrders((prev) => {
      let newOrders = [...prev];
      let newOrder = { ...newOrders[orderIndex] };
      let newItems = [...newOrder.orderDetails.items];
      let newItem = { ...newItems[itemIndex], ...itemValues };
      newItems[itemIndex] = newItem;
      newOrder.orderDetails.items = newItems;
      newOrders[orderIndex] = newOrder;
      return newOrders;
    });
  }

  console.log("orders", orders);

  function submitOrder(order) {
    // set the current order to loading state
    setOrders((prev) =>
      prev.map((prevOrder) =>
        prevOrder.purchaseOrderNumber === order.purchaseOrderNumber
          ? { ...prevOrder, status: "LOADING" }
          : prevOrder,
      ),
    );

    const number = order.purchaseOrderNumber;
    const orderDate = moment(order.orderDetails.purchaseOrderDate).format(
      "YYYY-MM-DD",
    );
    const lines = order.orderDetails.items;
    const stockCategoryId = stockCategory.id;
    const detail = JSON.stringify({ order, lines }, "", 4);
    const variables = { region, stockCategoryId, number, detail, orderDate };
    submitAcknowledgement({
      variables,
      onCompleted(res) {
        console.log("res", res);
        completedOrders.current.push(order);
        setOrders((prev) =>
          prev.map((prevOrder) =>
            prevOrder.purchaseOrderNumber === order.purchaseOrderNumber
              ? { ...prevOrder, status: "ACKNOWLEDGED" }
              : prevOrder,
          ),
        );

        if (completedOrders.current.length === selectedOrders.length) {
          Alert("success", "All orders acknowledged.");
          completedOrders.current = [];
        } else {
          Alert("success", `Order ${order.purchaseOrderNumber} acknowledged.`);
          submitOrder(selectedOrders[completedOrders.current.length]);
        }
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    });
  }

  function trySubmitAcknowledgement() {
    if (
      window.confirm(
        `Are you sure to submit Acknowledgement for these ${
          selectedOrders.length
        } order${selectedOrders.length > 1 ? "s" : ""}?`,
      )
    ) {
      submitOrder(selectedOrders[0]);
    }
  }

  return (
    <div className="space-y-6 mt-4">
      <div className="flex space-x-2 items-center">
        <label htmlFor="selected-all">Select All:</label>
        <input
          type="checkbox"
          id="selected-all"
          checked={selectedOrders.length === orders.length}
          onChange={() => {
            if (selectedOrders.length === orders.length) {
              setOrders((prev) => prev.map((i) => ({ ...i, selected: false })));
            } else {
              setOrders((prev) => prev.map((i) => ({ ...i, selected: true })));
            }
          }}
        />
      </div>

      {orders.map((order, orderIndex) => (
        <AckOrderView
          key={orderIndex}
          order={order}
          onChangeOrder={(orderValues) =>
            onChangeOrder(orderIndex, orderValues)
          }
          onChangeItem={(itemIndex, values) =>
            onChangeItem(orderIndex, itemIndex, values)
          }
        />
      ))}

      <div className="text-right py-10 px-1">
        <div className="flex justify-end">
          <CharlesButton
            className="text-base"
            onClick={trySubmitAcknowledgement}
            loading={submitAcknowledgementRes.loading}
          >
            Submit Acknowledgement for All Orders
          </CharlesButton>
        </div>

        <div className="mt-2 italic">
          By clicking the <b>Submit Acknowledgement</b> button above, WIS will
          submit the acknowledgement to Amazon and <b>record the stock usage</b>
          . Then the latest stock will be computed base on the these records. So
          make sure you always submit Acknowledgement in WIS instead of handling
          order in Amazon Vendor Center, otherwise you will need to manage the
          stock by yourself.
        </div>
      </div>
    </div>
  );
};

export default AckOrdersView;
