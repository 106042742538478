import { useMutation } from "@apollo/client";
import { SCORE_COLORS } from "./const";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import Spinner from "components/Spinner";
import { CONFIRM_ASSESSMENT_QUESTION } from "./graphql";

const ConfirmAssessmentQuestionView = ({ assessmentQuestion, hide }) => {
  const [confirm, confirmRes] = useMutation(CONFIRM_ASSESSMENT_QUESTION, {
    onCompleted() {
      Alert("success", "Assessment score updated.");
      hide();
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  return (
    <div>
      <div className="flex space-x-8">
        {["A", "B", "C", "D"].map((choice) => (
          <div
            className="text-7xl font-black cursor-pointer opacity-100 border dark:border-gray-700 rounded-full w-28 h-28 flex items-center justify-center hover:bg-blue-50 hover:border-blue-300 dark:hover:bg-gray-900 transition-all duration-300 font-serif"
            key={choice}
            onClick={() => {
              confirm({
                variables: { id: assessmentQuestion.id, score: choice },
              });
            }}
          >
            <div className={SCORE_COLORS[choice]}>{choice}</div>
          </div>
        ))}
      </div>

      <div className="mt-8 opacity-70">
        Note that score C and D will automactically mark this question to be
        improvement required. However, you can still manually mark this question
        not improvement required after the score is set.
      </div>

      {confirmRes.loading ? <Spinner /> : null}
    </div>
  );
};

export default ConfirmAssessmentQuestionView;
