import Errors from "components/Errors";
import SearchBar from "components/SearchBar";
import Spinner from "components/Spinner";
import useOdooCustomers from "hooks/useOdooCustomers";
import { useState } from "react";
import { searchByProp } from "utils/search";

const OdooCustomersView = ({ onSelect }) => {
  const { loading, error, odooCustomers } = useOdooCustomers();
  const [searchText, setSearchText] = useState("");

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const customers = odooCustomers
    .filter((i) => i.name)
    .filter((i) =>
      searchByProp(i, ["display_name", "email", "vat"], searchText),
    );

  return (
    <div>
      <div>
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          placeholder="Search by name, email or vat"
        />
      </div>

      <div className="mt-2 divide-y dark:divide-gray-700">
        {customers.map((customer) => (
          <div
            key={customer.id}
            className="py-2 cursor-pointer hover:bg-blue-100"
            onClick={() => onSelect(customer)}
          >
            <div>{customer.display_name}</div>
            <div className="text-sm">{customer.email}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OdooCustomersView;
