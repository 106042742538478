import { gql, useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Input } from "components/Form";
import { Alert } from "components/Toast";
import { useState } from "react";

const REDUCE_PLAN = gql`
  mutation REDUCE_PLAN($planId: ID!, $lines: [ProductionPlanLineInputType]!) {
    reducePlan(planId: $planId, lines: $lines) {
      productionPlan {
        id
        lines {
          id
          qty
          reduceQty
          finalQty
        }
        totalQty
        totalCbm
        totalPrice
      }
    }
  }
`;

const ReduceQtyView = ({ originalPlan, complete }) => {
  console.log("originalPlan", originalPlan);

  const [lines, setLines] = useState(
    originalPlan.lines.map((i) => ({ ...i, originFinalQty: i.finalQty })),
  );
  const [reducePlan, reducePlanRes] = useMutation(REDUCE_PLAN, {
    onCompleted() {
      Alert("success", "Plan updated.");
      if (complete) complete(lines);
    },
    onError(error) {
      Alert("error", error.message);
    },
  });

  const computedLines = lines.map((i) => {
    const validQty =
      i.finalQty === 0 ||
      (i.originFinalQty >= i.finalQty &&
        i.finalQty >= i.product.quantityPerCarton &&
        i.finalQty % i.product.quantityPerCarton === 0);
    return { ...i, validQty };
  });

  const validLines = computedLines.every((i) => i.validQty);

  console.log("computedLines", computedLines);

  return (
    <div>
      <div className="-mx-2">
        <table>
          <thead>
            <tr className=" whitespace-nowrap">
              <th>Product</th>
              <th className="text-right">QTY / Carton</th>
              <th className="text-right">Original QTY</th>
              <th className="text-right">Final QTY</th>
            </tr>
          </thead>
          <tbody>
            {computedLines.map((line, index) => (
              <tr key={line.id} className="border-b">
                <td>
                  [{line.product.number}] {line.name}
                </td>
                <td className="text-right">{line.product.quantityPerCarton}</td>
                <td className="text-right">{line.originFinalQty}</td>
                <td className="text-right">
                  <div className="flex justify-end">
                    <Input
                      className="text-right"
                      validated={line.validQty}
                      value={line.finalQty}
                      onChange={(e) => {
                        const finalQty = parseInt(e.target.value) || 0;
                        setLines((prev) =>
                          prev.map((prevLine, prevIndex) =>
                            prevIndex === index
                              ? {
                                  ...prevLine,
                                  finalQty,
                                  reduceQty: prevLine.qty - finalQty,
                                }
                              : prevLine,
                          ),
                        );
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="text-sm mt-6 text-red-600 font-semibold">
        When you reduce qty for a handled plan, there will be stock released
        back to our supplier. This might effect the later plans.
        <br />
        Reduce qty will not recompute the current plan. However, reduce qty will
        recompute the later plans.
        <br />
        <br />
        Note that if you reduce qty for a plan, you can not increase it back.
        The only way to increase it is to create a new additional plan.
      </div>

      <div className="mt-6">
        <CharlesButton
          primary
          loading={reducePlanRes.loading}
          disabled={!validLines}
          onClick={() => {
            reducePlan({
              variables: {
                planId: originalPlan.id,
                lines: lines.map((i) => ({
                  id: i.id,
                  reduceQty: i.qty - i.finalQty,
                })),
              },
            });
          }}
        >
          Confirm Reduce
        </CharlesButton>
        {validLines ? null : (
          <div className="text-sm mt-2 text-red-600 font-semibold">
            You can only reduce qty, which is multiple of carton qty.
          </div>
        )}
      </div>
    </div>
  );
};

export default ReduceQtyView;
