const AssessmentBaseInfoView = ({ data }) => {
  const baseInfo = data ? JSON.parse(data) : null;

  return (
    <div className="card px-6 py-4">
      <h4>Base Info</h4>

      {baseInfo ? (
        <div className="mt-4 space-y-4">
          <div className=" space-y-2">
            <div>
              <label className="pr-3">Name: </label> {baseInfo.name}
            </div>
            <div>
              <label className="pr-3">Address: </label> {baseInfo.address}
            </div>
            <div>
              <label className="pr-3">District: </label> {baseInfo.district}
            </div>

            <div>
              <label className="pr-3">Phone: </label> {baseInfo.phone}
            </div>

            <div>
              <label className="pr-3">Fax: </label> {baseInfo.fax}
            </div>

            <div>
              <label className="pr-3">Email: </label> {baseInfo.email}
            </div>

            <div>
              <label className="pr-3">Website: </label> {baseInfo.website}
            </div>

            <div>
              <label className="pr-3">Contact Name and Title: </label>{" "}
              {baseInfo.contactNameAndTitle}
            </div>

            <div>
              <label className="pr-3">Principle Products: </label>{" "}
              {baseInfo.principleProducts}
            </div>

            <div>
              <label className="pr-3">Active Years: </label>{" "}
              {baseInfo.activeYears}
            </div>

            <div>
              <label className="pr-3">Contact Name and Title: </label>{" "}
              {baseInfo.contactNameAndTitle}
            </div>

            <div>
              <label className="pr-3">Business Certificate: </label>
              <div className="flex flex-wrap mt-2">
                {baseInfo.businessCertificate.map((url, index) => (
                  <div key={index} className="mr-2 mb-2">
                    <a href={url} target="_blank" rel="noreferrer">
                      <img
                        src={
                          url +
                          "?imageMogr2/thumbnail/300x/strip/quality/50/format/webp"
                        }
                        alt="business certificate"
                        className="w-32 h-32 rounded-lg object-cover border"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <label className="pr-3">Audit Certificates: </label>
              <div className="flex flex-wrap mt-2">
                {baseInfo.auditCertificates.map((url, index) => (
                  <div key={index} className="mr-2 mb-2">
                    <a href={url} target="_blank" rel="noreferrer">
                      <img
                        src={
                          url +
                          "?imageMogr2/thumbnail/500x/strip/quality/50/format/webp"
                        }
                        alt="audit certificate"
                        className="w-32 h-32 rounded-lg object-cover border"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <h5>Factory Size: </h5>
            <div className="mt-4 space-y-2">
              <div>
                <label className="pr-3">Workshop: </label>{" "}
                {baseInfo.workshop.size}
              </div>
              <div>
                <label className="pr-3">Total workshop men workers: </label>{" "}
                {baseInfo.workshop.totalMen}
              </div>
              <div>
                <label className="pr-3">Total workshop women workers: </label>{" "}
                {baseInfo.workshop.totalWomen}
              </div>

              <div>
                <label className="pr-3">Admin: </label> {baseInfo.admin.size}
              </div>
              <div>
                <label className="pr-3">Total admin men workers: </label>{" "}
                {baseInfo.admin.totalMen}
              </div>
              <div>
                <label className="pr-3">Total admin women workers: </label>{" "}
                {baseInfo.admin.totalWomen}
              </div>
            </div>
          </div>

          <div>
            <h5>Quarters: </h5>
            <div className="mt-4 space-y-2">
              <div>
                <label className="pr-3">Size: </label> {baseInfo.quarters.size}
              </div>
              <div>
                <label className="pr-3">Total person each room: </label>{" "}
                {baseInfo.quarters.totalPersonEachRoom}
              </div>
              <div>
                <label className="pr-3">Totel toilets: </label>{" "}
                {baseInfo.quarters.totalTolets}
              </div>
            </div>
          </div>

          <div>
            <h5>Canteen</h5>
            <div className="mt-4 space-y-2">
              <div>
                <label className="pr-3">Size: </label> {baseInfo.canteen?.size}
              </div>
              <div>
                <label className="pr-3">Total tables: </label>{" "}
                {baseInfo.canteen?.totalTables}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="opacity-70">
          Supplier has not input any base info for this assessment yet.
        </div>
      )}
    </div>
  );
};

export default AssessmentBaseInfoView;
