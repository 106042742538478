//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

export function setupTracker() {
  if (process.env.NODE_ENV === "development") return;

  mixpanel.init("4206b0bc66e564e1cf58785869406359", {
    debug: false,
    track_pageview: true,
    persistence: "localStorage",
    api_host: "https://x.waboba.com",
    secure_cookie: true,
  });
}

export function trackPageView() {
  if (process.env.NODE_ENV === "development") return;

  mixpanel.track_pageview();
}

export function trackUser(user) {
  if (process.env.NODE_ENV === "development") return;

  mixpanel.identify(user.id);
  mixpanel.people.set_once({ email: user.email });
}
