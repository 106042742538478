import CharlesButton from "components/charles/base";
import { FileSelector } from "components/Form";
import useFileUploader from "hooks/useFileUploader";
import { useState } from "react";
import { BsTrash, BsUpload } from "react-icons/bs";

const ProductImagesView = ({ product, setProduct }) => {
  const [images, setImages] = useState(product.images);
  const { isUploading, handleFileUpload } = useFileUploader((urls) => {
    const newImages = [...images, ...urls];
    setImages(newImages);
    setProduct({ ...product, images: newImages });
  }, "AWS");

  console.log("images", images);

  return (
    <div className="card px-6 py-4">
      <h4>Images</h4>
      <div className="mt-4 grid grid-cols-6 gap-4">
        {images.map((image, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 relative border rounded-2xl overflow-auto"
          >
            <img
              src={`${image}?imageView2/2/w/300`}
              alt={product.name + " image"}
              className="w-full object-cover"
            />
            <CharlesButton
              danger
              className="absolute top-0 right-0 p-4 z-10"
              onClick={() => {
                console.log("remove image");
                const newImages = images.filter((_, i) => i !== index);
                setImages(newImages);
                setProduct({ ...product, images: newImages });
              }}
            >
              <BsTrash />
            </CharlesButton>
          </div>
        ))}
      </div>

      <div className="mt-4 space-x-4 flex">
        <FileSelector
          accept="image/*"
          multiple
          className="text-sm"
          uploading={isUploading}
          title={
            <div className="flex items-center space-x-2">
              <BsUpload />
              <span>Upload image</span>
            </div>
          }
          onChange={(e) => {
            const files = [...e.target.files];
            handleFileUpload(files);
            e.target.value = null;
          }}
        />
      </div>
    </div>
  );
};

export default ProductImagesView;
