import { gql, useLazyQuery } from "@apollo/client";
import { Alert } from "components/Toast";
import { useState } from "react";

const FETCH_PRESIGNED_URL = gql`
  query FETCH_PRESIGNED_URL($filename: String!) {
    qiniuPresignedUrl(filename: $filename) {
      token
      key
      url
    }
  }
`;

const FETCH_AWS_PRESIGNED_URL = gql`
  query FETCH_AWS_PRESIGNED_URL($filename: String!, $contentType: String) {
    awsPreSignedUrl(filename: $filename, contentType: $contentType) {
      url
      fields
    }
  }
`;

function awsUploadFile(awsPreSignedUrl, file) {
  const fields = JSON.parse(awsPreSignedUrl.fields);
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", awsPreSignedUrl.url, true);

    let formData = new FormData();
    for (let [key, value] of Object.entries(fields)) {
      formData.append(key, value);
    }
    formData.append("acl", "public-read");
    formData.append("content-type", file.type);
    formData.delete("__typename");
    formData.append("file", file);
    xhr.upload.addEventListener(
      "progress",
      function (evt) {
        if (evt.lengthComputable) {
          console.log("process", Math.round((evt.loaded * 100) / evt.total));
        }
      },
      false,
    );
    xhr.onreadystatechange = function () {
      if (xhr.status === 204) {
        resolve(awsPreSignedUrl.url + fields.key);
      } else if (xhr.readyState === 4) {
        reject(new Error("Upload failed with status " + xhr.status));
      }
    };
    xhr.send(formData);
  });
}

function qiniuUploadFile(qiniuPresignedUrl, file) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "https://up-z2.qiniup.com", true);
    let formData = new FormData();
    formData.append("key", qiniuPresignedUrl.key);
    formData.append("token", qiniuPresignedUrl.token);
    formData.append("file", file);
    xhr.upload.addEventListener(
      "progress",
      function (evt) {
        if (evt.lengthComputable) {
          console.log("process", Math.round((evt.loaded * 100) / evt.total));
        }
      },
      false,
    );
    xhr.onreadystatechange = function () {
      if (
        xhr.readyState === 4 &&
        xhr.status === 200 &&
        xhr.responseText !== ""
      ) {
        resolve(qiniuPresignedUrl.url);
      } else if (xhr.readyState === 4) {
        reject(new Error("Upload failed with status " + xhr.status));
      }
    };
    xhr.send(formData);
  });
}

function useFileUploader(complete, service) {
  const query =
    service === "AWS" ? FETCH_AWS_PRESIGNED_URL : FETCH_PRESIGNED_URL;
  function uploadFile(data, file) {
    if (service === "AWS") return awsUploadFile(data.awsPreSignedUrl, file);
    return qiniuUploadFile(data.qiniuPresignedUrl, file);
  }

  const [fetchPresignedUrl] = useLazyQuery(query);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(files) {
    setIsUploading(true);
    let promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((onCompleted, onError) => {
          // remove comma from filename
          const filename = file.name.replace(/,/g, "");
          const variables = { filename };
          if (service === "AWS") variables.contentType = file.type;
          fetchPresignedUrl({ variables })
            .then((res) => uploadFile(res.data, file))
            .then(onCompleted)
            .catch(onError);
        }),
      );
    });

    Promise.all(promises)
      .then((urls) => {
        console.log("urls", urls);
        complete(urls);
      })
      .catch((err) => {
        Alert("error", err.message);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }

  return { isUploading, handleFileUpload };
}

export default useFileUploader;
