import { Input, Select } from "components/Form";
import Status from "components/Status";
import moment from "moment";

const AckOrderView = ({ order, onChangeOrder, onChangeItem }) => {
  const acceptedItems = order.orderDetails.items.filter(
    (i) => i.action.actionType === "accept",
  );
  const acceptedValue = acceptedItems.reduce(
    (acc, item) => acc + item.action.qty * item.netCost.amount,
    0,
  );

  return (
    <div key={order.purchaseOrderNumber} className="card relative">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center space-x-2">
            <input
              id={`selected-${order.purchaseOrderNumber}`}
              className="cursor-pointer"
              type="checkbox"
              checked={order.selected}
              onChange={() => onChangeOrder({ selected: !order.selected })}
            />
            <label htmlFor={`selected-${order.purchaseOrderNumber}`}>
              <h4>{order.purchaseOrderNumber}</h4>
            </label>
          </div>

          <div className="flex space-x-8">
            <div>
              <label htmlFor="">Date: </label>
              <span>
                {moment(order.orderDetails.purchaseOrderDate).format(
                  "YYYY-MM-DD",
                )}
              </span>
            </div>
            <div>
              <label htmlFor="">Window: </label>
              <span>
                {moment(order.windowStart).format("YYYY-MM-DD")} -{" "}
                {moment(order.windowEnd).format("YYYY-MM-DD")}
              </span>
            </div>
          </div>
        </div>

        <div>
          <Status status={order.status} />
        </div>
      </div>

      <div className="-mx-2 mt-4 whitespace-nowrap text-xs">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right">Stock</th>
              <th className="text-right">QTY Requested</th>
              <th className="text-right">Net Cost</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {order.orderDetails.items.map((item, index) => (
              <tr
                key={index}
                className={`border-y border-gray-100 dark:border-gray-700 bg-opacity-80 dark:bg-opacity-20
                  ${
                    item.action.actionType === "accept"
                      ? "dark:bg-green-500 bg-green-100"
                      : "dark:bg-red-500 bg-red-100"
                  }`}
              >
                <td className="w-full">
                  <div className="flex space-x-2 items-center">
                    <div>
                      <div className="font-semibold">
                        [{item.stock?.number}] {item.stock?.name}
                      </div>
                      <div className="flex space-x-2 opacity-70">
                        <div>{item.amazonProductIdentifier}</div>
                        <div>{item.stock?.qtyPerMaster} / Master</div>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      {item.stock?.wisProduct?.images?.map((image, index) => (
                        <img
                          className="w-8 h-8 rounded-lg"
                          key={index}
                          src={image.url}
                          alt={item.stock.name}
                        />
                      ))}
                    </div>
                  </div>
                </td>
                <td className="text-right">{item.qtyOnHand}</td>
                <td className="text-right">{item.orderedQuantity.amount}</td>
                <td className="text-right">
                  {item.netCost.currencyCode} {item.netCost.amount}
                </td>
                <td>
                  <div className="flex space-x-2 items-center justify-end">
                    <div>
                      <Select
                        disabled={order.status !== "AWAITING"}
                        value={item.action.actionType}
                        onChange={(e) =>
                          onChangeItem(index, {
                            action: {
                              ...item.action,
                              actionType: e.target.value,
                            },
                          })
                        }
                      >
                        <option value="accept">Accept</option>
                        <option value="reject">Reject</option>
                      </Select>
                    </div>

                    {item.action.actionType === "accept" && (
                      <div>
                        <Input
                          disabled={order.status !== "AWAITING"}
                          className="w-16 text-center"
                          value={item.action.qty}
                          onChange={(e) =>
                            onChangeItem(index, {
                              action: { ...item.action, qty: e.target.value },
                            })
                          }
                        />
                      </div>
                    )}

                    {item.action.actionType === "reject" ? (
                      <Select
                        disabled={order.status !== "AWAITING"}
                        value={item.action.rejectReason || "0"}
                        onChange={(e) =>
                          onChangeItem(index, {
                            action: {
                              ...item.action,
                              rejectReason: e.target.value,
                            },
                          })
                        }
                      >
                        <option value="0" disabled>
                          Reject Reason
                        </option>
                        <option value="TemporarilyUnavailable">
                          TemporarilyUnavailable
                        </option>
                        <option value="InvalidProductIdentifier">
                          InvalidProductIdentifier
                        </option>
                        <option value="ObsoleteProduct">ObsoleteProduct</option>
                      </Select>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4">
        <div className="flex justify-end space-x-4 text-xl">
          <div className="opacity-60">Accepted Value:</div>
          <div
            className={`font-bold
                  ${acceptedValue >= 200 ? " text-green-600" : " text-red-600"}`}
          >
            {acceptedValue.toFixed(2)}
          </div>
        </div>

        {acceptedValue < 200 && acceptedItems.length > 0 ? (
          <div className="font-bold text-red-600 dark:text-red-800 text-right">
            The order value is under 200, you should not accept any items for
            this order.
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AckOrderView;
