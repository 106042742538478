import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import { BsExclamationCircleFill } from "react-icons/bs";

const FETCH_CUSTOMER_REVIEW_INFO = gql`
  query FETCH_CUSTOMER($id: ID!) {
    customer(id: $id) {
      id
      name
      notes
      areas {
        id
        name
      }
    }
  }
`;

const ReviewCustomerView = ({ customer, complete }) => {
  const { loading, error, data } = useQuery(FETCH_CUSTOMER_REVIEW_INFO, {
    variables: { id: customer.id },
  });

  if (loading) return <Spinner text="Loading Customer" />;
  if (error) return <Errors error={error} />;

  return (
    <div>
      <div>
        <div className="text-lg text-orange-600">
          <BsExclamationCircleFill className="inline-block mr-2 -translate-y-[2px]" />
          Please read this notes and review the errors for this customer before
          you create shipment.
        </div>

        <div className="mt-4">
          {data.customer.notes ? (
            <div className="whitespace-pre-wrap text-base">
              {data.customer.notes}
            </div>
          ) : (
            <label>This customer has no special notes.</label>
          )}
        </div>
      </div>
      <hr />

      <div>
        <CharlesButton onClick={complete} next>
          Continue Create Shipment
        </CharlesButton>
      </div>
    </div>
  );
};

export default ReviewCustomerView;
