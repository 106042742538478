import Status from "components/Status";
import moment from "moment";

const ShipmentView = ({ shipment }) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <div className="font-semibold">
          🗓️ {moment(shipment.date).format("YYYY-MM-DD")}
        </div>
        <Status status={shipment.state} />
      </div>
      <div className="mt-2 -mx-2">
        <table>
          <tbody>
            {shipment.lines.map((line) => (
              <tr
                key={line.id}
                className="border-y border-gray-100 dark:border-gray-700"
              >
                <td>
                  [{line.product.number}] {line.product.name}
                </td>
                <td className="text-right">{line.qty}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShipmentView;
