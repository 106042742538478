import SelectCustomerView from "./SelectCustomerView";
import { useSearchParams } from "react-router-dom";
import ABSales from "./ABSales";
import useOdooCustomers from "hooks/useOdooCustomers";
import Spinner from "components/Spinner";
import Errors from "components/Errors";

const ABPage = () => {
  const [searchParams, setSearchParams] = useSearchParams({ partnerId: "ALL" });
  const partnerId = searchParams.get("partnerId");

  const { loading, error, odooCustomers } = useOdooCustomers();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  let customer;
  if (partnerId !== "ALL" && partnerId !== "EXCLUDE_INC") {
    customer = odooCustomers.find((i) => i.name === partnerId);
  }

  return (
    <div className="p-6">
      <div>
        <SelectCustomerView
          odooCustomers={odooCustomers}
          value={partnerId}
          onSelect={(id) => {
            setSearchParams({ partnerId: id });
          }}
        />
        <div className="font-semibold text-xs text-red-600 mt-2 mx-1">
          We are working on this page. The data might not be accurate at this
          moment.
        </div>
      </div>

      <div className="mt-2 ">
        <ABSales partnerId={partnerId} customer={customer} />
      </div>
    </div>
  );
};

export default ABPage;
