import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

const MonthSalesView = ({ data, years, colors }) => {
  return (
    <div className="card">
      <h4>Sales Amount by Month</h4>
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data} animationEasing="ease-in-out">
            <XAxis dataKey="month" />
            <Tooltip />
            <Legend type="start" />
            {years.map((year, index) => (
              <Bar
                legendType="circle"
                key={year}
                dataKey={year}
                fill={colors[index]}
                label={{
                  position: "top",
                  formatter: (i) => i.toLocaleString(),
                }}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MonthSalesView;
