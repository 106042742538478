import { Select } from "components/Form";
import { useState } from "react";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const ProductSaleView = ({ data, years, colors }) => {
  const [productValueType, setProductValueType] = useState("");

  return (
    <div className="card">
      <div className="flex  justify-between items-center">
        <h4>Sales by Product Category</h4>

        <div>
          <Select
            onChange={(e) => setProductValueType(e.target.value)}
            value={productValueType}
          >
            <option value="">Amount</option>
            <option value="_qty">QTY</option>
          </Select>
        </div>
      </div>
      <div className="mt-4 border-gray-100 dark:border-gray-700">
        <ResponsiveContainer width="100%" height={76 * data.length}>
          <BarChart
            data={data}
            layout="vertical"
            barSize={8}
            barGap={10}
            margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
            animationEasing="ease-in-out"
          >
            <XAxis type="number" tickFormatter={(i) => i.toLocaleString()} />
            <YAxis
              dataKey="category"
              type="category"
              mirror={true}
              tickLine={false}
              tick={(i) => (
                <text
                  x={i.x}
                  y={i.y}
                  // dx={-8}
                  dy={-24}
                  textAnchor="right"
                  style={{ fontWeight: "bold" }}
                >
                  {i.payload.value}
                </text>
              )}
            />
            {/* <Tooltip /> */}
            <Legend />
            {years.map((year, index) => (
              <Bar
                key={year}
                dataKey={`${year}${productValueType}`}
                fill={colors[index]}
                label={{
                  position: "right",
                  formatter: (i) => i.toLocaleString(),
                }}
                animationEasing="ease-in-out"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ProductSaleView;
