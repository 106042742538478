import { useMutation, useQuery } from "@apollo/client";
import { formatDate } from "react-day-picker/moment";
import { Button } from "components/base";
import Errors from "components/Errors";
import { Input, Select } from "components/Form";
import Spinner, { InlineSpinner } from "components/Spinner";
import { useEffect, useState } from "react";
import {
  AMAZON_SUBMIT_ACKNOWLEDGEMENT,
  FETCH_AMAZON_STOCK_CATEGORY,
} from "./graphql";
import { Alert } from "components/Toast";
import { useOdooProducts } from "hooks/useOdooProduct";
import { useAmazonParty } from "hooks/useAmazon";
import useOdooCustomers from "hooks/useOdooCustomers";

const NewOrderView = ({ regionCode, order, hide, stockCategories }) => {
  const barcodes = order.orderDetails.items.map(
    (i) => i.vendorProductIdentifier,
  );

  const stockCategory = stockCategories.find(
    (i) => i.regions && i.regions.includes(regionCode),
  );

  const [stockCategoryId, setStockCategoryId] = useState(
    stockCategory ? stockCategory.id : "0",
  );

  const odooProductsQuery = useOdooProducts({ barcodes });
  const amazonPartyQuery = useAmazonParty(
    order.orderDetails.shipToParty.partyId,
  );
  const odooCustomersQuery = useOdooCustomers();

  if (
    odooProductsQuery.loading ||
    amazonPartyQuery.loading ||
    odooCustomersQuery.loading
  )
    return <Spinner />;
  if (odooProductsQuery.error)
    return <Errors error={odooProductsQuery.error} />;
  if (amazonPartyQuery.error) return <Errors error={amazonPartyQuery.error} />;
  if (odooCustomersQuery.error)
    return <Errors error={odooCustomersQuery.error} />;

  const odooProducts = odooProductsQuery.odooProducts;

  const odooDeliveryAddress = odooCustomersQuery.odooCustomers.find(
    (i) => i.comment === order.orderDetails.shipToParty.partyId,
  );
  const odooCustomer =
    odooDeliveryAddress && odooDeliveryAddress.parent_id
      ? odooCustomersQuery.odooCustomers.find(
          (i) => i.id === odooDeliveryAddress.parent_id[0],
        )
      : null;

  const computedOrder = {
    ...order,
    odooCustomer,
    odooDeliveryAddress,
    shipToParty: { ...amazonPartyQuery.data.amzParty },
  };

  return (
    <OrderDetail
      regionCode={regionCode}
      order={computedOrder}
      hide={hide}
      odooProducts={odooProducts}
      stockCategoryId={stockCategoryId}
      setStockCategoryId={setStockCategoryId}
      stockCategories={stockCategories}
    />
  );
};

function computeAction(line) {
  const requestQty = line.orderedQuantity.amount;
  const wrongBarcodes = ["0840001930872", "0840001910003", "0840001930858"];
  let actionType = "accept";
  let qty = requestQty;
  let rejectReason = null;
  const stock = line.stock;
  if (!stock || stock.latestQty <= 0) {
    actionType = "reject";
    qty = 0;
    rejectReason = "TemporarilyUnavailable";
  } else {
    if (wrongBarcodes.includes(line.barcode)) {
      actionType = "reject";
      qty = 0;
      rejectReason = "TemporarilyUnavailable";
    } else {
      if (requestQty >= stock.qtyPerMaster) {
        const acceptableQty = Math.min(requestQty, stock.latestQty);
        const sets = Math.floor(acceptableQty / stock.qtyPerMaster);
        if (sets > 0) {
          qty = sets * stock.qtyPerMaster;
          actionType = "accept";
        } else {
          actionType = "reject";
          qty = 0;
          rejectReason = "TemporarilyUnavailable";
        }
      } else {
        actionType = "reject";
        qty = 0;
        rejectReason = "TemporarilyUnavailable";
      }
    }
  }
  return { ...line, action: { actionType, qty, rejectReason } };
}

const OrderDetail = ({
  regionCode,
  order,
  hide,
  odooProducts,
  stockCategoryId,
  setStockCategoryId,
  stockCategories,
}) => {
  const { loading, error, data } = useQuery(FETCH_AMAZON_STOCK_CATEGORY, {
    variables: { id: stockCategoryId },
  });
  const [lines, setLines] = useState([]);

  useEffect(() => {
    if (data) {
      setLines(
        order.orderDetails.items
          .map((i) => {
            const stock = data.amazonStockCategory.stocks.find((s) => {
              if (s.asin) {
                if (s.asin === i.amazonProductIdentifier) return true;
                const combinedAsins = s.asin.split(",");
                if (combinedAsins.includes(i.amazonProductIdentifier))
                  return true;
              }
              return false;
            });
            const odooProduct = stock
              ? odooProducts.find(
                  (p) => p.barcode && p.barcode === stock.barcode,
                )
              : null;
            return { ...i, stock, odooProduct };
          })
          .map(computeAction)
          .map((line) => ({ ...line, cost: line.netCost.amount })),
      );
    }
  }, [data]);

  const [submitAcknowledgement, submitAcknowledgementRes] = useMutation(
    AMAZON_SUBMIT_ACKNOWLEDGEMENT,
    {
      onCompleted() {
        Alert("success", "Acknowledged.");
        hide();
      },
      onError(error) {
        Alert("error", error.message);
      },
      refetchQueries: [
        "FETCH_AMAZON_STOCKS",
        "FETCH_AMAZON_PURCHASE_ORDERS",
        "FETCH_AMAZON_LIVE_PURCHASE_ORDERS_STATUS",
      ],
      awaitRefetchQueries: true,
    },
  );

  function onChangeLineAction(index, key, value) {
    setLines((prev) =>
      prev.map((line, prevIndex) => {
        if (prevIndex !== index) return line;
        return { ...line, action: { ...line.action, [key]: value } };
      }),
    );
  }

  function submitAcknowledgementHandler() {
    const number = order.purchaseOrderNumber;
    const orderDate = formatDate(
      order.orderDetails.purchaseOrderDate,
      "YYYY-MM-DD",
    );
    const detail = JSON.stringify({ order, lines }, "", 4);
    const variables = {
      region: regionCode,
      stockCategoryId,
      number,
      detail,
      orderDate,
    };
    submitAcknowledgement({ variables });
  }

  const acceptedItems = lines.filter((i) => i.action.actionType === "accept");
  const acceptedValue = acceptedItems.reduce(
    (res, item) => (res += item.action.qty * item.netCost.amount),
    0,
  );

  return (
    <div>
      <div className="space-y-4">
        <div className="flex space-x-4">
          <label>Status: </label>
          <div>{order.purchaseOrderState}</div>
        </div>

        <div className="flex space-x-4">
          <label>Window Start: </label>
          <div>{formatDate(order.windowStart, "YYYY-MM-DD")}</div>

          <label>Window End: </label>
          <div>{formatDate(order.windowEnd, "YYYY-MM-DD")}</div>
        </div>

        <div className="flex space-x-8">
          <div className="flex space-x-4">
            <label>Selling Party: </label>
            <div>
              <div className="font-body">
                {order.orderDetails.sellingParty.partyId}
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Buying Party: </label>
            <div>
              <div className="font-body">
                {order.orderDetails.buyingParty.partyId}
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Ship to Party: </label>
            <div>
              <div className="font-body">
                {order.orderDetails.shipToParty.partyId}
              </div>
            </div>
          </div>

          <div className="flex space-x-4">
            <label>Bill to Party: </label>
            <div>
              <div className="font-body">
                {order.orderDetails.billToParty.partyId}
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-24">
          <div>
            <div>
              <label className="mr-2">Delivery Address: </label>
              {order.orderDetails.shipToParty.partyId}
            </div>
            <div className="whitespace-pre-wrap mt-2">
              {order.shipToParty.address}
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <label>Expect Date: </label>
          <div>{order.windowEnd.slice(0, 10)}</div>
        </div>
      </div>

      <div className="mt-6">
        <div className="flex items-center space-x-2">
          <label htmlFor="">Use Stock Category:</label>
          <Select
            value={stockCategoryId}
            onChange={(e) => setStockCategoryId(e.target.value)}
          >
            <option value="0" disabled>
              -- Select a Stock Category --
            </option>
            {stockCategories.map((i) => (
              <option value={i.id} key={i.id}>
                [{i.regions}] {i.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="text-pink-600 text-sm my-2">
          Stock numbers auto calculate base on the initial data and usage. Make
          sure you always <b>choose the correct stock category</b>.
        </div>
      </div>

      {loading ? (
        <div className="p-12">
          <InlineSpinner text="Loading Stock..." />
        </div>
      ) : error ? (
        <Errors error={error} />
      ) : (
        <div className="mt-2 space-y-4">
          <table className="bg-white dark:bg-gray-900">
            <thead>
              <tr>
                <th className="border dark:border-gray-700 p-4 text-left">
                  Product
                </th>
                <th className="border dark:border-gray-700 p-4 text-right">
                  Stock
                </th>
                <th className="border dark:border-gray-700 p-4 text-right">
                  Qty Requested
                </th>
                <th className="border dark:border-gray-700 p-4 text-right">
                  Net Cost
                </th>

                <th className="border dark:border-gray-700 p-4 text-right">
                  Acknowledgement
                </th>
              </tr>
            </thead>
            <tbody>
              {lines.map((line, lineIndex) => (
                <tr
                  key={lineIndex}
                  className={
                    line.action.actionType === "accept"
                      ? "bg-green-200 bg-opacity-30 dark:bg-green-900 dark:bg-opacity-30"
                      : "bg-pink-100 bg-opacity-60 dark:bg-pink-900 dark:bg-opacity-30"
                  }
                >
                  <td className="border dark:border-gray-700 px-4">
                    <div className="space-y-1 ">
                      {line.stock ? (
                        <div className="font-bold">
                          [{line.stock.number}] {line.stock.name}{" "}
                          {line.stock.barcode}
                        </div>
                      ) : (
                        <div>
                          <b>{line.vendorProductIdentifier}</b> - No stock for
                          this barcode.
                        </div>
                      )}

                      {line.stock ? (
                        <div className="flex space-x-4">
                          <label className="font-semibold pr-2">
                            Qty / Master:{" "}
                          </label>
                          {line.stock.qtyPerMaster}
                        </div>
                      ) : null}
                    </div>
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right">
                    {line.stock ? line.stock.latestQty : "-"}
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right">
                    {line.orderedQuantity.amount}
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right">
                    {line.netCost.currencyCode} {line.netCost.amount}
                  </td>

                  <td className="border dark:border-gray-700 px-4 text-right text-xs">
                    <div className="flex space-x-2 justify-end">
                      <div>
                        <Select
                          value={line.action.actionType}
                          onChange={(e) =>
                            onChangeLineAction(
                              lineIndex,
                              "actionType",
                              e.target.value,
                            )
                          }
                        >
                          <option value="accept">Accept</option>
                          <option value="reject">Reject</option>
                        </Select>
                      </div>
                      <div>
                        <Input
                          className="w-16 text-center"
                          value={line.action.qty}
                          onChange={(e) =>
                            onChangeLineAction(lineIndex, "qty", e.target.value)
                          }
                        />
                      </div>

                      {line.action.actionType === "reject" ? (
                        <Select
                          textAlignLast="center"
                          value={line.action.rejectReason}
                          onChange={(e) =>
                            onChangeLineAction(
                              lineIndex,
                              "rejectReason",
                              e.target.value,
                            )
                          }
                        >
                          <option value="0" disabled>
                            Reject Reason
                          </option>
                          <option value="TemporarilyUnavailable">
                            TemporarilyUnavailable
                          </option>
                          <option value="InvalidProductIdentifier">
                            InvalidProductIdentifier
                          </option>
                          <option value="ObsoleteProduct">
                            ObsoleteProduct
                          </option>
                        </Select>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div>
            <div className="flex justify-end space-x-4 text-xl">
              <div className="opacity-60">Accepted Value:</div>
              <div
                className={`font-bold
                        ${acceptedValue >= 200 ? " text-green-600" : " text-red-600"}`}
              >
                {acceptedValue.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-8">
        <div className="italic space-y-2">
          <p>
            The <b>delivery address</b> is get from{" "}
            <a
              className="text-blue-600"
              target="_blank"
              rel="noreferrer"
              href="https://vendorcentral.amazon.co.uk/hz/vendor/members/support/form/hub"
            >
              Amazon Resource Center / Operations / Pan EU Vendor Manual
            </a>{" "}
            (
            <a
              className="text-blue-600"
              href="https://vendorcentral.s3.amazonaws.com/RC/EU_FC_addresses%2Bdelivery_specificities.xlsx"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
            ) , however it has slight different text to the one shown in Amazon
            Vendor Center. According to Amazon Support, this is also the correct
            address for this FC Code as well as the one in the website. Since
            Amazon SP API does not give us any Delivery Address directly, we
            show this address here.
          </p>

          <p>We should only accept order value higher than 200 EUR.</p>
        </div>

        <div className="space-y-8 py-8 mt-4">
          <div>
            {acceptedValue < 200 && acceptedItems.length > 0 ? (
              <div className="font-bold text-red-600 mb-4">
                The order value is under 200, you should not accept any items
                for this order.
              </div>
            ) : null}

            <Button
              size="xl"
              bold
              title="Submit Acknowledgement"
              onClick={submitAcknowledgementHandler}
              loading={submitAcknowledgementRes.loading}
              disabled={submitAcknowledgementRes.loading}
            />
            <div className="mt-2 italic">
              By clicking the <b>Submit Acknowledgement</b> button, WIS will
              submit the acknowledgement to Amazon and{" "}
              <b>record the stock usage</b>. Then the latest stock will be
              computed base on the these records. So make sure you always submit
              Acknowledgement in WIS instead of handling order in Amazon Vendor
              Center, otherwise you will need to manage the stock by yourself.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderView;
