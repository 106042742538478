import { gql } from "@apollo/client";
import {
  FRAGMENT_PRODUCT_FOR_COST,
  FRAGMMENT_COMPONENT,
} from "graphql/fragments";

export const FETCH_ALL_FACTORIES = gql`
  query FETCH_ALL_FACTORIES {
    allFactories {
      id
      odooId
      name
      status
    }
  }
`;

export const ASSOCIATE_PRODUCT_LINES = gql`
  mutation ASSOCIATE_PRODUCT_LINES($componentId: ID!, $productLineIds: [ID]) {
    associateComponentProductLines(
      componentId: $componentId
      productLineIds: $productLineIds
    ) {
      component {
        id
        productLines {
          id
          name
        }
      }
    }
  }
`;

export const REORDERED_COMPONENT_PRICES = gql`
  mutation REORDERED_COMPONENT_PRICES($id: ID!, $priceIds: [ID!]!) {
    reorderComponentPrices(id: $id, priceIds: $priceIds) {
      component {
        ...component
      }
    }
  }
  ${FRAGMMENT_COMPONENT}
`;

export const FETCH_INITIAL_DATA = gql`
  query FETCH_INITIAL_DATA {
    allFactories {
      id
      nickName
      name
    }
    allComponents {
      ...component
    }
  }
  ${FRAGMMENT_COMPONENT}
`;

export const FETCH_ALL_COMPONENTS = gql`
  query FETCH_ALL_COMPONENTS {
    allComponents {
      ...component
    }
  }
  ${FRAGMMENT_COMPONENT}
`;

export const FETCH_DATA_FOR_COMPONENT_DETAIL = gql`
  query FETCH_DATA_FOR_COMPONENT_DETAIL($id: ID!) {
    component(id: $id) {
      ...component
    }
    allFactories {
      id
      nickName
      name
    }
  }
  ${FRAGMMENT_COMPONENT}
`;

export const FETCH_ALL_PRODUCT_LINES = gql`
  query FETCH_ALL_PRODUCT_LINES {
    allProductlines {
      id
      name
      number
      mainImage
    }
  }
`;

export const SAVE_COMPONENT_PRICE = gql`
  mutation SAVE_COMPONENT_PRICE(
    $id: ID
    $factoryId: ID
    $componentId: ID
    $minQty: Int
    $price: Float
    $currency: String
    $avaliableFromMonth: Int
    $avaliableToMonth: Int
  ) {
    saveComponentPrice(
      id: $id
      factoryId: $factoryId
      componentId: $componentId
      minQty: $minQty
      price: $price
      currency: $currency
      avaliableFromMonth: $avaliableFromMonth
      avaliableToMonth: $avaliableToMonth
    ) {
      componentPrice {
        id
        currency
        minQty
        price
        archivedAt
      }
    }
  }
`;

export const ARCHIVE_COMPONENT_PRICE = gql`
  mutation ARCHIVE_COMPONENT_PRICE($id: ID!) {
    archiveComponentPrice(id: $id) {
      componentPrice {
        id
        archivedAt
      }
    }
  }
`;

export const CREATE_COMPONENT = gql`
  mutation CREATE_COMPONENT(
    $name: String!
    $number: String!
    $itemShape: String!
    $itemBallSize: Float!
    $itemCubeSizeX: Float!
    $itemCubeSizeY: Float!
    $itemCubeSizeZ: Float!
    $itemFlatSizeX: Float!
    $itemFlatSizeY: Float!
    $description: String
    $productLineIds: [ID]
  ) {
    createComponent(
      name: $name
      number: $number
      itemShape: $itemShape
      itemBallSize: $itemBallSize
      itemCubeSizeX: $itemCubeSizeX
      itemCubeSizeY: $itemCubeSizeY
      itemCubeSizeZ: $itemCubeSizeZ
      itemFlatSizeX: $itemFlatSizeX
      itemFlatSizeY: $itemFlatSizeY
      description: $description
      productLineIds: $productLineIds
    ) {
      component {
        ...component
      }
    }
  }
  ${FRAGMMENT_COMPONENT}
`;

export const BATCH_UPDATE_COST = gql`
  mutation BATCH_UPDATE_COST(
    $factoryId: ID!
    $productIds: [ID]
    $notes: String!
    $code: String!
    $currency: String
    $effectiveDate: Date!
    $updatedPrices: [UpdatedPriceType]
    $formula: String
    $formulaValue: Float
    $decimalPlaces: Int
    $levels: [BatchUpdatePriceLevelInputType]
    $syncToOdoo: Boolean
  ) {
    batchUpdatePrices(
      factoryId: $factoryId
      productIds: $productIds
      currency: $currency
      notes: $notes
      code: $code
      effectiveDate: $effectiveDate
      updatedPrices: $updatedPrices
      formula: $formula
      formulaValue: $formulaValue
      decimalPlaces: $decimalPlaces
      levels: $levels
      syncToOdoo: $syncToOdoo
    ) {
      factory {
        id
        name
        products {
          ...productForCost
        }
      }
      effectedProducts {
        id
        odooId
      }
    }
  }
  ${FRAGMENT_PRODUCT_FOR_COST}
`;
