export const newSimpleFields = {
  name: "",
  number: "",
  productType: "normal",
  odooTemplateId: "",
  odooId: "",
  odooCategoryId: "0",
  barCode: "",
  packing: "",
  shippingMarkName: "",
  colors: "",
  useProductLineImagesIfNoImages: true,
  innerBoxBarcodeEan: "",
  innerBoxBarcodeUpc: "",
  outerCartonBarcodeEan: "",
  outerCartonBarcodeUpc: "",
  innerCartonBarcodeEan: "",
  innerCartonBarcodeUpc: "",
  packingName: "",
  itemsPerSet: 1,
  defaultQty: 1,
  moq: 0,
  itemWeight: "",
  grossWeight: "",
  innerBoxX: "",
  innerBoxY: "",
  innerBoxZ: "",
  displayBoxX: "",
  displayBoxY: "",
  displayBoxZ: "",
  innerCartonX: "",
  innerCartonY: "",
  innerCartonZ: "",
  outerCartonX: "",
  outerCartonY: "",
  outerCartonZ: "",
  quantityPerDisplayBox: "",
  quantityPerCarton: "",
  ctnNetWeight: "",
  ctnGrossWeight: "",
  description: "",
  isActive: true,
  isActiveForBiz: true,
  isLaborCost: false,
  isSalable: false,
  isPurchasable: false,
  reminderForMissingBeautyShot: false,
  note: "",
  considerBomInProduction: true,
};

export const newSize = {
  shape: "ball",
  ballDiameter: 0,
  cubeX: 0,
  cubeY: 0,
  cubeZ: 0,
  flatX: 0,
  flatY: 0,
  cylindricalDiameter: 0,
  cylindricalHeight: 0,
  multiSizes: "",
};
