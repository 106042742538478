import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useState } from "react";
import {
  FETCH_BIZ_PRODUCT_COLLECTION,
  SAVE_BIZ_PRODUCT,
  SAVE_BIZ_PRODUCT_LINE,
} from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { buildName } from "./name";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import ProductRow from "./ProductRow";
import SearchBar from "components/SearchBar";
import { useSearchParams } from "react-router-dom";
import { searchByProp } from "utils/search";

const CURRENT_COLLECTION_NAME = "Catalog 2025";

const BizProductsPage = () => {
  const { loading, error, data } = useQuery(FETCH_BIZ_PRODUCT_COLLECTION, {
    variables: { name: CURRENT_COLLECTION_NAME },
  });
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });
  const [saveProductLine] = useMutation(SAVE_BIZ_PRODUCT_LINE, {
    onCompleted() {
      Alert("success", "Product Line Saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  const [saveProduct] = useMutation(SAVE_BIZ_PRODUCT, {
    onCompleted() {
      Alert("success", "Product Saved.");
    },
    onError(error) {
      Alert("error", parseError(error));
    },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const q = searchParams.get("q");

  const root = data.anyCollection;
  const nodes = root.descendants
    .map((i) => ({
      ...i,
      name: buildName(i, [root, ...root.descendants]),
      productLines: root.bizProductLines.filter((j) => {
        const collectionIds = j.anyCollections.map((k) => k.id);
        return collectionIds.includes(i.id);
      }),
    }))
    .map((i) => ({
      ...i,
      productLines: i.productLines
        .map((j) => ({
          ...j,
          products: j.products.filter((i) =>
            searchByProp(i, ["number", "name"], q),
          ),
        }))
        .filter((j) => j.products.length > 0),
    }))
    .filter((i) => i.productLines.length > 0)
    .sort((a, b) => a.name.localeCompare(b.name));

  function toggleProdductLine(productLine, values) {
    saveProductLine({
      variables: { id: productLine.id, simpleFields: values },
      optimisticResponse: {
        __typename: "Mutation",
        saveProductLine: {
          productLine: {
            __typename: "ProductLine",
            ...productLine,
            ...values,
          },
        },
      },
    });
  }

  function onChangeProduct(product, values) {
    saveProduct({
      variables: { id: product.id, simpleFields: values },
      optimisticResponse: {
        __typename: "Mutation",
        saveProduct: {
          product: {
            __typename: "Product",
            ...product,
            ...values,
          },
        },
      },
    });
  }

  return (
    <div className="flex flex-col flex-1 overflow-auto card p-0 m-6">
      <div className="p-4">
        <SearchBar
          placeholder="Search by product name or number"
          initialQuery={q}
          onChange={(value) => {
            setSearchParams({ q: value });
          }}
        />
      </div>

      <div className="flex-1 overflow-auto">
        <table>
          <tbody>
            {nodes.map((node) => (
              <Fragment key={node.id}>
                <tr className="font-semibold bg-gray-600 text-gray-100 dark:bg-gray-900 sticky top-0 z-10">
                  <td className="px-6 py-3" colSpan={8}>
                    {node.name}
                  </td>
                </tr>
                {node.productLines.map((productLine) => (
                  <Fragment key={productLine.id}>
                    <tr className="bg-gray-200 dark:bg-gray-800 sticky top-0 z-10 font-semibold">
                      <td className="px-6 py-3">{productLine.name}</td>
                      <td className="px-6 text-right">Groups</td>
                      <td className="px-6 text-right">Version</td>
                      <td className="px-6 text-right">
                        <div className="flex items-center justify-end space-x-2">
                          <span>WIS</span>
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={productLine.activeForWis}
                            onChange={(e) => {
                              toggleProdductLine(productLine, {
                                activeForWis: !productLine.activeForWis,
                              });
                            }}
                          />
                        </div>
                      </td>
                      <td className="px-6 text-right">
                        <div className="flex items-center justify-end space-x-2">
                          <span>BIZ</span>
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={productLine.activeForBiz}
                            onChange={(e) => {
                              toggleProdductLine(productLine, {
                                activeForBiz: !productLine.activeForBiz,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    {productLine.products.map((product, index) => (
                      <ProductRow
                        index={index}
                        key={product.id}
                        product={product}
                        onChangeProduct={onChangeProduct}
                      />
                    ))}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BizProductsPage;
