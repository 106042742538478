import { Select } from "components/Form";
import useOdooCustomers from "hooks/useOdooCustomers";

const SelectCustomerView = ({ odooCustomers, value, onSelect }) => {
  return (
    <Select value={value || "ALL"} onChange={(e) => onSelect(e.target.value)}>
      <optgroup label="Groups">
        <option value="ALL">All Customers</option>
        <option value="EXCLUDE_INC">Exclude Inc</option>
      </optgroup>

      <optgroup label="Customers">
        {odooCustomers
          .filter((i) => !i.parent_id && i.name)
          .map((customer) => (
            <option key={customer.id} value={customer.name}>
              {customer.name}
            </option>
          ))}
      </optgroup>
    </Select>
  );
};

export default SelectCustomerView;
