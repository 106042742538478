export const calcRowTotal = (row) => {
   let totalCarton = 0;
   if (!isNaN(row.qty)) {
      if (row.newProductQtyPerCarton) {
         totalCarton = Math.ceil(row.qty / row.newProductQtyPerCarton);
      }
   }

   let totalNetWeight = 0;
   if (totalCarton) {
      totalNetWeight = totalCarton * row.newProductNetWeight;
   }
   totalNetWeight = parseFloat(totalNetWeight.toFixed(2));

   let totalGrossWeight = 0;
   if (totalCarton) {
      totalGrossWeight = totalCarton * row.newProductGrossWeight;
   }
   totalGrossWeight = parseFloat(totalGrossWeight.toFixed(2));

   let totalCbm = 0;
   if (totalCarton) {
      totalCbm = totalCarton * row.newProductCbm;
   }
   totalCbm = totalCbm.toFixed(3);

   let totalValue = 0;
   if (totalCarton) {
      totalValue = row.qty * row.newProductValue;
   }
   totalValue = parseFloat(totalValue.toFixed(2));

   return {
      totalCarton,
      totalNetWeight,
      totalGrossWeight,
      totalCbm,
      totalValue,
   };
};

export const calcTotal = (shipment, key, toFixed = 0) => {
   let total = 0;
   shipment.shipmentInvoices.forEach((shipmentInvoice) => {
      shipmentInvoice.shipmentInvoiceRows.forEach((row) => {
         total += Number(row[key]);
      });
   });
   return parseFloat(total).toFixed(toFixed);
};

export function reCalcShipemntTotal(shipment) {
   const totalQty = calcTotal(shipment, 'qty');
   const totalCarton = calcTotal(shipment, 'totalCarton');
   const totalNetWeight = calcTotal(shipment, 'totalNetWeight', 2);
   const totalGrossWeight = calcTotal(shipment, 'totalGrossWeight', 2);
   const totalCbm = calcTotal(shipment, 'totalCbm', 3);
   return { totalQty, totalCarton, totalNetWeight, totalGrossWeight, totalCbm };
}
