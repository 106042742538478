import { Input, Select, Text, DatePicker, Switcher } from "components/Form";
import { Button } from "components/base";
import { formatDate } from "react-day-picker/moment";
import { BsArrowRight, BsTrash } from "react-icons/bs";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Alert } from "components/Toast";
import { BATCH_UPDATE_COST_2 } from "./graphql";
import { ARCHIVE_CODES } from "pages/price/ArchivePriceForm";
import { currencySymbol } from "pages/price/PriceWithCurrency";

const calcNewPrice = (value, formula, formulaValue, decimalPlaces) => {
  if (!formulaValue) return value.toFixed(decimalPlaces);
  switch (formula) {
    case "+":
      return (value + formulaValue).toFixed(decimalPlaces);
    case "-":
      return (value - formulaValue).toFixed(decimalPlaces);
    case "x":
      return (value * formulaValue).toFixed(decimalPlaces);
    case "=":
      return formulaValue.toFixed(decimalPlaces);
    default:
      return value.toFixed(decimalPlaces);
  }
};

const BatchUpdateView = ({ factoryId, products }) => {
  const [formula, setFormula] = useState("x");
  const [formulaValue, setFormulaValue] = useState(1);
  const [decimalPlaces, setDecimalPlaces] = useState(3);
  const [updateReasonCode, setUpdateReasonCode] = useState("1");
  const [notes, setNotes] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [currency, setCurrency] = useState("USD");
  const [batchSyncToOdoo, setBatchSyncToOdoo] = useState(false);

  console.log("products", products);

  const productPriceLevels = products.reduce((res, p) => {
    p.prices.forEach((i) => {
      if (!res.includes(i.minQty)) res = [...res, i.minQty];
    });
    return res;
  }, []);
  const initialLevels = productPriceLevels.map((i) => ({ qty: i, price: 0 }));

  const [levels, setLevels] = useState(initialLevels);

  function onChangeLevel(index, values) {
    setLevels((prev) =>
      prev.map((prev, prevIndex) =>
        prevIndex === index ? { ...prev, ...values } : prev,
      ),
    );
  }

  const [batchUpdate, batchUpdateRes] = useMutation(BATCH_UPDATE_COST_2, {
    onCompleted: (res) => {
      Alert("success", "Updated!");
    },
    onError: (error) => Alert("error", error.message),
    refetchQueries: batchSyncToOdoo
      ? ["FETCH_ODOO_PRODUCT_PRICES", "FETCH_PRODUCT_PRICES"]
      : ["FETCH_PRODUCT_PRICES"],
  });

  function addLevel() {
    setLevels((prev) => [...prev, { qty: 0, price: 0 }]);
  }

  function removeLevel(index) {
    setLevels((prev) => prev.filter((_, prevIndex) => prevIndex !== index));
  }

  function batchUpdateSaveHandler() {
    if (
      window.confirm(
        `Are you sure to update all the prices for ${
          products.length
        } product${products.length > 1 ? "s" : ""}?`,
      )
    ) {
      batchUpdate({
        variables: {
          factoryId,
          productIds: products.map((i) => i.id),
          currency,
          notes,
          effectiveDate: formatDate(effectiveDate, "YYYY-MM-DD"),
          code: updateReasonCode,
          updatedPrices: products
            .flatMap((i) => i.prices)
            .map((p) => ({
              id: p.id,
              newPrice: calcNewPrice(
                p.price,
                formula,
                formulaValue,
                decimalPlaces,
              ),
            })),
          formula,
          formulaValue,
          decimalPlaces,
          levels,
          syncToOdoo: batchSyncToOdoo,
        },
      });
    }
  }

  return (
    <div className="text-sm">
      <div className="space-y-4">
        <div>
          <label className="pr-2">Currency: </label>
          <Select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="RMB">RMB</option>
            <option value="USD">USD</option>
          </Select>
        </div>
        <div className="space-x-2">
          <label className="pr-2">Formula: </label>
          <Select value={formula} onChange={(e) => setFormula(e.target.value)}>
            <option value="+">+</option>
            <option value="-">-</option>
            <option value="x">&times;</option>
            <option value="=">=</option>
          </Select>
          {formula === "=" ? null : (
            <>
              <Input
                type="number"
                className="text-center"
                value={formulaValue}
                onChange={(e) => setFormulaValue(parseFloat(e.target.value))}
              />
              <label className="pr-2">Decimal Place: </label>
              <Select
                value={decimalPlaces}
                onChange={(e) => setDecimalPlaces(e.target.value)}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Select>
            </>
          )}
        </div>

        {formula === "=" ? (
          <div className="card rounded-2xl py-6 px-8">
            <div>
              <label htmlFor="">Price Levels</label>
            </div>
            <div className="mt-4 space-y-2">
              {levels.map((level, index) => (
                <div key={level.id} className="flex space-x-8">
                  <div className="flex items-center">
                    <Button
                      leftIcon={<BsTrash />}
                      onClick={() => removeLevel(index)}
                    />
                    <label className="px-2">QTY: </label>
                    <Input
                      className="text-center"
                      value={level.qty}
                      onChange={(e) =>
                        onChangeLevel(index, {
                          qty: e.target.value,
                        })
                      }
                      type="number"
                    />
                  </div>
                  <div>
                    <label className="pr-2">Price: </label>
                    <Input
                      className="text-center"
                      value={level.price}
                      onChange={(e) =>
                        onChangeLevel(index, {
                          price: e.target.value,
                        })
                      }
                      type="number"
                    />
                  </div>
                </div>
              ))}

              <div>
                <Button title="+ Add New Level" onClick={addLevel} />
              </div>

              <hr />

              <div className="space-y-1 text-xs">
                {products.map((i) => (
                  <div>
                    [{i.number}] {i.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}

        {formula === "=" ? null : (
          <div className="card px-8 py-6 rounded-2xl">
            <h4>Preview</h4>
            <table className="mt-4">
              <tbody>
                {products.map((product, productIndex) => (
                  <tr
                    key={product.id}
                    className={`flex items-center justify-between dark:border-gray-700
                              ${productIndex !== 0 ? "border-t" : ""}`}
                  >
                    <td className="px-0">
                      [{product.number}] {product.name}
                    </td>
                    <td className="px-0">
                      <PricePreview
                        product={product}
                        currency={currency}
                        formula={formula}
                        formulaValue={formulaValue}
                        decimalPlaces={decimalPlaces}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div>
          <label className="pr-2">Update Reason: </label>
          <Select
            value={updateReasonCode}
            onChange={(e) => setUpdateReasonCode(e.target.value)}
          >
            {ARCHIVE_CODES.map(({ code, description }) => (
              <option value={code} key={code}>
                {description}
              </option>
            ))}
          </Select>
        </div>

        <div className="flex items-center space-x-4">
          <label htmlFor="">Effect Date:</label>
          <DatePicker value={effectiveDate} onDayChange={setEffectiveDate} />
        </div>

        <div>
          <Text
            placeholder="Notes.."
            className="mt-2"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </div>

        <div className="flex items-center space-x-4">
          <label htmlFor="">Sync to Odoo: </label>
          <Switcher
            isOn={batchSyncToOdoo}
            onChange={() => setBatchSyncToOdoo((prev) => !prev)}
          />
        </div>
      </div>

      <div className="flex items-center space-x-4 mt-8">
        <Button
          size="lg"
          loading={batchUpdateRes.loading}
          bold
          title="Update"
          onClick={batchUpdateSaveHandler}
        />
      </div>
    </div>
  );
};

const PricePreview = ({
  product,
  currency,
  formula,
  formulaValue,
  decimalPlaces,
}) => {
  return (
    <div>
      {product.prices.map((price, index) => (
        <div key={index} className="flex items-center justify-end">
          <div>
            {price.minQty}: {currencySymbol(price.currency)}{" "}
            {price.price.toFixed(3)}
          </div>
          <div className="mx-1">
            <BsArrowRight />
          </div>
          <div>
            {currencySymbol(currency)}{" "}
            {calcNewPrice(price.price, formula, formulaValue, decimalPlaces)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BatchUpdateView;
