import { Fragment } from "react";

const ProductRow = ({ product, onChangeProduct, index }) => {
  return (
    <tr
      className="border-y dark:border-gray-700 animate-fadeInDown opacity-0"
      style={{ animationDelay: `${index * 50}ms` }}
    >
      <td className="px-6">
        <div className="flex space-x-2">
          <div>
            <div className="font-semibold">{product.number}</div>
            <div className="opacity-70">{product.name}</div>
          </div>
          <div className="flex gap-4">
            {product.images.map((i) => (
              <img
                key={i.id}
                src={i.url}
                alt={product.name}
                className="w-8 h-8 rounded-lg"
              />
            ))}
          </div>
        </div>
      </td>
      <td className="px-6 text-right">
        {product.areas.map((area, index) => (
          <Fragment key={index}>
            {index > 0 && ", "}
            {area.name}
          </Fragment>
        ))}
      </td>
      <td className="px-6 text-right">
        {product.productList?.version ?? "N/A"}
      </td>
      <td className="px-6 text-right">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={product.isActive}
          onChange={() =>
            onChangeProduct(product, {
              isActive: !product.isActive,
            })
          }
        />
      </td>
      <td className="px-6 text-right">
        <input
          type="checkbox"
          className="cursor-pointer"
          checked={product.isActiveForBiz}
          onChange={(e) => {
            onChangeProduct(product, {
              isActiveForBiz: !product.isActiveForBiz,
            });
          }}
        />
      </td>
    </tr>
  );
};

export default ProductRow;
