import { useMutation, useQuery } from "@apollo/client";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { Alert } from "components/Toast";
import { useModals } from "ModalProvider";
import moment from "moment";
import { IoIosCheckmarkCircle, IoIosRemoveCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import {
  FETCH_RETAILER_STORE_PRODUCTS,
  RETAILER_CREATE_PRODUCT,
} from "./graphlq";
import { computeMaxPackage } from "./inventory";
import SortView from "./SortView";

const ProductList = () => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_STORE_PRODUCTS);
  const navigate = useNavigate();
  const [createProduct, createProductRes] = useMutation(
    RETAILER_CREATE_PRODUCT,
    {
      onCompleted(res) {
        Alert("success", "Product created.");
        navigate(res.retailerCreateProduct.product.id);
      },
      onError(error) {
        Alert("error", parseError(error));
      },
      refetchQueries: [{ query: FETCH_RETAILER_STORE_PRODUCTS }],
    },
  );
  const sortModal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const rows = data.retailerProducts
    .map((product) => {
      const bom = product.bom.map((child) => ({
        ...child,
        maxPackages: computeMaxPackage(child),
      }));

      const minPackagesNumber =
        bom.length > 0 ? Math.min(...bom.map((child) => child.maxPackages)) : 0;

      return { ...product, maxInventory: minPackagesNumber };
    })
    .sort((a, b) => a.ordering - b.ordering);

  function addProduct() {
    const name = window.prompt("Enter product name");
    if (name) createProduct({ variables: { name } });
  }

  function sortProducts() {
    sortModal.present({
      title: "Sort Products",
      children: <SortView originProducts={rows} hide={sortModal.hide} />,
    });
  }

  return (
    <Page
      title="Products"
      rightButtons={
        <div className="flex space-x-4">
          <CharlesButton
            onClick={addProduct}
            loading={createProductRes.loading}
          >
            + Add Product
          </CharlesButton>

          <CharlesButton onClick={sortProducts}>Sort Products</CharlesButton>
        </div>
      }
    >
      <div className="card m-6 p-4 pt-2 text-sm">
        <table>
          <thead>
            <tr className=" whitespace-nowrap">
              <th>Product</th>
              <th className="text-right">Price</th>
              <th className="text-right">Active</th>
              <th className="text-right">In Stock</th>
              <th className="text-right">Coming Inbounds</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((product) => (
              <tr
                key={product.id}
                className={`border-y border-gray-100 dark:border-gray-700`}
              >
                <td>
                  <div className="flex space-x-2 items-center">
                    {product.image ? (
                      <img
                        src={product.image + "?imageView2/2/w/120"}
                        alt={product.name}
                        className="w-12 h-12 rounded-xl"
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-xl bg-gray-200"></div>
                    )}
                    <div>
                      <div>
                        <Link to={`${product.id}`}>{product.name}</Link>
                        <div className="space-x-3 text-xs">
                          {product.bom.map((child) => (
                            <span
                              key={child.id}
                              className={
                                child.wisProduct.warehouseInventory <= 0
                                  ? "text-red-600"
                                  : ""
                              }
                            >
                              {child.wisProduct.number} x {child.qty} (
                              {child.wisProduct.warehouseInventory})
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-right whitespace-nowrap">
                  € {product.price.toFixed(2)}
                </td>
                <td className="text-right">
                  <div className="flex justify-end">
                    {product.isActive ? (
                      <IoIosCheckmarkCircle className="text-green-500 text-xl" />
                    ) : (
                      <IoIosRemoveCircle className="text-red-500 text-xl" />
                    )}
                  </div>
                </td>
                <td
                  className={`text-right font-semibold
                  ${product.maxInventory <= 0 && "text-red-500"}`}
                >
                  {product.maxInventory}
                </td>
                <td className="text-right">
                  {product.nextAvailable && product.nextAvailable.eta
                    ? `${moment(product.nextAvailable.eta).format("YYYY-MM-DD")} (${product.nextAvailable.qty})`
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default ProductList;
