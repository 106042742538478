import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Spinner from "./components/Spinner";
import Securelogin from "./pages/auth/Securelogin";
import Customers from "./pages/customers";
import Projects from "./pages/projects";
import Products from "./pages/products/v3";
import PricePages from "./pages/price";
import ProductList from "./pages/productList";
import CalculatorV2 from "./pages/calculator/v2";
import Shipment from "./pages/shipment";
import Amazon from "./pages/amazon";
import Stock from "./pages/stock";
import Production from "./pages/production";
import CSRPage from "./pages/csr";
import Materials from "./pages/materials";
import Feedback from "./pages/feedback";
import Analytics from "./pages/analytics";
import QCPage from "./pages/qc";
import ToolsPage from "./pages/tools";
import { useFetchMe } from "./hooks/user";
import ManageIndex from "./pages/manage";
import ITWork from "./pages/itWork";
import localforage from "localforage";
import OdooProvider from "OdooProvider";
import CurrencyProvider from "CurrencyProvider";
import OrderScheduler from "pages/orderScheduler";
import { AppContext } from "App";
import FsiPage from "pages/fsi";
import OdooPages from "pages/odoo";
import ALCPage from "pages/alc";
import Header from "components/Header";
import ModalProvider from "ModalProvider";
import Validator from "pages/validate";
import ExpeditorPage from "pages/expeditor";
import SubSupplierPage from "pages/subSupplier";
import SupplierCalendar from "pages/production/supplierCalendar";
import SupplierPortal from "pages/supplierPortal";
import SentryTestPage from "pages/SentryTestPage";
import BarcodePage from "pages/tools/barcode";
import ProductsNew from "pages/products";
import UserProfilePage from "pages/userProfile";
import Orders from "pages/orders";
import Assessment from "pages/assessment";
import PageNotFound from "pages/PageNotFound";
import TestReportsPage from "pages/testReports";
import IncPages from "pages/inc";
import { INTERNAL, QC } from "utils/permissions";
import RetailerStorePages from "pages/retailer";
import mixpanel from "mixpanel-browser";
import { trackPageView, trackUser } from "utils/track";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "wis",
  storeName: "wisStore",
  version: 1.0,
});

const publicPaths = ["/barcode"];

const AppRoutes = () => {
  const { loading, data, error } = useFetchMe();
  const [inited, setInited] = useState(false);
  const { setUser, hasPermission } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        setUser(data.me);
        trackUser(data.me);
      }
      if (error) {
        if (publicPaths.every((path) => !location.pathname.startsWith(path))) {
          window.location.href =
            process.env.REACT_APP_AUTH_SERVER +
            "?redirectTo=" +
            encodeURIComponent(window.location.href);
        }
      }
      setInited(true);
    }
  }, [loading, data]);

  if (!inited) return <Spinner />;

  // if (process.env.NODE_ENV === "development") {
  //   console.log("user", user);
  //   console.log("location", location);
  // }

  if (hasPermission(INTERNAL)) return <InternalRoutes />;
  if (hasPermission(QC)) return <QCRoutes />;

  return (
    <Routes>
      <Route path="/barcode" element={<BarcodePage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

const InternalRoutes = () => {
  const { showMainMenu } = useContext(AppContext);
  return (
    <Routes>
      <Route
        element={
          <CurrencyProvider>
            <OdooProvider>
              <ModalProvider>
                <div>
                  {showMainMenu ? <Header /> : null}
                  <div
                    className={`
                      relative z-20 text-xs flex flex-1 flex-col min-h-screen transform-gpu duration-300 transition-all
                        md:block ${showMainMenu ? "md:ml-52" : ""}
                    `}
                    data-testid="main-content"
                  >
                    <Outlet />
                  </div>
                </div>
              </ModalProvider>
            </OdooProvider>
          </CurrencyProvider>
        }
      >
        <Route path="products-new/*" element={<ProductsNew />} />
        <Route path="products/*" element={<Products />} />
        <Route path="product-list/*" element={<ProductList />} />
        <Route path="calculate/*" element={<CalculatorV2 />} />
        <Route path="stock/*" element={<Stock />} />
        <Route path="price/*" element={<PricePages />} />
        <Route path="test-reports/*" element={<TestReportsPage />} />
        <Route path="order-scheduler/*" element={<OrderScheduler />} />
        <Route path="supplier-calendar/*" element={<SupplierCalendar />} />
        <Route path="production/*" element={<Production />} />
        <Route path="customers/*" element={<Customers />} />
        <Route path="shipment/*" element={<Shipment />} />
        <Route path="expeditor/*" element={<ExpeditorPage />} />
        <Route path="amazon/*" element={<Amazon />} />
        <Route path="orders/*" element={<Orders />} />
        <Route path="amazon/*" element={<Amazon />} />
        <Route path="fsi/*" element={<FsiPage />} />
        <Route path="alc/*" element={<ALCPage />} />
        <Route path="inc/*" element={<IncPages />} />
        <Route path="odoo/*" element={<OdooPages />} />
        <Route path="materials/*" element={<Materials />} />
        <Route path="csr/*" element={<CSRPage />} />
        <Route path="assessment/*" element={<Assessment />} />
        <Route path="feedback/*" element={<Feedback />} />
        <Route path="qc/*" element={<QCPage />} />
        <Route path="sub-supplier/*" element={<SubSupplierPage />} />
        <Route path="analytics/*" element={<Analytics />} />
        <Route path="manage/*" element={<ManageIndex />} />
        <Route path="retailer-store/*" element={<RetailerStorePages />} />
        <Route path="validate/*" element={<Validator />} />
        <Route path="it/*" element={<ITWork />} />
        <Route path="tools/*" element={<ToolsPage />} />
        <Route path="settings/*" element={<UserProfilePage />} />
        <Route path="projects" element={<Projects />} />
      </Route>

      <Route path="/supplier/*" element={<SupplierPortal />} />
      <Route path="/barcode" element={<BarcodePage />} />
      <Route path="/login" element={<Securelogin />} />
      <Route path="/sentry-test" element={<SentryTestPage />} />

      <Route path="*" element={<Navigate to="/products" />} />
    </Routes>
  );
};

const QCRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ModalProvider>
            <div>
              <Header />
              <div className="relative z-20 text-sm flex flex-1 flex-col min-h-screen transform-gpu duration-300 transition-all md:block md:ml-52">
                <Outlet />
              </div>
            </div>
          </ModalProvider>
        }
      >
        <Route path="qc/*" element={<QCPage />} />
      </Route>

      <Route path="/login" element={<Securelogin />} />

      <Route path="*" element={<Navigate to="/qc" />} />
    </Routes>
  );
};

export default AppRoutes;
