import { useQuery } from "@apollo/client";
import { FETCH_ANALYTICS_SALES_REPORT } from "./graphql";
import { InlineSpinner } from "components/Spinner";
import Errors from "components/Errors";
import ProductSaleView from "./ProductSalesView";
import MonthSalesView from "./MonthSalesView";
import CustomerView from "./CustomerView";

const ABSales = ({ partnerId, customer }) => {
  const currentYear = new Date().getFullYear();

  console.log("customer", customer);

  const { loading, error, data } = useQuery(FETCH_ANALYTICS_SALES_REPORT, {
    variables: { startYear: currentYear - 1, endYear: currentYear, partnerId },
  });

  if (loading)
    return (
      <div className="p-10">
        <InlineSpinner />
      </div>
    );
  if (error) return <Errors error={error} />;

  const res = JSON.parse(data.analyticsSalesReportByMonth);
  const resByCategory = JSON.parse(data.analyticsSalesReportByCategory);

  const years = Object.keys(res[0]).filter((key) => key !== "month");
  const colors = ["#3b82f6", "#16a34a"];

  const salesByYear = years.reduce((yearAcc, year) => {
    yearAcc[year] = res.reduce((res, i) => {
      return res + i[year];
    }, 0);
    return yearAcc;
  }, {});

  const qtyByYear = years.reduce((yearAcc, year) => {
    yearAcc[year] = resByCategory.reduce((res, i) => {
      return res + i[`${year}_qty`];
    }, 0);
    return yearAcc;
  }, {});

  const totalSkuByYear = years.reduce((yearAcc, year) => {
    yearAcc[year] = resByCategory.filter((i) => i[year] > 0).length;
    return yearAcc;
  }, {});

  return (
    <div className="gap-6 grid grid-cols-12">
      <div className="col-span-8 2xl:col-span-9 space-y-6 fill-current">
        <MonthSalesView years={years} colors={colors} data={res} />
        <ProductSaleView years={years} colors={colors} data={resByCategory} />
      </div>

      <div className="col-span-4 2xl:col-span-3 space-y-6">
        {customer && <CustomerView customer={customer} />}

        <div className="card">
          <h4>Total Sales</h4>
          <div className="mt-2">
            {Object.keys(salesByYear).map((year, index) => (
              <div
                key={year}
                className="flex justify-between space-x-2 text-xl"
              >
                <div style={{ color: colors[index] }}>{year}</div>
                <div className="font-code font-bold">
                  {salesByYear[year].toLocaleString()} SEK
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <h4>Total QTY</h4>
          <div className="mt-2">
            {Object.keys(qtyByYear).map((year, index) => (
              <div
                key={year}
                className="flex justify-between space-x-2 text-xl"
              >
                <div style={{ color: colors[index] }}>{year}</div>
                <div className="font-code font-bold">
                  {qtyByYear[year].toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card">
          <h4>Total SKU</h4>
          <div className="mt-2">
            {Object.keys(totalSkuByYear).map((year, index) => (
              <div
                key={year}
                className="flex justify-between space-x-2 text-xl"
              >
                <div style={{ color: colors[index] }}>{year}</div>
                <div className="font-code font-bold">
                  {totalSkuByYear[year].toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ABSales;
