import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Spinner from "./Spinner";
import Errors from "./Errors";
import ProgressRing from "./ProgressRing.js";
import Icon from "./Icon.js";

const FETCH_PRESIGNED_URL = gql`
  query FETCH_PRESIGNED_URL($filename: String!) {
    qiniuPresignedUrl(filename: $filename) {
      token
      key
      url
    }
  }
`;

const uploadUrl = "https://up-z2.qiniup.com";

function QiniuUploader({
  file,
  filename,
  preview,
  onUploaded,
  ignoreUploadedState,
  className,
}) {
  const { loading, error, data } = useQuery(FETCH_PRESIGNED_URL, {
    variables: { filename },
  });

  if (loading) return <Spinner text={null} />;
  if (error) return <Errors error={error} />;

  return (
    <Uploader
      className={className}
      qiniuPresignedUrl={data.qiniuPresignedUrl}
      file={file}
      preview={preview}
      onUploaded={onUploaded}
      ignoreUploadedState={ignoreUploadedState}
    />
  );
}

function Uploader({
  className = "",
  qiniuPresignedUrl,
  file,
  preview,
  ignoreUploadedState,
  onUploaded,
}) {
  const [progress, setProgress] = useState(0);

  function uploadFile() {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", uploadUrl, true);
    let formData = new FormData();
    formData.append("key", qiniuPresignedUrl.key);
    formData.append("token", qiniuPresignedUrl.token);
    formData.append("file", file);
    xhr.upload.addEventListener(
      "progress",
      function (evt) {
        if (evt.lengthComputable) {
          setProgress(Math.round((evt.loaded * 100) / evt.total));
        }
      },
      false,
    );
    xhr.onreadystatechange = function () {
      if (
        xhr.readyState === 4 &&
        xhr.status === 200 &&
        xhr.responseText !== ""
      ) {
        onUploaded(qiniuPresignedUrl.url);
      } else if (xhr.status !== 200 && xhr.responseText) {
      }
    };
    xhr.send(formData);
  }

  useEffect(() => {
    if (file) uploadFile();
  }, [file]);

  const uploaded = progress === 100;
  // const uploaded = false;

  return (
    <div
      className={`${className} w-full h-full relative`}
      style={{ minWidth: 100 }}
    >
      {preview ? <img className="w-full" src={preview} alt="preview" /> : null}
      <div
        className={`absolute inset-0 z-10 flex items-center justify-center
               ${preview ? (uploaded ? "bg-dark-20" : "bg-dark-50") : ""}
            `}
      >
        {!ignoreUploadedState && uploaded ? (
          <div className="text-green-500">
            <Icon icon="check" size={30} />
          </div>
        ) : (
          <div style={{ width: 40, height: 40 }}>
            <ProgressRing radius={20} stroke={2} progress={progress} />
          </div>
        )}
      </div>
    </div>
  );
}

export default QiniuUploader;
